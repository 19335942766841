<script setup lang="ts">
import { computed } from 'vue';
import AdvancedAppList from './AdvancedAppList.vue';
import InputSelectFloating from '../InputSelectFloating.vue';
import GeneralSettingSelector from './GeneralSettingSelector.vue';
import type { Project } from '../../project';

//refactor to component-library
import { getBasemapApis } from '@maps/business-logic/basemap';
import { Figure } from '../../maps';
import { KeyValue } from '../../key-value';

const props = defineProps<{
  selectedProject: Project;
  figures: Figure[];
  userId: number;
  userDevice?: string | null;
  figureId?: number | null;
  basemap: number;
  radius: number;
  selectedAppIds: number[];
  selectedSampleIds: number[];
}>();

const basemaps = getBasemapApis(
  props.selectedProject.address_country,
  props.selectedProject.address_state ?? undefined
).filter((b) => b.index === 0);

const emit = defineEmits<{
  (e: 'start'): void;
  (e: 'back'): void;
  (e: 'update:offlineRequest', keyValue: KeyValue): void;
  (e: 'toggleEnabledPushNotifications', value: boolean): void;
}>();

const userId = computed({
  get() {
    return props.userId;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'user_id', value });
  },
});

const userDevice = computed({
  get() {
    return props.userDevice;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'device', value });
  },
});

const figureId = computed({
  get() {
    return props.figureId;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'figure_id', value });
  },
});

const basemap = computed({
  get() {
    return props.basemap;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'basemap', value });
  },
});

const radius = computed({
  get() {
    return props.radius;
  },
  set(value) {
    emit('update:offlineRequest', { key: 'radius', value });
  },
});
</script>
<template>
  <GeneralSettingSelector
    v-if="figureId"
    :projectId="selectedProject.project_id"
    :userId="userId"
    :userDevice="userDevice"
    :figures="figures"
    :figureId="figureId"
    @selectUserId="(id) => (userId = id)"
    @selectUserDevice="(device) => (userDevice = device)"
    @selectFigureId="(id) => (figureId = id)"
    @toggleEnabledPushNotifications="
      emit('toggleEnabledPushNotifications', $event)
    "
  />

  <InputSelectFloating
    v-model="basemap"
    required
    class="mb-2"
    name="mode"
    label="Basemap"
  >
    <option
      v-for="(basemap, basemapIndex) in basemaps"
      :key="`basemap-${basemapIndex}`"
      :value="basemap.index"
    >
      {{ basemap.title }}
    </option>
  </InputSelectFloating>

  <div class="mb-2">
    <label for="radius" class="form-label">
      Radius of tiles around address
    </label>
    <div class="input-group">
      <input
        id="radius"
        v-model.number="radius"
        type="number"
        class="form-control"
        min="1"
        max="25000"
      />
      <span class="input-group-text">m</span>
    </div>
  </div>

  <span class="fw-medium d-block mb-2"> Apps to take offline </span>
  <AdvancedAppList
    :project="selectedProject"
    :selectedAppIds="selectedAppIds"
    :selectedSampleIds="selectedSampleIds"
    class="mb-2"
    @setSelectedAppIds="
      ($event) =>
        emit('update:offlineRequest', { key: 'app_ids', value: $event })
    "
    @setSelectedSampleIds="
      ($event) =>
        emit('update:offlineRequest', { key: 'sample_ids', value: $event })
    "
  />
</template>
