<script setup lang="ts">
import { ref, inject, computed, onMounted } from 'vue';
import Modal from '@component-library/components/Modal.vue';
import InputCheckbox from '@component-library/components/InputCheckbox.vue';
import useRequestOfflineSetup from '@/js/composables/useRequestOfflineSetup';
import useAuth from '@component-library/composables/useAuth';
import Spinner from '@component-library/components/Spinner.vue';
const auth = useAuth();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { requestNotificationPermission, subscribeToNotifications } =
  useRequestOfflineSetup();

const isSubscribing = ref(false);
const isPermissionGranted = ref(false);

const deferredInstallPrompt = inject<any>('deferredInstallPrompt');
const hasInstalled = ref(false);

const hasNotificationsDisabled = ref(false);

const dontShowAgain = ref(false);

const hasAcknowledgedOfflineSetup =
  localStorage.getItem('hasAcknowledgedOfflineSetup') === 'true';

const user = computed(() => {
  return auth.user();
});

const toggleSubscribe = async () => {
  try {
    isSubscribing.value = true;

    await requestNotificationPermission();
    await subscribeToNotifications();
    await refreshUser();

    isPermissionGranted.value = true;
    isSubscribing.value = false;
  } catch (e) {
    hasNotificationsDisabled.value = true;
    isSubscribing.value = false;

    throw e;
  }
};

const refreshUser = async () => {
  await auth.fetch();
};

const installPWA = async () => {
  const { outcome } = await deferredInstallPrompt.value!.prompt();
  hasInstalled.value = outcome === 'accepted';
};

const isIOS = computed(() => {
  return (
    window.navigator && /iPad|iPhone|iPod/.test(window.navigator.userAgent)
  );
});

const isInstalledOnIOS = computed(() => {
  return (
    'standalone' in window.navigator &&
    window.navigator.standalone &&
    isIOS.value
  );
});

const isStandAloneDevice = computed(() => {
  const isStandaloneIOS =
    'standalone' in window.navigator && window.navigator.standalone;
  const isStandaloneAndroid = window.matchMedia(
    '(display-mode: standalone)'
  ).matches;

  return isStandaloneIOS || isStandaloneAndroid;
});

const requirePermission = computed(() => {
  return !isPermissionGranted.value;
});

const refreshApp = () => {
  window.location.reload();
};

const isNotFinishedSetup = computed(() => {
  return (
    (isIOS.value && !isInstalledOnIOS.value) ||
    (!isIOS.value && !hasInstalled && deferredInstallPrompt) ||
    ((requirePermission.value || hasNotificationsDisabled.value) &&
      !user.value.company.is_force_local_gather_offline_mode)
  );
});

const close = () => {
  if (dontShowAgain.value) {
    emit('close');
    return;
  }

  let shouldSetAcknowledged = true;
  if (isNotFinishedSetup.value) {
    shouldSetAcknowledged = false;
  }

  if (shouldSetAcknowledged) {
    localStorage.setItem('hasAcknowledgedOfflineSetup', 'true');
  }

  emit('close');
};

const toggleDontShowAgain = () => {
  localStorage.setItem(
    'hasAcknowledgedOfflineSetup',
    dontShowAgain.value ? 'true' : 'false'
  );
};

onMounted(() => {
  if (typeof Notification !== 'undefined') {
    isPermissionGranted.value =
      Notification && Notification.permission === 'granted';
  }
});
</script>
<template>
  <Modal @close="close">
    <template #header>
      Enable Device Settings
      <i class="fal fa-sync clickable ms-2" @click="refreshApp" />
    </template>

    <template #default>
      <div class="list-group list-group-flush">
        <div
          v-if="isIOS"
          class="list-group-item d-flex align-items-center justify-content-between pb-3 pt-0 px-0"
        >
          <div>
            <span class="fw-medium d-block mb-2">
              Install Application on IOS
            </span>
            <small class="d-block text-muted pe-3">
              In the menu -> Click on
              <span class="fw-medium">Add to Home Screen.</span>
            </small>
          </div>

          <i
            style="font-size: 22px"
            :class="{
              'fal fa-square text-muted': !isInstalledOnIOS,
              'fas fa-check-square text-primary': isInstalledOnIOS,
            }"
          />
        </div>

        <div
          v-else
          class="list-group-item clickable d-flex align-items-center justify-content-between pt-0 pb-3 px-0"
          @click="installPWA"
        >
          <div>
            <span class="fw-medium d-block mb-2"> Install Application </span>
            <small class="d-block text-muted pe-3">
              We need to install the application, in order to be able to use it
              while offline.
            </small>
          </div>

          <i
            style="font-size: 22px"
            :class="{
              'fal fa-square text-muted':
                !hasInstalled && deferredInstallPrompt,
              'fas fa-check-square text-primary':
                hasInstalled || !deferredInstallPrompt,
            }"
          />
        </div>

        <div
          class="list-group-item clickable d-flex align-items-center justify-content-between pt-3 pb-3 px-0"
        >
          <div>
            <span class="fw-medium d-block mb-2"> Open Application </span>
            <small class="d-block text-muted pe-3">
              Once installed, go to your homescreen and open the application.
            </small>
          </div>

          <i
            style="font-size: 22px"
            :class="{
              'fal fa-square text-muted': !isStandAloneDevice,
              'fas fa-check-square text-primary': isStandAloneDevice,
            }"
          />
        </div>

        <div
          v-if="!user.company.is_force_local_gather_offline_mode"
          class="list-group-item clickable d-flex align-items-center justify-content-between pb-0 pt-3 px-0"
          :class="{
            disabled: hasNotificationsDisabled,
          }"
          @click="toggleSubscribe"
        >
          <div>
            <span class="fw-medium d-block mb-2">
              Enable Push Notifications
            </span>
            <small class="d-block text-muted pe-3">
              Datanest will notify you whenever offline data is downloaded, this
              is essential.
            </small>

            <small
              v-if="!requirePermission && user.push_subscriptions?.length === 0"
              class="text-danger"
            >
              Push subscriptions haven't been registered for this user. Try
              again.
              <button
                class="btn btn-light btn-sm ms-1"
                @click.stop="toggleSubscribe"
              >
                <i class="fal fa-sync clickable" />
              </button>
            </small>
          </div>

          <Spinner v-if="isSubscribing" />
          <i
            v-else
            style="font-size: 22px"
            :class="{
              'fal fa-square text-muted': requirePermission,
              'fas fa-check-square text-primary': !requirePermission,
            }"
          />
        </div>
      </div>

      <div
        v-if="
          hasNotificationsDisabled &&
          !user.company.is_force_local_gather_offline_mode
        "
        class="alert alert-danger mt-3 mb-0"
      >
        <template v-if="isIOS">
          <strong>Important:</strong> You have denied notification permissions
          at a previous stage. Go to settings -> Datanest: Gather to enable them
          for the app & reload.
        </template>
        <template v-else>
          <strong>Important:</strong> You have denied notification permissions
          at a previous stage. Go to Chrome settings -> Datanest: Gather ->
          enable them for the app.
        </template>
      </div>
    </template>

    <template v-if="!hasAcknowledgedOfflineSetup || isStandAloneDevice" #footer>
      <div
        v-if="!hasAcknowledgedOfflineSetup"
        class="d-flex flex-column align-items-start w-100 m-0 mb-2 ms-2"
      >
        <InputCheckbox
          v-model="dontShowAgain"
          label="Don't show this again"
          @input="toggleDontShowAgain"
        />
        <small class="text-muted">
          It can be accessed again in the left hand menu.
        </small>
      </div>

      <button
        v-if="isStandAloneDevice"
        class="btn btn-primary w-100 py-3"
        :disabled="isNotFinishedSetup"
        @click="
          () => {
            close();
            refreshApp();
          }
        "
      >
        Start using Datanest
      </button>
    </template>
  </Modal>
</template>
