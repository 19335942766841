<template>
  <Modal v-if="show" :half="true" :hideHeader="true" @close="close">
    <template #default>
      <version-control v-if="sample && show" :sampleId="sample.id" />
    </template>

    <template #footer>
      <button class="btn btn-outline-secondary w-100" @click="close">
        {{ isNonSpatialView ? 'Close' : 'Back' }}
      </button>
    </template>
  </Modal>
</template>

<script>
import VersionControl from '@/js/modules/project/version-control/index.vue';
import Modal from '@component-library/components/Modal.vue';

export default {
  props: {
    show: Boolean,
    sample: Object,
    isNonSpatialView: Boolean,
  },
  components: { Modal, VersionControl },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
