<script setup lang="ts">
import type {
  CollectionType,
  ConditionOnGatherApp as Condition,
  StylingRuleOnGatherApp as StylingRule,
  GatherAppTarget as Target,
} from '@component-library/business-logic/mapping/styling-rule';
import {
  Operator,
  OperatorLabelMap,
  TargetType,
  getDefaultCollectionType,
} from '@component-library/business-logic/mapping/styling-rule';
import {
  DrawingType,
  type App,
  type GatherField,
  type Section,
} from '@component-library/gather';
import InfoButton from '@component-library/components/InfoButton.vue';
import CheckboxOperandInput from '@component-library/components/mapping/operand-inputs/Checkbox.vue';
import DateOperandInput from '@component-library/components/mapping/operand-inputs/Date.vue';
import DropdownOperandInput from '@component-library/components/mapping/operand-inputs/Dropdown.vue';
import ExpressionOperandInput from '@component-library/components/mapping/operand-inputs/Expression.vue';
import NumberOperandInput from '@component-library/components/mapping/operand-inputs/Number.vue';
import TextOperandInput from '@component-library/components/mapping/operand-inputs/Text.vue';
import { computed, ref, watch } from 'vue';
import useStylingRules from '../../../composables/useStylingRules';
import { FieldTypeIds } from '@component-library/fields';
import { getDefaultValuePair } from '@component-library/business-logic/expression';
import makeId from '@component-library/local-id.mjs';

const props = defineProps<{ app: App }>();
const collectionTypePointId = ref(makeId());
const collectionTypePolygonId = ref(makeId());
const collectionTypePolylineId = ref(makeId());
const sectionSelectId = ref(makeId());
const fieldSelectId = ref(makeId());
const operatorSelectId = ref(makeId());

const emit = defineEmits(['targetAndConditionChange']);

const { activeTempStylingRule } = useStylingRules(props.app);

const collectionType = ref<CollectionType>(
  activeTempStylingRule.value
    ? activeTempStylingRule.value.target.collectionType
    : getDefaultCollectionType(props.app.drawing_type)
);
const isCollectionTypeChooserVisible = computed(() => {
  return props.app.drawing_type === DrawingType.Any;
});
const availableSections = computed<Section[]>(() => {
  return props.app.sections?.filter((section) => !section.is_repeatable) ?? [];
});
watch(availableSections, (newValue) => {
  selectedSectionId.value = newValue[0]?.id;
});
const selectedSectionId = ref<number | undefined>(
  (activeTempStylingRule.value as StylingRule)?.target.sectionId ??
    availableSections.value[0]?.id
);
const selectedSection = computed<Section | undefined>(() => {
  if (!selectedSectionId.value) {
    return undefined;
  }

  return availableSections.value.find(
    (section) => section.id === selectedSectionId.value
  );
});
const availableFields = computed(() => {
  return (
    selectedSection.value?.template_fields?.filter((field) =>
      (
        [
          FieldTypeIds.CHECKBOX,
          FieldTypeIds.DATE,
          FieldTypeIds.DROPDOWN,
          FieldTypeIds.LITHOLOGY,
          FieldTypeIds.EXPRESSION,
          FieldTypeIds.NUMBER,
          FieldTypeIds.TEXT,
        ] as any
      ).includes(field.field_type_id)
    ) ?? []
  );
});
watch(availableFields, (newValue) => {
  selectedFieldId.value = newValue[0]?.id;
});
const selectedFieldId = ref<number | undefined>(
  (activeTempStylingRule.value as StylingRule)?.condition.fieldId ??
    availableFields.value[0]?.id
);
const selectedField = computed<GatherField | undefined>(() => {
  if (!selectedFieldId.value) {
    return undefined;
  }

  return availableFields.value.find(
    (field) => field.id === selectedFieldId.value
  );
});
const operand = ref(
  (activeTempStylingRule.value as StylingRule)?.condition.operand ??
    (selectedField.value
      ? getDefaultValuePair(selectedField.value)
      : { value: null, value2: null })
);
watch(selectedField, (newValue) => {
  operand.value = newValue
    ? getDefaultValuePair(newValue)
    : { value: null, value2: null };
});
const selectedOperator = ref<Operator>(
  ((activeTempStylingRule.value as StylingRule)?.condition
    .operator as Operator) ?? Operator.EqualTo
);
const operandInputComponent = computed<any>(() => {
  if (!selectedField.value) {
    return undefined;
  }

  switch (selectedField.value.field_type_id) {
    case FieldTypeIds.TEXT:
      return TextOperandInput;
    case FieldTypeIds.NUMBER:
      return NumberOperandInput;
    case FieldTypeIds.DATE:
      return DateOperandInput;
    case FieldTypeIds.DROPDOWN:
    case FieldTypeIds.LITHOLOGY:
      return DropdownOperandInput;
    case FieldTypeIds.CHECKBOX:
      return CheckboxOperandInput;
    case FieldTypeIds.EXPRESSION:
      return ExpressionOperandInput;
    default:
      throw `Unsupported field type: ${selectedField.value.field_type_id}`;
  }
});

const targetAndCondition = computed<
  { target: Target; condition: Condition } | undefined
>(() => {
  if (!selectedSection.value || !selectedField.value) {
    return undefined;
  }

  return {
    target: {
      type: TargetType.GatherApp,
      id: props.app.id,
      collectionType: collectionType.value,
      sectionId: selectedSection.value.id,
    },
    condition: {
      fieldId: selectedField.value.id,
      operator: selectedOperator.value,
      operand: operand.value,
    },
  };
});
watch(
  targetAndCondition,
  (newValue) => {
    emit('targetAndConditionChange', newValue);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div>
    <!-- App -->
    <div class="form-group mb-3">
      <label class="form-label">App</label>
      <div>
        <b>{{ app.title }}</b>
      </div>
    </div>

    <div class="mb-3">
      <label class="form-label">Collection type</label>
      <div v-if="isCollectionTypeChooserVisible" class="d-flex gap-3">
        <div class="form-check">
          <input
            :id="collectionTypePointId"
            v-model="collectionType"
            class="form-check-input"
            type="radio"
            name="collectionType"
            :value="DrawingType.Point"
          />
          <label class="form-check-label" :for="collectionTypePointId">
            Point</label
          >
        </div>
        <div class="form-check">
          <input
            :id="collectionTypePolygonId"
            v-model="collectionType"
            class="form-check-input"
            type="radio"
            name="collectionType"
            :value="DrawingType.Polygon"
          />
          <label class="form-check-label" :for="collectionTypePolygonId">
            Polygon</label
          >
        </div>
        <div class="form-check">
          <input
            :id="collectionTypePolylineId"
            v-model="collectionType"
            class="form-check-input"
            type="radio"
            name="collectionType"
            :value="DrawingType.Polyline"
          />
          <label class="form-check-label" :for="collectionTypePolylineId">
            Polyline</label
          >
        </div>
      </div>
      <div v-else>
        <b>{{ collectionType }}</b>
      </div>
    </div>

    <!-- Section select -->
    <div class="form-group mb-3">
      <label class="form-label" :for="sectionSelectId">Section </label
      ><InfoButton
        class="ms-2"
        info="Only non-repeatable sections show here."
      />
      <select
        :id="sectionSelectId"
        v-model="selectedSectionId"
        class="form-select"
      >
        <option
          v-for="section in availableSections"
          :key="`section-${section.id}`"
          :value="section.id"
        >
          {{ section.label }}
        </option>
      </select>
    </div>

    <!-- Field select -->
    <div class="form-group mb-3">
      <label class="form-label" :for="fieldSelectId">Field</label>
      <select :id="fieldSelectId" v-model="selectedFieldId" class="form-select">
        <option
          v-for="field in availableFields"
          :key="`field-${field.id}`"
          :value="field.id"
        >
          {{ field.label }}
        </option>
      </select>
    </div>

    <!-- Operator select -->
    <div class="form-group mb-3">
      <label class="form-label" :for="operatorSelectId"
        >Comparison operator</label
      >
      <select
        :id="operatorSelectId"
        v-model="selectedOperator"
        class="form-select"
      >
        <option
          v-for="(operator, operatorIndex) in Object.values(Operator)"
          :key="`operator-${operatorIndex}`"
          :value="operator"
        >
          {{ OperatorLabelMap[operator] }}
        </option>
      </select>
    </div>

    <!-- Operand input -->
    <div v-if="operandInputComponent" class="form-group mb-3">
      <label class="form-label">Value</label>
      <component
        :is="operandInputComponent"
        v-bind="{ field: selectedField }"
        v-model="operand"
      />
    </div>
  </div>
</template>
