import _isNil from 'lodash/isNil';
import _snakeCase from 'lodash/snakeCase';
import type { InputValueValue } from './business-logic/input-value';
import {
  checkIsConditionsDefault,
  checkIsExpressionDefault,
  checkIsLookupDefault,
  checkIsReferenceDefault,
} from './business-model/auto-assign';
import { OPTION_TYPE_TEXT } from './business-model/dropdown';
import type { ExpressionOptions } from './business-model/expression';
import { ResultType } from './business-model/expression';
import type { GatherField, GatherFieldOptions, InputValue } from './gather';
import { useLinkedFieldsStore } from './store/linked-fields';
import Field from './classes/Field';

export const FieldTypeIds = {
  TEXT: 1,
  NUMBER: 2,
  DATE: 3,
  DROPDOWN: 4,
  CHECKBOX: 5,
  MEDIA: 6,
  DEPTH: 7,
  REFERENCE: 8,
  SIGNATURE: 9,
  CAPTION: 10,
  EXPRESSION: 11,
  DOCUMENT: 12,
  DUPLICATE: 13,
  TRIPLICATE: 14,
  DRAWING: 15,
  LITHOLOGY: 16,
  ADDRESS: 17,
  USER: 18,
  LAB_ID: 19,
  COPY_DATA_LINK: 20,
} as const;

export type FieldTypeId = (typeof FieldTypeIds)[keyof typeof FieldTypeIds];

export type SelectOption = {
  value: string;
  displayValue: string;
};

export function checkHasNumberValue(f: GatherField | Field): boolean {
  if (f.field_type_id === FieldTypeIds.NUMBER) {
    return true;
  }

  if (f.field_type_id === FieldTypeIds.EXPRESSION) {
    const { expression } = f.options as ExpressionOptions;
    if (!expression || typeof expression === 'string') {
      return true;
    } else {
      return expression.resultType === ResultType.NUMBER;
    }
  }

  return false;
}

const YES: SelectOption = {
  value: 'yes',
  displayValue: 'Yes',
};

const NO: SelectOption = {
  value: 'no',
  displayValue: 'No',
};

export function checkIsSelectSupported(f: GatherField | Field): boolean {
  if (
    (
      [
        FieldTypeIds.CHECKBOX,
        FieldTypeIds.DROPDOWN,
        FieldTypeIds.LITHOLOGY,
      ] as any
    ).includes(f.field_type_id)
  ) {
    return true;
  }

  if (f.field_type_id === FieldTypeIds.EXPRESSION) {
    const { expression } = f.options as ExpressionOptions;
    if (expression && typeof expression !== 'string') {
      return expression.resultType === ResultType.BOOLEAN;
    }
  }

  return false;
}

export function getSelectOptions(f: GatherField | Field): SelectOption[] {
  if (!checkIsSelectSupported(f)) {
    return [];
  }

  if (f.field_type_id === FieldTypeIds.CHECKBOX) {
    return [YES, NO];
  }

  if (f.field_type_id === FieldTypeIds.EXPRESSION) {
    const { expression } = f.options as ExpressionOptions;
    if (
      expression &&
      typeof expression !== 'string' &&
      expression.resultType === ResultType.BOOLEAN
    ) {
      return [YES, NO];
    }
  }

  return (
    f.options?.options?.map((o) => {
      return {
        value: o,
        displayValue: o,
      };
    }) ?? []
  );
}

export function getOperand(
  inputValues: InputValue[],
  fieldId: number | null,
  sectionIndex: number | null
): InputValueValue {
  if (!fieldId) {
    return null;
  }
  const inputValue = inputValues.find(
    (iv) =>
      iv.template_field_id == fieldId &&
      (sectionIndex === null || iv.template_section_index === sectionIndex)
  );

  if (!inputValue) {
    return null;
  }

  return typeof inputValue.value === 'boolean' // The result of an logical expression
    ? inputValue.value
      ? 'yes'
      : 'no'
    : inputValue.value;
}

export function checkIsConditionMet(
  field: GatherField,
  inputValues: InputValue[],
  sectionIndex: number | null = null
): boolean {
  const is_always_hidden = field.options?.is_always_hidden;
  if (is_always_hidden) {
    return false;
  }

  const conditions = field.options?.conditions;
  const allConditionsMatch = field.options?.all_conditions_match;
  if (!conditions || conditions.length === 0) {
    return true;
  }

  let conditionsMet = 0;

  for (const c of conditions) {
    const { operator, value } = c;
    const operand = c.field_id && getOperand(inputValues, c.field_id, sectionIndex);
    if (operator !== '!=' && !operand) {
      continue;
    }
    if (
      (operator === '=' && operand == value) ||
      (operator === '!=' && operand != value) ||
      (operator === '>' && Number(operand) > Number(value)) ||
      (operator === '>=' && Number(operand) >= Number(value)) ||
      (operator === '<' && Number(operand) < Number(value)) ||
      (operator === '<=' && Number(operand) <= Number(value))
    ) {
      conditionsMet += 1;
      if (!allConditionsMatch) {
        break;
      }
    }
  }

  return (
    conditionsMet > 0 &&
    (!allConditionsMatch || conditionsMet === conditions.length)
  );
}

export function checkIsFieldEligibleForItemTitle(field: GatherField) {
  return (
    [
      FieldTypeIds.TEXT,
      FieldTypeIds.NUMBER,
      FieldTypeIds.DATE,
      FieldTypeIds.DROPDOWN,
      FieldTypeIds.CHECKBOX,
      FieldTypeIds.DEPTH,
      FieldTypeIds.LITHOLOGY,
      FieldTypeIds.EXPRESSION,
    ] as any
  ).includes(field.field_type_id);
}

export function canBeFiltered(field: GatherField) {
  return !([FieldTypeIds.MEDIA, FieldTypeIds.SIGNATURE] as any).includes(
    field.field_type_id
  );
}
export interface FieldType {
  id: FieldTypeId;
  type: string;
  display_name: string;
  short_name?: string;
  icon: string;
  is_hidden: boolean;
}

export const fieldTypes: FieldType[] = [
  {
    id: FieldTypeIds.TEXT,
    type: 'text',
    display_name: 'Text',
    icon: 'fa-text',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.NUMBER,
    type: 'number',
    display_name: 'Number',
    icon: 'fa-lambda',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.DATE,
    type: 'date',
    display_name: 'Date / Time',
    icon: 'fa-calendar-day',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.DROPDOWN,
    type: 'dropdown',
    display_name: 'Choice / Dropdown',
    short_name: 'Choice',
    icon: 'fa-caret-square-down',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.CHECKBOX,
    type: 'checkbox',
    display_name: 'Yes / No Choice',
    icon: 'fa-check-square',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.MEDIA,
    type: 'media',
    display_name: 'Media',
    icon: 'fa-file',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.DEPTH,
    type: 'depth',
    display_name: 'Depth',
    icon: 'fa-ruler-vertical',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.REFERENCE,
    type: 'reference',
    display_name: 'Link another App',
    short_name: 'Linked App',
    icon: 'fa-link',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.SIGNATURE,
    type: 'signature',
    display_name: 'Signature',
    icon: 'fa-signature',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.CAPTION,
    type: 'caption',
    display_name: 'Caption',
    icon: 'fa-closed-captioning',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.LITHOLOGY,
    type: 'lithology',
    display_name: 'Lithology',
    icon: 'fa-lithology',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.EXPRESSION,
    type: 'expression',
    display_name: 'Expression',
    icon: 'fa-square-root-alt',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.DOCUMENT,
    type: 'document',
    display_name: 'Document',
    icon: 'fal fa-file-alt',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.DUPLICATE,
    type: 'duplicate',
    display_name: 'Duplicate',
    icon: 'fal fa-map-marker',
    is_hidden: true,
  },
  {
    id: FieldTypeIds.TRIPLICATE,
    type: 'triplicate',
    display_name: 'Triplicate',
    icon: 'fal fa-map-marker',
    is_hidden: true,
  },
  {
    id: FieldTypeIds.DRAWING,
    type: 'drawing',
    display_name: 'Drawing',
    icon: 'fal fa-palette',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.ADDRESS,
    type: 'address',
    display_name: 'Lookup Address',
    icon: 'fal fa-map-marker-alt',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.USER,
    type: 'user',
    display_name: 'Datanest User',
    icon: 'fal fa-users',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.LAB_ID,
    type: 'lab-id',
    display_name: 'Lab ID',
    icon: 'fal fa-map-marker',
    is_hidden: false,
  },
  {
    id: FieldTypeIds.COPY_DATA_LINK,
    type: 'copy-data-link',
    display_name: 'Copy Data Link',
    icon: 'fal fa-copy',
    is_hidden: false,
  },
];

export function createDefaultOptions(): GatherFieldOptions {
  return {
    conditions: [],
    options: [],
    groups: [],
    groupConditions: [],
    defaults: [],
    all_conditions_match: null,
    is_readonly: false,
    isInvolvedInDataCopy: true,
  };
}

export const draggableFieldTypes = [...fieldTypes]
  .filter((field) => !field.is_hidden)
  .map(({ id, display_name, icon }) => {
    const field = {
      field_type_id: id,
      label: display_name,
      options: createDefaultOptions(),
      icon,
    };
    if (id === FieldTypeIds.CAPTION) {
      field.options.content = '';
      field.options.status = 'secondary';
    } else if (id === FieldTypeIds.DROPDOWN) {
      field.options.option_type = OPTION_TYPE_TEXT;
    } else if (id === FieldTypeIds.LITHOLOGY) {
      field.options.optionExtensions = {};
    }
    return field;
  });

export function getFieldTypeById(id: FieldTypeId) {
  return fieldTypes.find((t) => t.id == id);
}

export function getFieldIconById(id: FieldTypeId) {
  const fieldType = getFieldTypeById(id);

  let classData = {};
  let className = 'fa-value-absolute';

  if (fieldType) {
    className = fieldType.icon;
  }

  classData[className] = true;

  return classData;
}

export function getDefaultValue(
  field,
  inputValues,
  sectionIndex = null,
  callback,
  findFieldById: (id: number) => GatherField
): InputValueValue {
  const { default: unconditionalDefault = null, defaults = [] } = field.options;

  if (unconditionalDefault === null && !defaults.length) {
    return null;
  }

  const conditionsDefaults = defaults.filter((d) =>
    checkIsConditionsDefault(d)
  );
  const lookupDefaults = defaults.filter((d) => checkIsLookupDefault(d));
  const expressionDefaults = defaults.filter((d) =>
    checkIsExpressionDefault(d)
  );
  const referenceDefaults = defaults.filter((d) => checkIsReferenceDefault(d));
  // The conditions defaults override the lookup default and the lookup default
  // overrides the expression default.
  const defaultsInOrder = [
    ...conditionsDefaults,
    ...lookupDefaults,
    ...expressionDefaults,
    ...referenceDefaults,
  ];

  let result;
  for (const d of defaultsInOrder) {
    if (checkIsConditionsDefault(d)) {
      const operand = getOperand(inputValues, d.field_id, sectionIndex);
      if (!operand) {
        continue;
      }

      const { operator, value } = d;
      if (
        (operator === '=' && operand == value) ||
        (operator === '!=' && operand != value) ||
        (operator === '>' && Number(operand) > Number(value)) ||
        (operator === '>=' && Number(operand) >= Number(value)) ||
        (operator === '<' && Number(operand) < Number(value)) ||
        (operator === '<=' && Number(operand) <= Number(value))
      ) {
        result = d.default;
        break;
      }
    } else if (checkIsLookupDefault(d)) {
      const { sourceFieldIds, dictionary } = d;
      const sourceOperands = sourceFieldIds.map((sfId) =>
        getOperand(inputValues, sfId, sectionIndex)
      );
      const targetEntry = dictionary.find((entry) =>
        sourceOperands.reduce(
          (accu, so, soIndex) => accu && entry[soIndex] === so,
          true
        )
      );
      if (targetEntry) {
        result = targetEntry[sourceFieldIds.length];
        break;
      }
    } else if (checkIsExpressionDefault(d)) {
      let o = getOperand(inputValues, d.fieldId, sectionIndex);
      if (
        [FieldTypeIds.DROPDOWN, FieldTypeIds.LITHOLOGY].includes(
          field.field_type_id
        )
      ) {
        if (!field.options.options.includes(o)) {
          o = null;
        }
      } else if (field.field_type_id === FieldTypeIds.TEXT) {
        const expressionField = findFieldById(d.fieldId);
        if (
          expressionField.options?.expression!.resultType === ResultType.NUMBER
        ) {
          o = Number(o).toFixed(expressionField.options.maxDecimals ?? 0);
        }
        o = _isNil(o) ? o : String(o);
      }
      result = o;
      break;
    } else if (checkIsReferenceDefault(d)) {
      result = useLinkedFieldsStore().getLinkedFieldValue(
        d,
        inputValues,
        callback,
        sectionIndex ?? 0
      );
      break;
    }
  }

  const isResultVisible =
    result !== null && result !== undefined && String(result).trim() !== '';
  return isResultVisible ? result : unconditionalDefault;
}

export function hasGroup(
  field: GatherField,
  inputValues: InputValue[],
  sectionIndex: number | null = null
) {
  const conditions = field.options?.groupConditions || [];

  if (!conditions || conditions.length == 0) {
    return false;
  }

  for (const condition of conditions) {
    const operand = getOperand(inputValues, condition.field_id, sectionIndex);

    if (!operand) {
      continue;
    }

    const { operator, value } = condition;

    if (
      (operator === '=' && operand == value) ||
      (operator === '!=' && operand != value) ||
      (operator === '>' && Number(operand) > Number(value)) ||
      (operator === '>=' && Number(operand) >= Number(value)) ||
      (operator === '<' && Number(operand) < Number(value)) ||
      (operator === '<=' && Number(operand) <= Number(value))
    ) {
      return condition.default;
    }
  }
  return false;
}

export function getFieldAnchorId(fieldLabel: string): string {
  return `field_anchor_${_snakeCase(fieldLabel)}`;
}
