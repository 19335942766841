<script lang="ts" setup>
import ImageMedia from './ImageMedia.vue';
import VideoMedia from './VideoMedia.vue';
import AudioMedia from './AudioMedia.vue';
import { GatherField } from '../../gather';
import { InputValue } from '../../business-logic/input-value';
import { computed } from 'vue';

const props = defineProps<{
  field: GatherField;
  inputValue: InputValue;
  inputValues: InputValue[];
  repeatable: boolean;
}>();

const emit = defineEmits<{
  (event: 'clickCamera', data: any): void;
  (event: 'clickVideo', data: any): void;
  (event: 'clickSetPreview', data: any): void;
  (event: 'isLoading', value: boolean): void;
  (event: 'input', value: Event): void;
}>();

const getComponent = computed(() => {
  const { type } = props.field.options || {};
  if (type === 'audio') {
    return AudioMedia;
  } else if (type === 'video') {
    return VideoMedia;
  }
  return ImageMedia;
});

function clickCamera(data) {
  emit('clickCamera', data);
}

function clickVideo(data) {
  emit('clickVideo', data);
}

function clickSetPreview(data) {
  emit('clickSetPreview', data);
}

function isLoading(value) {
  emit('isLoading', value);
}

function input(value) {
  emit('input', value);
}
</script>

<template>
  <component
    :is="getComponent"
    v-if="getComponent && inputValue"
    :field="field"
    :value="inputValue"
    :inputValues="inputValues"
    :repeatable="!!repeatable"
    @clickCamera="clickCamera"
    @clickVideo="clickVideo"
    @clickSetPreview="clickSetPreview"
    @isLoading="isLoading"
    @input="input"
  />
</template>
