<script>
export default {
  name: 'TextTestValueInput',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);

const text = computed({
  get() {
    return props.modelValue.value;
  },
  set(value) {
    emit('update:modelValue', {
      value,
      value2: props.modelValue.value2,
    });
  },
});
</script>

<template>
  <input v-model="text" type="text" class="form-control form-control-sm" />
</template>
