<script setup lang="ts">
import type { GatherField } from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import { ref, watch } from 'vue';

const props = defineProps<{
  tabId: number;
  itemTitleFieldId?: number;
  fields: GatherField[];
}>();
const useFieldAsItemTitleCheckboxId = ref(makeId());
const emit = defineEmits(['change']);

const shouldUseFieldAsItemTitle = ref(!!props.itemTitleFieldId);
const selectedFieldId = ref<number | null>(props.itemTitleFieldId ?? null);

watch(shouldUseFieldAsItemTitle, (newValue) => {
  selectedFieldId.value = newValue ? props.fields[0].id : null;
});
watch(selectedFieldId, (newValue) => {
  emit('change', newValue);
});
</script>

<template>
  <div>
    <div class="form-check">
      <input
        :id="useFieldAsItemTitleCheckboxId"
        v-model="shouldUseFieldAsItemTitle"
        class="form-check-input"
        type="checkbox"
      />
      <label class="form-check-label" :for="useFieldAsItemTitleCheckboxId">
        Use a field as the title of collected items
      </label>
    </div>
    <select
      v-if="shouldUseFieldAsItemTitle"
      v-model="selectedFieldId"
      class="form-control"
    >
      <option v-for="field in fields" :key="field.id" :value="field.id">
        {{ field.label }}
      </option>
    </select>
  </div>
</template>
