<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, computed, nextTick } from 'vue';

const itemContext = ref<any>(null);
const menuWidth = ref<null | number>(null);
const menuHeight = ref<null | number>(null);
const shown = ref(false);
const inCooldown = ref(true);
const elementId = ref('context-menu-' + Math.random());

const emits = defineEmits(['menuClosed', 'menuOpened']);

const props = withDefaults(
  defineProps<{
    options: any[];
    closeOnScroll?: boolean;
  }>(),
  {
    closeOnScroll: false,
  }
);

function getMenu() {
  return document.getElementById(elementId.value);
}

defineExpose({ open, close, getMenu });

let hasScrollEvent = false;

onMounted(() => {
  document.body.addEventListener('keyup', onEscKeyRelease);
  if (props.closeOnScroll) {
    document.body.addEventListener('scroll', onScrollEvent);
    hasScrollEvent = true;
  }
});

onBeforeUnmount(() => {
  document.body.removeEventListener('keyup', onEscKeyRelease);
  if (hasScrollEvent) {
    document.body.removeEventListener('scroll', onScrollEvent);
  }
});

const filteredOptions = computed(() => {
  return props.options.filter((option) => {
    if (option.canUse && itemContext.value) {
      return option.canUse(itemContext.value);
    }
    return true;
  });
});

async function open(event: MouseEvent, item: any = null) {
  itemContext.value = item;

  updateMenuPosition(event);
  shown.value = true;
  emits('menuOpened', itemContext.value);

  // Cooldown to prevent the v-click-outside from closing the menu
  inCooldown.value = true;

  // Wait for the menu to be rendered, to get an accurate width and height
  await nextTick();
  updateMenuPosition(event);

  inCooldown.value = false;
}

async function updateMenuPosition(event: MouseEvent) {
  let menu = getMenu();
  if (!menu) {
    console.warn(
      'Cannot find menu for repositioning, will try again in a tick'
    );
    await nextTick();
    menu = getMenu();
    if (!menu) {
      console.error('Cannot find menu for repositioning, giving up');
      return;
    }
  }

  menu.style.visibility = 'hidden';
  menu.style.display = 'block';
  menuWidth.value = menu.offsetWidth;
  menuHeight.value = menu.offsetHeight;
  menu.removeAttribute('style');

  if (menuWidth.value + event.pageX >= window.innerWidth) {
    menu.style.left = event.pageX - menuWidth.value + 2 + 'px';
  } else {
    menu.style.left = event.pageX - 2 + 'px';
  }

  if (menuHeight.value + event.pageY - window.scrollY >= window.innerHeight) {
    menu.style.top = event.pageY - window.scrollY - menuHeight.value + 2 + 'px';
  } else {
    menu.style.top = event.pageY - window.scrollY - 2 + 'px';
  }
}

function close() {
  hideContextMenu();
}

function hideContextMenu() {
  const menu = getMenu();
  if (menu) {
    shown.value = false;
    emits('menuClosed');
  }
}

function onClickOutside() {
  if (inCooldown.value) {
    return;
  }
  hideContextMenu();
}

function optionClicked(option) {
  if (option.disabled) {
    return;
  }

  if (!option.click) {
    return;
  }

  hideContextMenu();
  option.click(itemContext.value);
}

function onEscKeyRelease(event) {
  if (event.keyCode === 27) {
    hideContextMenu();
  }
}

function onScrollEvent(event) {
  hideContextMenu();
}

function isOptionDisabled(option) {
  if (typeof option.disabled === 'function') {
    return option.disabled(itemContext.value);
  }
  return option.disabled === true;
}
</script>

<template>
  <div
    :id="elementId"
    v-click-outside="onClickOutside"
    class="v-context"
    :class="{ 'd-none': !shown }"
  >
    <ul @click="hideContextMenu">
      <li
        v-for="(option, index) in filteredOptions"
        :key="index"
        :class="
          (option.class || '') +
          ' ' +
          (option.type === 'divider'
            ? 'v-context__divider'
            : 'v-context__item') +
          (option.danger === true ? ' text-danger' : '') +
          (isOptionDisabled(option) ? ' disabled' : '')
        "
        @click.stop="optionClicked(option)"
      >
        <span v-if="option.type !== 'divider'" v-html="option.label" />
      </li>
    </ul>
  </div>
</template>
