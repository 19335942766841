<script>
export default {
  name: 'CheckValueContentInput',
};
</script>

<script setup>
import { computed, onMounted } from 'vue';

const checked = defineModel({ type: String, required: true });

const isYes = computed(() => {
  return checked.value?.toLowerCase() === 'yes';
});
const isNo = computed(() => {
  return checked.value?.toLowerCase() === 'no';
});

onMounted(() => {
  if (!checked.value) {
    checked.value = 'yes';
  }
});
</script>

<template>
  <div class="form-group">
    <div class="btn-group btn-group-toggle">
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isYes,
        }"
        @click="() => (checked = 'yes')"
      >
        Yes
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isNo,
        }"
        @click="() => (checked = 'no')"
      >
        No
      </button>
    </div>
  </div>
</template>
