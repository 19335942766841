<template>
  <div
    v-if="section && fields"
    :class="[
      'template-section',
      {
        'draggable-header': !isLabelInputFocused,
      },
    ]"
    @click="!selected && emit('selectSection', sectionIndex)"
  >
    <div
      :class="[
        'section-header p-3 ps-0 text-white d-flex align-items-center not-rounded-bottom',
        {
          'bg-dark': !selected,
          'bg-primary': selected,
        },
      ]"
      style="cursor: grab"
      @click="
        fieldIndex = null;
        emit('selectSection', sectionIndex);
      "
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex align-items-center">
          <div class="draggable-layer">
            <div class="draggable-control"></div>
          </div>

          <InfoButton
            v-if="invalidNameError"
            class="me-2"
            backgroundColor="#FF0000"
            :info="invalidNameError"
          />

          <span
            v-if="!isLabelEditable"
            v-tooltip="invalidNameError"
            :class="['h6 mb-0', { 'text-danger': invalidNameError }]"
          >
            {{ label }}
          </span>
          <input
            v-else
            ref="sectionLabelInput"
            v-model="label"
            v-tooltip="invalidNameError"
            type="text"
            :class="[
              'border-0 bg-transparent h6 mb-0 w-100',
              {
                'text-danger is-invalid': invalidNameError,
                'text-white': !invalidNameError,
              },
            ]"
            placeholder="Name section here"
            @keyup.enter="handleClearSection"
            @focus="handleLabelInputFocus"
            @blur="handleLabelInputBlur"
          />
        </div>

        <div v-if="isUpdating" class="d-flex align-items-center">
          <Spinner small />
        </div>

        <div
          v-if="!isUpdating && modelValue.id"
          class="d-flex align-items-center"
        >
          <span
            v-if="hasPublic && modelValue.is_public_form"
            class="badge bg-danger ms-2"
          >
            <i class="fal fa-globe-asia"></i>
            Included on Public Form
          </span>
          <span v-if="modelValue.is_repeatable" class="badge bg-dark ms-2">
            <i class="fal fa-repeat"></i>
            Repeatable
          </span>

          <template
            v-if="selected && fieldIndex == null && !modelValue.is_permanent"
          >
            <button
              v-if="
                !modelValue.is_gps_point_metadata && !showDeleteConfirmation
              "
              class="btn btn-light btn-sm ms-2 me-1"
              :disabled="isTemplateEditorOperating"
              @click.stop="emit('duplicate')"
            >
              <i v-if="!isDuplicating" class="fal fa-copy"></i>
              <Spinner v-else small />
            </button>

            <div
              v-if="showDeleteConfirmation"
              class="btn-group btn-group-sm ms-2"
            >
              <button
                class="btn btn-outline-light"
                @click="() => (showDeleteConfirmation = false)"
              >
                <i class="fal fa-times" />
              </button>
              <button
                class="btn btn-outline-danger"
                :disabled="isTemplateEditorOperating"
                @click="emit('delete', sectionIndex)"
              >
                <i class="fal fa-check" />
              </button>
            </div>
            <button
              v-else
              data-cy="section-remove"
              class="btn btn-outline-danger btn-sm ms-1"
              :disabled="isTemplateEditorOperating"
              @click="() => (showDeleteConfirmation = true)"
            >
              <i class="fal fa-trash-alt"></i>
            </button>
          </template>

          <h6
            :class="[
              'fas mb-0 clickable ms-2',
              {
                'fa-chevron-down': section.collapsed,
                'fa-chevron-up': !section.collapsed,
              },
            ]"
            @click="toggleSectionCollapsed(section)"
          />
        </div>
      </div>
    </div>
    <div
      v-show="!section.collapsed"
      data-cy="list-group-fields"
      class="list-group"
    >
      <div
        :class="{
          clickable:
            mobileSize && modelValue.template_fields.length === 0 && !disabled,
        }"
        @click="
          mobileSize &&
            modelValue.template_fields.length === 0 &&
            emit('openAddFields')
        "
      >
        <Draggable
          class="position-relative"
          draggable=".field-draggable"
          itemKey="id"
          :group="fieldsDraggableGroup"
          :modelValue="modelValue.template_fields"
          :disabled="disabled || checkDisabled"
          :sort="!isTemplateEditorOperating"
          :handle="draggableHandle"
          @change="handleFieldsChange"
        >
          <template v-if="modelValue.template_fields.length == 0" #header>
            <div v-if="modelValue.id" class="empty-fields py-5 px-3">
              <h1 class="fal fa-line-columns"></h1>
              <h6 v-if="mobileSize">Click here to get started!</h6>
              <h6 v-else>Drag a field here to get started!</h6>
              <p class="text-muted mb-0">
                It appears that you have not added any fields yet.
              </p>
            </div>
            <div v-else>
              <div
                class="p-4 d-flex align-items-center justify-content-center flex-column text-center w-100"
              >
                <Spinner large />
                <small class="text-muted mt-2 d-block"
                  >Creating section...</small
                >
              </div>
            </div>
          </template>
          <template #item="{ element, index }">
            <TemplateFieldDraggable
              :modelValue="modelValue.template_fields[index]"
              :index="index"
              :tab="tab"
              :section="section"
              :selected="fieldIndex == index && selected"
              :disabled="disabled || !element.id"
              :isTemplateEditorOperating="isTemplateEditorOperating"
              @updateLabel="updateLabel"
              @selectField="selectField"
              @delete="deleteField(element)"
              @clearSection="handleClearSection"
            />
          </template>
        </Draggable>
        <div
          v-if="modelValue.template_fields.length > 0 && !disabled"
          class="has-fields"
          :class="{
            clickable:
              mobileSize && modelValue.template_fields.length > 0 && !disabled,
          }"
          @click="emit('openAddFields')"
        >
          {{ mobileSize ? 'tap to add fields' : 'drag and drop fields above' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { findAppByFieldId } from '@component-library/business-logic/app';
import Field from '@component-library/classes/Field';
import Section from '@component-library/classes/Section';
import InfoButton from '@component-library/components/InfoButton.vue';
import Spinner from '@component-library/components/Spinner.vue';
import EventBus from '@component-library/EventBus';
import { App, getSectionReferenceError } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';
import Draggable from 'vuedraggable';
import TemplateFieldDraggable from './TemplateFieldDraggable.vue';

const toastStore = useToastStore();
const props = defineProps([
  'aFieldIndex',
  'selected',
  'disabled',
  'tab',
  'tabs',
  'modelValue',
  'sectionIndex',
  'isUpdating',
  'isDuplicating',
  'isTemplateEditorOperating',
  'mobileSize',
]);

const emit = defineEmits<{
  (event: 'update:modelValue', value: Section): void;
  (event: 'update:aFieldIndex', value: number): void;
  (
    event: 'createField',
    value: {
      sectionIndex: number;
      fieldIndex: number;
      value: Field;
    }
  ): void;
  (
    event: 'updateField',
    value: { sectionIndex: number; fieldIndex: number; value: Field }
  ): void;
  (event: 'updateFieldLabel', value: { index: number; label: string }): void;
  (event: 'deleteField', value: { field: Field; app: App }): void;
  (event: 'selectSection', value: number): void;
  (event: 'clearSection'): void;
  (event: 'selectField', value: number): void;
  (
    event: 'updateFieldLabel',
    value: { index: number; fieldIndex: number; label: string }
  ): void;
  (event: 'openAddFields'): void;
  (event: 'duplicate'): void;
  (event: 'delete', sectionIndex: number): void;
}>();

const showDeleteConfirmation = ref(false);
const isLabelInputFocused = ref(false);
const labelValue = ref('');
const sectionLabelInput = ref<HTMLInputElement>();

const checkDisabled = computed(() => {
  if (props.modelValue instanceof Section) return props.modelValue.isDisabled();
  return true;
});

const section = computed(() => {
  return props.modelValue;
});
const label = computed<string>({
  get() {
    return labelValue.value;
  },
  set(updated) {
    labelValue.value = updated;
    const label = updated.trim();
    if (!label) {
      return;
    }

    emit('update:modelValue', {
      ...props.modelValue,
      label,
    });
  },
});

const invalidNameError = computed(() => {
  return getSectionReferenceError(props.modelValue, props.tab);
});

const fields = computed(() => {
  return props.modelValue.template_fields;
});

const hasPublic = computed(() => {
  return props.tab?.public_link;
});

const fieldIndex = computed({
  get() {
    return props.aFieldIndex;
  },
  set(updated) {
    emit('update:aFieldIndex', updated);
  },
});

const draggableHandle = computed(() => {
  return window.innerWidth < 500 ? '.draggable-field' : '.list-group-item';
});

const fieldsDraggableGroup = computed(() => {
  return {
    name: 'fields',
    pull: !props.isTemplateEditorOperating,
    put: (to, from) => {
      return (
        to.options.group.name === 'fields' &&
        from.options.group.name === 'fields' &&
        !props.isTemplateEditorOperating
      );
    },
  };
});

const isLabelEditable = computed(() => {
  return (
    props.selected &&
    !section.value.is_gps_point_metadata &&
    !section.value.options?.disabled
  );
});

watch(
  () => props.selected,
  () => {
    if (!props.selected) {
      fieldIndex.value = null;
    }
  }
);

watch(
  () => props.modelValue.label,
  () => {
    if (!isLabelInputFocused.value) {
      labelValue.value = props.modelValue.label;
    }
  }
);

function updateField({ index, value }) {
  emit('updateField', {
    sectionIndex: props.sectionIndex,
    fieldIndex: index,
    value,
  });
}

function updateLabel({ index, label }) {
  emit('updateFieldLabel', {
    index: props.sectionIndex,
    fieldIndex: index,
    label,
  });
}

async function deleteField(field: Field) {
  if (field.options?.disabled || props.disabled) {
    toastStore.error('This field cannot be deleted');
    return;
  }
  if (!field.id) {
    toastStore.error('This field cannot be deleted');
    throw new Error('Field does not have an id');
  }
  try {
    const app = findAppByFieldId(props.tabs, field.id);
    await field.delete();
    props.modelValue.template_fields.splice(
      props.modelValue.template_fields.indexOf(field),
      1
    );
    props.modelValue.template_fields.forEach((item, index) => {
      item.order = index;
    });
    fieldIndex.value = null;
    // @ts-expect-error ???
    emit('deleteField', { field, app });
  } catch (e) {
    toastStore.unexpected(
      e,
      'Failed to delete field, please refresh and try again'
    );
    throw e;
  }
}

function selectField(index) {
  if (props.disabled) {
    fieldIndex.value = null;
    return;
  }

  if (!props.selected) {
    emit('selectSection', props.sectionIndex);
  }
  nextTick(() => {
    fieldIndex.value = index;
    emit('selectField', index);
  });
}

function toggleSectionCollapsed(section) {
  section.collapsed = !section.collapsed;
}

function handleFieldsChange({ added, moved }) {
  if (added) {
    if (props.modelValue.is_gps_point_metadata) {
      toastStore.info('Adding a new field into this section is not allowed.');
      return;
    }

    const { element, newIndex } = added;
    // When template_fields is empty, the newIndex is 1 because of the placeholder
    // in the draggable component. It needs to be corrected to 0.
    const fieldIndex = props.modelValue.template_fields.length ? newIndex : 0;
    if (!element.id) {
      emit('createField', {
        sectionIndex: props.sectionIndex,
        fieldIndex,
        value: { ...element, template_section_id: props.modelValue.id },
      });
      selectField(fieldIndex);
    } else {
      updateField({ index: fieldIndex, value: element });
    }
  } else if (moved) {
    const { element, oldIndex: fieldIndex, newIndex: order } = moved;
    updateField({ index: fieldIndex, value: { ...element, order } });
  }
}

function handleLabelInputFocus() {
  isLabelInputFocused.value = true;
}

function handleLabelInputBlur() {
  isLabelInputFocused.value = false;
}

function handleClearSection() {
  emit('clearSection');
}

onMounted(() => {
  labelValue.value = props.modelValue.label;
  EventBus.$on('selectField', (newFieldIndex: number | null) => {
    fieldIndex.value = newFieldIndex;
    // nextTick(() => {
    //   fieldIndex.value = field;
    // });
  });

  EventBus.$on('clearField', () => {
    fieldIndex.value = null;
  });

  if (isLabelEditable.value) {
    if (!sectionLabelInput.value) {
      throw new Error('sectionLabelInput is not defined');
    }
    sectionLabelInput.value.focus();
    sectionLabelInput.value.select();
  }
});
onBeforeUnmount(() => {
  EventBus.$off('selectField');
  EventBus.$off('clearField');
});
</script>

<style scoped>
.section-header .draggable-layer {
  height: 30px;
}

.section-header:hover .draggable-control {
  visibility: visible;
}

.list-group {
  border: 1px solid #f4f4f4;
  border-top: 0px;
}

.empty-fields {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.has-fields {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757da1;
  font-size: 0.875em;
  border: 1px dashed rgba(0, 0, 0, 0.125);
  border-top: none;
  background: #f7f7f7;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
</style>
