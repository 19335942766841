<script lang="ts" setup>
import { App } from '@component-library/gather';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import { computed, ref } from 'vue';
import { useToastStore } from '@component-library/store/toasts';
import axios from 'axios';
import { copyToClipboard } from '@component-library/utils/clipboard';
import { useDialogStore } from '@component-library/store/dialog';

const props = defineProps<{
  tab: App;
  publicSectionCount: number;
  mobileSize: boolean;
}>();

const toastStore = useToastStore();
const dialogStore = useDialogStore();
const showDefaultOptions = ref(false);

const fullPublicLink = computed(() => {
  return import.meta.env.VITE_APP_URL + '/form/' + props.tab.public_link;
});

const errorBag = computed(() => {
  let errors: string[] = [];
  if (!props.tab.title || props.tab.title === '') {
    errors.push('Make sure to set the App title');
  }
  if (props.publicSectionCount == 0) {
    errors.push(
      'Please pick at least one field or section on the right and check "Include on Public Form"'
    );
  }
  return errors;
});

function copyLink() {
  if (!props.tab.public_link) {
    return;
  }
  copyToClipboard(fullPublicLink.value);
  toastStore.success('Copied the link to your clipboard.');
}

function openLink() {
  if (!props.tab.public_link) {
    toastStore.error('No link to open.');
    throw new Error('No link to open.');
  }
  window.open(fullPublicLink.value, '_blank');
}

function refreshPublicURL() {
  dialogStore.confirmDanger(
    'Change URL',
    'Are you sure you wish to change the URL? This will close the form via the current link.',
    async () => {
      const response = await axios.post(
        'api/template/share-app/' + props.tab.id + '/share-public'
      );

      props.tab.public_link = response.data.public_link;
      toastStore.success('URL has been changed.');

      copyLink();
    }
  );
}
</script>

<template>
  <div class="mb-3">
    <ResponsiveOrSlideUp
      v-model="showDefaultOptions"
      title="Public Form Link"
      :mobileSize="mobileSize"
    >
      <template #body>
        <div v-if="errorBag.length" class="alert alert-warning">
          <ul class="mb-0">
            <li v-for="error of errorBag" :key="error">{{ error }}</li>
          </ul>
        </div>
        <div v-else>
          <div
            class="alert alert-success mb-0 cursor-pointer d-flex align-items-center"
            @click.prevent="copyLink"
          >
            <a
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              href="#"
            >
              {{ fullPublicLink }}
            </a>
            <i class="float-end fas fa-copy fa-fw mx-1"></i>
          </div>
          <div class="d-flex mt-2">
            <a class="btn btn-light me-1 flex-grow-1" @click="openLink">
              Open link <i class="fas fa-external-link fa-fw mx-1"></i>
            </a>
            <a class="btn btn-light ms-1 flex-grow-1" @click="refreshPublicURL"
              >Refresh the URL</a
            >
          </div>
          <p class="text-muted px-2 mt-2">
            You may share the link above to anyone. They will be able to submit
            a
            <span class="text-danger">public form of specified fields</span>
            without a Datanest login.
          </p>
        </div>
      </template>
    </ResponsiveOrSlideUp>
  </div>
</template>
