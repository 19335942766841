<script setup lang="ts">
import Field from '@component-library/classes/Field';
import AlertBox from '@component-library/components/AlertBox.vue';
import { GatherFieldOptions } from '@component-library/gather';
import { ref } from 'vue';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import AutoAssignByConditions from './options/AutoAssignByConditions.vue';
import AutoAssignByExpression from './options/AutoAssignByExpression.vue';
import AutoAssignByLinkedApp from './options/AutoAssignByLinkedApp.vue';
import AutoAssignByLookup from './options/AutoAssignByLookup.vue';

const props = defineProps<{
  field: Field;
  options: GatherFieldOptions;
  fields: Field[];
  mobileSize: boolean;
}>();

const emit = defineEmits<{
  (e: 'updateOptions', options: any[]): void;
}>();

const showDefaultOptions = ref((props.options?.defaults?.length || 0) > 0);
</script>

<template>
  <ResponsiveOrSlideUp
    v-model="showDefaultOptions"
    title="Auto Assign"
    :mobileSize="mobileSize"
  >
    <template #body>
      <div v-if="fields.length > 0">
        <AutoAssignByConditions
          :field="field"
          :fields="props.fields"
          @updateOptions="(data) => emit('updateOptions', data)"
        />
        <AutoAssignByLookup
          :field="field"
          :fields="fields"
          @updateOptions="(data) => emit('updateOptions', data)"
        />
        <AutoAssignByLinkedApp
          :field="field"
          :fields="props.fields"
          @updateOptions="(data) => emit('updateOptions', data)"
        />
        <AutoAssignByExpression
          :field="field"
          :fields="fields"
          @updateOptions="(data) => emit('updateOptions', data)"
        />
      </div>
      <AlertBox v-else type="info"
        ><template #default>
          Auto Assign requires at least one field of type Text, Number,
          Choice/Dropdown, Lithology, Yes / No Choice, Expression, or Link
          Another App in the section.
        </template></AlertBox
      >
    </template></ResponsiveOrSlideUp
  >
</template>
