<template>
  <responsive-or-slide-up
    v-model="isExpanded"
    title="Field Options"
    :mobileSize="mobileSize"
  >
    <template #body>
      <component
        :is="componentName"
        :value="sectionField"
        @input="updateField"
        @blur="clearField"
      />
    </template>
  </responsive-or-slide-up>
</template>

<script>
import EventBus from '@component-library/EventBus';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import * as gpsPointMetadataOptions from './options/gps-point-metadata';

export default {
  name: 'GpsPointMetadataSectionFieldOptions',
  components: {
    ResponsiveOrSlideUp,
    ...gpsPointMetadataOptions,
  },
  props: [
    'value',
    'sectionField',
    'sectionIndex',
    'fieldIndex',
    'mobileSize',
    'tab',
  ],
  emits: ['updateField', 'clearField'],
  data: () => ({
    isExpanded: true,
  }),
  computed: {
    componentName() {
      const { system_reference } = this.sectionField;
      if (['pole_height', 'phase_center_offset'].includes(system_reference)) {
        return 'OnlyDefaultValue';
      } else if (system_reference === 'corrected_elevation') {
        return 'CorrectedElevation';
      } else {
        return 'NotConfigurable';
      }
    },
  },
  methods: {
    updateField(value) {
      this.$emit('updateField', {
        sectionIndex: this.sectionIndex,
        fieldIndex: this.fieldIndex,
        value: { ...this.sectionField, ...value },
      });
    },
    clearField() {
      this.$emit('clearField');
      EventBus.$emit('clearField');
    },
  },
};
</script>
