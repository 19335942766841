<script setup lang="ts"></script>

<template>
  <div class="indeterminate-progress-bar">
    <div class="indeterminate-progress-bar-value"></div>
  </div>
</template>

<style lang="scss" scoped>
.indeterminate-progress-bar {
  height: 4px;
  background-color: var(--primary-color);
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .indeterminate-progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: white;
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
  }

  @keyframes indeterminateAnimation {
    0% {
      transform: translateX(0) scaleX(0);
    }
    40% {
      transform: translateX(0) scaleX(0.4);
    }
    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
}
</style>
