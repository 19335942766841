<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Unit</label>

      <input
        class="form-control"
        list="units"
        :value="field.options.unit"
        @keypress.enter="$emit('blur')"
        @input="
          $emit('update', {
            key: 'unit',
            value: $event.target.value,
          })
        "
      />
      <datalist id="units">
        <option value="metre">Metre</option>
        <option value="feet">Feet</option>
        <option value="millimeter">Millimeter</option>
        <option value="centimeter">Centimeter</option>
        <option value="inch">Inch</option>
      </datalist>
    </div>

    <div class="form-group mb-3">
      <label class="form-label">Max Decimal Places</label>

      <input
        class="form-control"
        type="number"
        min="0"
        max="9"
        placeholder="Default: 0"
        :value="field.options.maxDecimals"
        @keypress.enter="$emit('blur')"
        @input="
          $emit('update', {
            key: 'maxDecimals',
            value: Math.min(9, Math.max(0, $event.target.value)),
          })
        "
      />
    </div>

    <button
      class="btn btn-primary w-100"
      @click="$emit('showExpressionEditor')"
    >
      Open Editor
    </button>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
  },
  emits: ['blur', 'update', 'showExpressionEditor'],
};
</script>
