<template>
  <div>
    <div class="input-group">
      <select v-model="field_id" class="form-control">
        <option
          v-for="fieldOption in fields"
          :key="fieldOption.id"
          :value="fieldOption.id"
        >
          {{ fieldOption.label }}
        </option>
      </select>

      <select v-model="operator" class="form-control">
        <option
          v-for="(operator, operatorIndex) in operators"
          :key="operatorIndex"
          :value="operator"
        >
          {{ operator }}
        </option>
      </select>

      <select v-if="hasOptions()" v-model="conditionValue" class="form-control">
        <option
          v-for="fieldValue in getValues(condition)"
          :key="fieldValue.value"
          :value="fieldValue.value"
        >
          {{ fieldValue.displayValue }}
        </option>
      </select>
      <input v-else v-model="conditionValue" type="text" class="form-control" />

      <button
        type="button"
        class="btn btn-outline-danger"
        @click="removeCondition"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </div>
    <div v-if="isDefault" :key="'selection-' + index" class="mt-1">
      <select
        v-model="conditionDefault"
        class="form-control form-control-sm d-flex flex-grow"
        placeholder="Select values"
      >
        <option :value="null" selected>All values</option>
        <option
          v-for="(group, groupKey) in groups"
          :key="groupKey"
          :value="groupKey"
        >
          {{ group.title || `Group ${groupKey + 1}` }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  checkHasNumberValue,
  checkIsSelectSupported,
  getSelectOptions,
} from '@component-library/fields';
import Field from '@component-library/classes/Field';
import { computed, nextTick, onMounted, watch } from 'vue';
import { Condition } from '@component-library/gather';

const props = defineProps<{
  condition: Condition;
  index: number;
  fields: Field[];
  isDefault: boolean;
  groups?: { title: string }[];
}>();

const emit = defineEmits<{
  (event: 'update', value: { condition: Condition; index: number }): void;
  (event: 'removeCondition'): void;
}>();

const operators = computed(() => {
  const field = props.fields.find((f) => f.id === props.condition.field_id);
  if (field && checkHasNumberValue(field)) {
    return ['=', '!=', '<', '<=', '>', '>='];
  }

  return ['=', '!='];
});

const field_id = computed({
  get() {
    return props.condition.field_id;
  },
  set(field_id) {
    emit('update', {
      condition: { ...props.condition, field_id },
      index: props.index,
    });
  },
});

const operator = computed({
  get() {
    return props.condition.operator;
  },
  set(operator) {
    emit('update', {
      condition: { ...props.condition, operator },
      index: props.index,
    });
  },
});

const conditionValue = computed({
  get() {
    return props.condition.value;
  },
  set(value) {
    emit('update', {
      condition: { ...props.condition, value },
      index: props.index,
    });
  },
});

const conditionDefault = computed({
  get() {
    return props.condition.default;
  },
  set(aDefault) {
    emit('update', {
      condition: { ...props.condition, default: aDefault },
      index: props.index,
    });
  },
});

function getValues(condition: Condition) {
  const field = props.fields.find((f) => f.id == condition.field_id);

  if (!field) {
    return [];
  }

  return getSelectOptions(field);
}

function hasOptions() {
  const conditionField = props.fields.find(
    (f) => f.id === props.condition.field_id
  );
  return !!conditionField && checkIsSelectSupported(conditionField);
}

function removeCondition() {
  emit('removeCondition');
}
watch(field_id, async () => {
  conditionValue.value = null;
  await nextTick();
  operator.value = operators.value[0];
});

onMounted(() => {
  if (props.condition.field_id == null && props.condition.field_label) {
    const foundField = props.fields.find(
      (aField) => aField.label == props.condition.field_label
    );
    if (foundField) {
      field_id.value = foundField.id;
    }
  }
});
</script>
