<script lang="ts" setup>
import Spinner from './Spinner.vue';

type ButtonColor =
  | 'outline-secondary'
  | 'outline-primary'
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'outline-danger'
  | 'success'
  | 'outline-success';

const props = withDefaults(
  defineProps<{
    color?: ButtonColor;
    disabled?: boolean;
    outline?: boolean;
    width?: never; // use class
    margin?: never; // use class
    large?: boolean;
    small?: boolean;
    type?: 'button' | 'submit';
    icon?: string | null;
    showSpinner?: boolean;
    confirm?: string | null;
  }>(),
  {
    color: 'outline-secondary',
    type: 'button',
    disabled: false,
    large: false,
    small: false,
    icon: null,
    confirm: null,
  }
);

const emit = defineEmits(['click']);

function click() {
  // @ts-expect-error ts with vue is broken?
  if (props.disabled) {
    return;
  }

  // @ts-expect-error ts with vue is broken?
  if (props.confirm && !confirm(props.confirm)) {
    return;
  }

  emit('click');
}
</script>

<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="`btn btn-${outline ? 'outline-' : ''}${color} ${
      small ? 'btn-sm' : ''
    } ${disabled ? 'disabled' : ''} ${large ? 'btn-lg' : ''} `"
    @click="click"
  >
    <Spinner v-if="showSpinner" small />
    <i v-else-if="icon" :class="`${icon} fa-fw`"></i>
    <slot />
  </button>
</template>
