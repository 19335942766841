<template>
  <div>
    <label class="form-label">Options</label>

    <div class="alert alert-info">
      Template links allow you to link data between different Gather Apps.
    </div>

    <div v-if="!isLoading" class="form-group mb-3">
      <label class="form-label">Pick a Gather App to reference</label>
      <select
        class="form-control"
        required
        :value="field.options.template_tab_title || ''"
        @input="
          $emit('update', {
            key: 'template_tab_title',
            value: $event.target.value,
          })
        "
      >
        <option value="" disabled>Select One</option>
        <option v-for="item of dataItems" :key="item.id" :value="item.title">
          {{ item.title }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    field: Object,
  },
  emits: ['update'],
  data: () => ({
    dataItems: [],
    isLoading: true,
  }),
  methods: {
    async loadItems() {
      const response = await axios.get('/api/project/template-types');
      this.dataItems = response.data.tabs;
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
