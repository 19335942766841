<template>
  <div>
    <Drawer v-if="mobileSize" ref="drawer">
      <template #header>
        <h6 class="mb-0">
          {{
            selectedField
              ? selectedField.label
              : selectedSection
              ? selectedSection.label
              : ''
          }}
          Options
        </h6>
      </template>
      <div id="mobile-template-options" class="px-4 pb-4"></div>
    </Drawer>
    <div
      v-if="tab"
      :class="[
        'row position-relative',
        {
          disabled: disabled,
        },
      ]"
    >
      <div
        v-if="!disabled && !mobileSize"
        class="col-lg-3 mb-5 pb-5"
        :style="
          mobileSize
            ? ''
            : 'max-height: 90vh; overflow-y: auto; top: 4rem; position: sticky'
        "
      >
        <div class="mb-3">
          <TemplateAvailableDraggables
            v-model="sections"
            :template="tab"
            :disabled="disabled"
            :selectedSection="sectionIndex"
            :isTemplateEditorOperating="isTemplateEditorOperating"
            :mobileSize="mobileSize"
            @addSection="addSection"
            @addField="createField"
          />
        </div>
      </div>
      <div
        :class="{
          'col-lg-6': !disabled,
          'col-lg-9': disabled,
        }"
        :style="mobileSize ? 'margin-bottom: 80px' : ''"
      >
        <button
          v-if="hasHSSection"
          type="button"
          class="btn btn-outline-primary btn-lg w-100 mb-3"
          @click="showHSCategoryModal = true"
        >
          Select H&S Categories
        </button>

        <ProgressBar
          v-if="isLoadingSections"
          :progress="loadingProgress"
          title="Loading Sections..."
        />
        <Draggable
          v-else
          class="h-100 p-0"
          draggable=".template-section.draggable-header"
          ghostClass="section-header-draggable"
          handle=".section-header"
          :group="sectionsDraggableGroup"
          :modelValue="sections"
          :disabled="disabled"
          :sort="!isTemplateEditorOperating"
          :itemKey="(item) => item.id"
          @change="handleSectionsChange"
        >
          <template v-if="sections.length === 0" #header>
            <div
              class="text-center d-flex align-items-center justify-content-center flex-column py-5"
            >
              <h1 class="fal fa-line-columns"></h1>
              <h6 class="mb-0">
                {{
                  mobileSize
                    ? 'Click the "+" to get started'
                    : 'Drag a section here to get started'
                }}
              </h6>
            </div>
          </template>
          <template #item="{ element, index }">
            <TemplateSectionDraggable
              v-model:aFieldIndex="fieldIndex"
              class="mb-3"
              :modelValue="element"
              :isUpdating="element.isUpdating"
              :isDuplicating="element.isDuplicating"
              :sectionIndex="index"
              :tab="tab"
              :tabs="tabs"
              :disabled="disabled || element.isDeleting"
              :selected="sectionIndex == index"
              :isTemplateEditorOperating="isTemplateEditorOperating"
              :mobileSize="mobileSize"
              @update:modelValue="updateSection"
              @selectSection="selectSection"
              @clearSection="clearSection"
              @selectField="selectField"
              @createField="createField"
              @updateField="updateField"
              @updateFieldLabel="updateFieldLabel"
              @delete="removeSection(index)"
              @duplicate="duplicateSection(index)"
              @deleteField="handleDeleteField"
              @openAddFields="() => (showFieldModal = true)"
            />
          </template>
        </Draggable>
      </div>

      <div
        id="desktop-template-options"
        class="col-md-5 col-lg-3 position-sticky"
        style="z-index: 3; top: 4rem"
        :style="mobileSize ? '' : 'max-height: 90vh; overflow-y: auto'"
      ></div>
    </div>

    <Teleport
      v-if="!isLoadingSections"
      :to="
        mobileSize ? '#mobile-template-options' : '#desktop-template-options'
      "
    >
      <div class="sticky-container">
        <slot name="template-tab-editor"></slot>

        <template
          v-if="
            selectedField &&
            selectedSection &&
            sectionIndex !== null &&
            fieldIndex !== null &&
            !disabled
          "
        >
          <TemplateFieldOptions
            v-if="!selectedSection?.is_gps_point_metadata"
            :key="`field-options-${selectedField.id}`"
            :fieldIndex="fieldIndex"
            :sectionField="selectedField"
            :sectionIndex="sectionIndex"
            :section="selectedSection"
            :tab="tab"
            :mobileSize="mobileSize"
            class="mb-3"
            @updateField="updateField"
            @updateFieldOptions="updateFieldOptions"
            @update:section="updateSection"
            @addCondition="addCondition"
            @updateTabField="(data) => emit('updateTab', data)"
            @clearField="
              fieldIndex = null;
              sectionIndex = null;
            "
            @showExpressionEditor="handleShowExpressionEditor"
          />
          <GpsPointMetadataSectionFieldOptions
            v-else
            :fieldIndex="fieldIndex"
            :sectionField="selectedField"
            :sectionIndex="sectionIndex"
            :modelValue="selectedSection"
            :tab="tab"
            :mobileSize="mobileSize"
            class="mb-3"
            @updateField="updateField"
            @clearField="
              fieldIndex = null;
              sectionIndex = null;
            "
          />
        </template>

        <template v-else-if="selectedSection && !disabled">
          <TemplateSectionOptions
            v-if="!selectedSection.is_gps_point_metadata"
            class="mb-3"
            :modelValue="selectedSection"
            :app="tab"
            :mobileSize="mobileSize"
            :allowPublic="hasPublicLink"
            :firstSectionInPublicForm="firstSectionInPublicForm"
            @update:modelValue="updateSection"
            @clearSection="clearSection"
          />
          <GpsPointMetadataSectionOptions
            v-else
            class="mb-3"
            :section="selectedSection"
            :mobileSize="mobileSize"
            @clearSection="clearSection"
          />
        </template>

        <PublicFormOptions
          v-if="hasPublicLink"
          class="mb-3"
          :tab="tab"
          :publicSectionCount="
            sections.filter(
              (s) =>
                s.is_public_form ||
                s.template_fields.some((f) => f.options.is_public_form)
            ).length
          "
          :mobileSize="mobileSize"
        />

        <div v-if="showTabSettings" class="mb-2">
          <div
            class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-2"
          >
            <h6 class="mb-0">Template Settings</h6>
          </div>

          <label class="w-100">
            Identifier Prefix
            <input
              v-model="identifierPrefix"
              class="form-control"
              placeholder="E.g. HA, Photo, Item..."
            />
          </label>

          <div class="form-check float-end">
            <input
              id="identifier_increment"
              v-model="identifierIncrement"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label" for="identifier_increment">
              Autoincrement
            </label>
          </div>
        </div>

        <button
          v-if="mobileSize && !disabled"
          type="button"
          class="btn btn-light w-100 p-3 fw-bold mb-2"
          @click="addMobileSections"
        >
          Add Sections
        </button>

        <button
          type="button"
          class="btn btn-light w-100 p-3 fw-bold"
          @click="() => (showTemplatePreviewModal = true)"
        >
          Preview App
        </button>

        <template v-if="project">
          <button
            type="button"
            class="btn btn-light w-100 p-3 mt-2 fw-bold"
            @click="openGatherSupportPage"
          >
            Learn more about Gather Apps?
          </button>
          <button
            type="button"
            class="btn btn-primary w-100 p-3 mt-2 mb-3 fw-bold"
            @click="gotoMap"
          >
            Finish & Go To Gather
          </button>
        </template>
      </div>
    </Teleport>

    <TemplatePreviewModal
      v-if="showTemplatePreviewModal"
      :dataTab="tab"
      @close="() => (showTemplatePreviewModal = false)"
    />

    <ExpressionEditorModal
      v-if="selectedSection && selectedField && showExpressionEditorModal"
      :dataTab="tab"
      :value="selectedField"
      :sections="sections"
      @updateExpression="updateExpression"
      @close="() => (showExpressionEditorModal = false)"
    />

    <CategorySelectorModal
      v-if="showHSCategoryModal"
      :tab="tab"
      :updateTab="
        (categories) =>
          $emit('updateTab', {
            key: 'hs_categories',
            value: categories,
          })
      "
      @close="showHSCategoryModal = false"
    />

    <Modal v-if="mobileSize && showFieldModal" @close="showFieldModal = false">
      <TemplateAvailableDraggables
        v-model="sections"
        :template="tab"
        :disabled="disabled"
        :selectedSection="sectionIndex"
        :isTemplateEditorOperating="isTemplateEditorOperating"
        :mobileSize="mobileSize"
        @addSection="addSection"
        @addField="createField"
      />

      <template #footer>
        <a class="btn btn-primary w-100" @click="showFieldModal = false">
          Done
        </a>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import { findAppByFieldId } from '@component-library/business-logic/app';
import { checkIsInPublicForm } from '@component-library/business-logic/section';
import Section from '@component-library/classes/Section';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import Drawer from '@component-library/components/Drawer.vue';
import Modal from '@component-library/components/Modal.vue';
import ProgressBar from '@component-library/components/ProgressBar.vue';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { DATANEST_URL } from '@component-library/env';
import { FieldTypeIds } from '@component-library/fields';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  Teleport,
  watch,
} from 'vue';
import Draggable from 'vuedraggable';
import { useToastStore } from '@component-library/store/toasts';
import ExpressionEditorModal from '../expression/Modal.vue';
import CategorySelectorModal from '../health-and-safety/CategorySelectorModal.vue';
import GpsPointMetadataSectionFieldOptions from './GpsPointMetadataSectionFieldOptions.vue';
import GpsPointMetadataSectionOptions from './GpsPointMetadataSectionOptions.vue';
import PublicFormOptions from './PublicFormOptions.vue';
import TemplateAvailableDraggables from './TemplateAvailableDraggables.vue';
import TemplateFieldOptions from './TemplateFieldOptions.vue';
import TemplatePreviewModal from './TemplatePreviewModal.vue';
import TemplateSectionDraggable from './TemplateSectionDraggable.vue';
import TemplateSectionOptions from './TemplateSectionOptions.vue';

const toastStore = useToastStore();
const props = defineProps([
  'disabled',
  'project',
  'showTabSettings',
  'tab',
  'tabs',
  'shouldSave',
  'onFieldUpdated',
  'onFieldDeleted',
  'isTabBeingEdited',
]);
const emit = defineEmits<{
  (event: 'updateTemplateSections', value: Section[]): void;
  (event: 'save'): void;
  (event: 'goMap'): void;
  (event: 'updateTab', data: { key: string; value: any }): void;
  (event: 'finishEditingTab'): void;
}>();

defineExpose({ addSection, removeSection, updateFieldOptions, clearSection });
const sections = ref<Section[]>([]);
const sectionIndex = ref<number | null>(null);
const fieldIndex = ref<number | null>(null);
const showTemplatePreviewModal = ref(false);
const showExpressionEditorModal = ref(false);
const mobileSize = ref(false);
const showHSCategoryModal = ref(false);
const isLoadingSections = ref(true);
const loadingProgress = ref(0);
const showFieldModal = ref(false);
const drawer = ref<typeof Drawer>();

const identifierPrefix = ref('');
const identifierIncrement = ref(true);

const selectedSection = computed(() => {
  return sectionIndex.value !== null
    ? sections.value[sectionIndex.value]
    : null;
});

const selectedField = computed(() => {
  return selectedSection.value && fieldIndex.value !== null
    ? selectedSection.value.template_fields[fieldIndex.value]
    : null;
});

const firstSectionInPublicForm = computed(() => {
  return sections.value.find((item) => checkIsInPublicForm(item));
});

const hasPublicLink = computed(() => {
  return !!props.tab?.public_link;
});

const hasHSSection = computed(() => {
  return props.tab.sections.findIndex((s) => s.is_health_safety) != -1;
});

// When a section or field is being added, updated or deleted, in order to keep
// the orders consistent, users are not allowed to
const isTemplateEditorOperating = computed(() => {
  return !!sections.value.find(
    (s) =>
      !s.id ||
      s.isUpdating ||
      s.isDeleting ||
      s.isDuplicating ||
      !!s.template_fields.find((f) => !f.id || f.isUpdating || f.isDeleting)
  );
});

const sectionsDraggableGroup = computed(() => {
  return {
    name: 'sections',
    pull: false,
    put: (to, from) => {
      return (
        from.options.group.name === 'sections' &&
        to.options.group.name === 'sections' &&
        !isTemplateEditorOperating.value
      );
    },
  };
});

watch(firstSectionInPublicForm, async (newValue, oldValue) => {
  if (newValue?.id && newValue.id !== oldValue?.id) {
    newValue.is_shown_on_new_page = false;
    await newValue.save();
  }
});

function updateTemplateSections() {
  emit('updateTemplateSections', sections.value);
}

function updateExpression(expression) {
  if (sectionIndex.value === null || fieldIndex.value === null) {
    throw new Error(
      'updateExpression: sectionIndex and fieldIndex are required'
    );
  }
  updateFieldOptions({
    sectionIndex: sectionIndex.value,
    fieldIndex: fieldIndex.value,
    options: {
      ...sections.value[sectionIndex.value].template_fields[fieldIndex.value]
        .options,
      expression,
    },
  });
}

function addMobileSections() {
  sectionIndex.value = null;
  showFieldModal.value = true;
  drawer.value?.close();
}

function gotoMap() {
  emit('goMap');
}

function save() {
  emit('save');
}

async function createField({ sectionIndex, fieldIndex, value }) {
  const section = sections.value[sectionIndex];

  if (
    value.field_type_id === FieldTypeIds.COPY_DATA_LINK &&
    section.template_fields.some(
      (f) => f.field_type_id === FieldTypeIds.COPY_DATA_LINK
    )
  ) {
    toastStore.error('A section can have only one Copy Data Link field.');
    return;
  }

  for (let i = fieldIndex; i < section.template_fields.length; i++) {
    const field = section.template_fields[i];
    section.setField(i, { ...field, order: i + 1 });
  }

  section.addField(fieldIndex, { ...value, order: fieldIndex });
  if (mobileSize.value) {
    showFieldModal.value = false;
  }
  await section.template_fields[fieldIndex].save();
  updateTemplateSections();
}

async function updateField({ sectionIndex, fieldIndex, value }) {
  const section = sections.value[sectionIndex];
  const originalSection = sections.value.find(
    (s) => s.id === value.template_section_id
  );
  if (!originalSection) {
    throw new Error('updateField: originalSection not found');
  }
  const isSectionChanged = section !== originalSection;
  const field = !isSectionChanged
    ? section.template_fields[fieldIndex]
    : originalSection?.template_fields.find(
        (f) => f.id === value.id && value.id !== null
      );

  if (!field) {
    throw new Error('updateField: field not found');
  }

  if (isSectionChanged && field.is_permanent) {
    toastStore.error(
      `The field '${field.label}' should always be in the '${originalSection.label}' section.`
    );
    return;
  }

  const isWaitingForId = field && !field.id;
  if (isWaitingForId) {
    await new Promise<void>((resolve) => {
      let i = 0;
      const intervalId = setInterval(() => {
        i++;
        if (field.id) {
          clearInterval(intervalId);
          resolve();
        } else if (i > 100) {
          clearInterval(intervalId);
          throw new Error('updateField: Failed to wait for field id');
        }
      }, 100);
    });
  }

  let newField;
  if (!isSectionChanged) {
    const order = value.order ?? fieldIndex;
    if (order !== fieldIndex) {
      section.moveField(fieldIndex, order);
    } else {
      section.setField(order, {
        ...field,
        ..._omit(value, 'id'),
        order,
      });
    }
    newField = section.template_fields[order];
    await newField.save();
    updateTemplateSections();
  } else {
    for (let i = fieldIndex; i < section.template_fields.length; i++) {
      const field = section.template_fields[i];
      section.setField(i, { ...field, order: i + 1 });
    }

    const { order: originalFieldIndex } = field;
    for (
      let i = (originalFieldIndex || 0) + 1;
      i < originalSection.template_fields.length;
      i++
    ) {
      const field = originalSection.template_fields[i];
      originalSection.setField(i, { ...field, order: i - 1 });
    }
    originalSection.deleteField(originalFieldIndex);
    const nextField = {
      ...field,
      ..._omit(value, 'id'),
      order: fieldIndex,
      template_section_id: section.id,
    };
    section.addField(fieldIndex, nextField);
    newField = section.template_fields[fieldIndex];
    await newField.save();
    updateTemplateSections();
  }
  const app = findAppByFieldId(props.tabs, newField.id);
  props.onFieldUpdated(newField, app);
}

function addCondition({ sectionIndex, fieldIndex, condition }) {
  const options = {
    ...sections.value[sectionIndex].template_fields[fieldIndex].options,
  };
  options.conditions = options.conditions
    ? [...options.conditions, condition]
    : [condition];
  updateFieldOptions({ sectionIndex, fieldIndex, options });
}

async function updateFieldOptions(args: {
  sectionIndex: number;
  fieldIndex: number;
  options: any;
}) {
  if (!args.options) {
    console.warn('updateFieldOptions: options is required, skipping');
    return;
  }

  const value = {
    ...sections.value[args.sectionIndex].template_fields[args.fieldIndex],
    options: args.options,
  };
  await updateField({
    sectionIndex: args.sectionIndex,
    fieldIndex: args.fieldIndex,
    value,
  });
}

const updateFieldLabel = _debounce(async function ({
  index,
  fieldIndex,
  label,
}) {
  const value = {
    ...sections.value[index].template_fields[fieldIndex],
    label,
  };
  await updateField({ sectionIndex: index, fieldIndex, value });
},
600);

const updateSection = _debounce(async function (value) {
  // Prefer ID as sectionIndex may change
  // ID may be undefined/null before first save, fallback to selected this.sectionIndex.
  let si = value.id
    ? sections.value.findIndex((s) => s.id === value.id)
    : sectionIndex.value;
  if (si === undefined) {
    si = sectionIndex.value;
  }

  if (si === undefined || si === null) {
    throw new Error('updateSection: sectionIndex cannot be determined');
  }

  let sectionData: any = sections.value[si];

  sectionData = {
    ...sectionData,
    ...value,
    order: si,
    template_tab_id: props.tab.id,
  };
  if (!checkIsInPublicForm(sectionData)) {
    sectionData = { ...sectionData, is_shown_on_new_page: false };
  }
  const section = Section.wrap(sectionData);

  const isWaitingForId = section && !section.id;
  if (isWaitingForId) {
    await new Promise<void>((resolve) => {
      let i = 0;
      const intervalId = setInterval(() => {
        i++;
        if (section.id) {
          clearInterval(intervalId);
          resolve();
        } else if (i > 100) {
          clearInterval(intervalId);
          throw new Error('updateSection: Failed to wait for section id');
        }
      }, 100);
    });
  }

  // Section index may change as a result of await.
  si = sections.value.findIndex((s) => s.id === section.id) || si;
  sections.value.splice(si, 1, section);
  await sections.value[si].save();
  updateTemplateSections();
}, 600);

function handleShowExpressionEditor() {
  showExpressionEditorModal.value = true;
}

function openGatherSupportPage() {
  window.open(DATANEST_URL + '/support/gather', '_blank');
}

function selectSection(index: number) {
  if (props.disabled || sectionIndex.value === index) {
    return;
  }

  sectionIndex.value = index;
}

function selectField(index: number) {
  fieldIndex.value = index;
}

async function removeSection(index: number) {
  try {
    if (index < 0 || index >= sections.value.length) {
      throw new Error('removeSection: Invalid index');
    }
    let section = sections.value[index];

    if (!(section instanceof Section)) {
      section = Section.wrap(section);
    }
    await section.delete();
    sections.value.splice(index, 1);
    sections.value.forEach((item, itemIndex) => {
      item.order = itemIndex;
    });
    updateTemplateSections();
    sectionIndex.value = null;
    fieldIndex.value = null;
  } catch (e) {
    toastStore.unexpected(
      e,
      'Failed to delete section, please refresh and try again'
    );
    throw e;
  }
}

async function duplicateSection(index) {
  const section = sections.value[index];
  const order = sections.value.length;
  const duplicate = await section.duplicate(order);

  sections.value = [...sections.value, duplicate];
  updateTemplateSections();
  selectSection(order);
}

function resizeHandler() {
  mobileSize.value = window.innerWidth < 991;
}

function handleDeleteField({ field, app }) {
  fieldIndex.value = null;
  updateTemplateSections();
  props.onFieldDeleted(field, app);
}

async function handleSectionsChange({ added, moved }) {
  if (added) {
    const { element } = added;

    if (element.is_gps_point_metadata) {
      if (props.tab.drawing_type !== 'point') {
        toastStore.info(
          'The section can be only added to an app with a Point collection type.'
        );
        return;
      }

      if (sections.value.find((item) => item.is_gps_point_metadata)) {
        toastStore.info('The section has already existed in the app.');
        return;
      }
    }

    // When sections is empty, the newIndex is 1 because of the placeholder
    // in the draggable component. It needs to be corrected to 0.
    const newIndex = sections.value.length ? added.newIndex : 0;

    for (let i = newIndex; i < sections.value.length; i++) {
      const section = sections.value[i];
      section.order = i + 1;
    }

    await addSection({
      element,
      index: newIndex,
    });
  } else if (moved) {
    const { element, oldIndex, newIndex } = moved;
    const nextSection = Section.wrap({
      ...element,
      order: newIndex,
    });
    await nextSection.save();
    // Swap positions
    const sectionAtOrder = sections.value[nextSection.order];
    sections.value.splice(nextSection.order, 1, nextSection);
    sections.value.splice(
      oldIndex,
      1,
      Section.wrap({
        ...sectionAtOrder,
        order: oldIndex,
      })
    );
    updateTemplateSections();
  }
}

async function addSection({ element, index }) {
  const section = Section.wrap({
    ...element,
    template_tab_id: props.tab.id,
    order: index,
  });
  await section.save();
  sections.value.splice(index, 0, section);
  fieldIndex.value = null;
  selectSection(index);
  if (mobileSize.value) {
    showFieldModal.value = false;
  }
  updateTemplateSections();
}

function clearSection() {
  sectionIndex.value = null;
}

watch(sectionIndex, (newValue) => {
  if (newValue !== null && props.isTabBeingEdited) {
    emit('finishEditingTab');
  }
});

onBeforeMount(resizeHandler);

onMounted(async () => {
  const access = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR);
  if (!access.hasPermissionToAccess()) {
    toastStore.error('You do not have permission to access this page');
    gotoMap();
    return;
  }

  window.addEventListener('resize', resizeHandler);

  if (props.tab.id) {
    isLoadingSections.value = true;
    sections.value = await Section.getTabSections(props.tab.id, (progress) => {
      loadingProgress.value = progress;
    }).catch((err) => {
      isLoadingSections.value = false;
      throw err;
    });
    isLoadingSections.value = false;
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeHandler);
});
</script>
