<template>
  <div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="form-label">Prefix</label>
          <input
            v-model="prefix"
            type="text"
            class="form-control"
            @keypress.enter="$emit('blur')"
          />
        </div>

        <div class="form-group mt-2">
          <label class="form-label">Starting Value</label>

          <input
            v-model="default_value"
            class="form-control"
            type="number"
            @keypress.enter="$emit('blur')"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label class="form-label">Unit</label>

          <input
            v-model="unit"
            class="form-control"
            list="units"
            @keypress.enter="$emit('blur')"
          />
          <datalist id="units">
            <option value="metre">Metre</option>
            <option value="feet">Feet</option>
            <option value="millimeter">Millimeter</option>
            <option value="centimeter">Centimeter</option>
            <option value="inch">Inch</option>
          </datalist>
        </div>

        <div class="form-group mt-2">
          <label class="form-label">Auto Increment By</label>

          <input
            v-model="increment"
            class="form-control"
            type="number"
            @keypress.enter="$emit('blur')"
          />
        </div>
      </div>
    </div>

    <div class="d-flex align-items-end mt-2">
      <span class="flex-grow-1 mb-0 fw-medium">Tolerance</span>
      <a
        class="btn btn-sm"
        :class="{
          'btn-primary': !toleranceEnabled,
          'btn-outline-secondary': toleranceEnabled,
        }"
        @click="toggleToleranceEnabled"
        >{{ toleranceEnabled ? 'Disable' : 'Enable' }}</a
      >
    </div>
    <div v-if="toleranceEnabled">
      <p v-if="explainerSentence" class="alert alert-info mt-2">
        {{ explainerSentence }}
      </p>
      <p v-else class="alert alert-warning mt-2">
        Enter values below and an explanation will appear here.
      </p>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label class="form-label">Tolerance basis</label>

            <select
              v-model="tolerance_basis"
              class="form-control"
              @keypress.enter="$emit('blur')"
            >
              <option value="of_n">Previous n values</option>
              <option value="of_fixed">Fixed Value</option>
            </select>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label v-if="tolerance_basis == 'of_n'" class="form-label"
              >Number of previous values</label
            >
            <label v-else class="form-label">Tolerance target value</label>

            <input
              v-model="tolerance_of"
              class="form-control"
              type="number"
              @keypress.enter="$emit('blur')"
            />
          </div>
        </div>
      </div>

      <div class="form-group mt-2">
        <label class="form-label">Tolerance direction</label>

        <select
          v-model="tolerance_direction"
          class="form-control"
          @keypress.enter="$emit('blur')"
        >
          <option value="">Above or below +/-</option>
          <option value="above">Above, values below tolerance will fail</option>
          <option value="below">Below, values can not exceed tolerance</option>
        </select>
      </div>

      <div
        v-if="tolerance_direction != 'above' && tolerance_direction != 'below'"
        class="row"
      >
        <div class="col-6">
          <div class="form-group mt-2">
            <label class="form-label">Tolerance</label>
            <input
              v-model="tolerance"
              type="text"
              class="form-control"
              placeholder="None"
              @keypress.enter="$emit('blur')"
            />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mt-2">
            <label class="form-label">Type</label>

            <select
              v-model="tolerance_type"
              class="form-control"
              @keypress.enter="$emit('blur')"
            >
              <option value="fixed">Fixed Amount</option>
              <option value="percentage">Percentage (%)</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { buildSentenceFromField } from '@component-library/utils/tolerance';
export default {
  data: () => ({
    toleranceEnabled: false,
  }),
  props: {
    value: Object,
  },
  emits: ['blur', 'updateField'],
  computed: {
    field: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('updateField', updated);
      },
    },
    options: {
      get() {
        return this.value.options;
      },
      set(updated) {
        this.$emit('updateField', { ...this.value, options: updated });
      },
    },
    prefix: {
      get() {
        return this.value.options?.prefix || null;
      },
      set(updated) {
        const options = { ...this.value.options, prefix: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    default_value: {
      get() {
        return this.value.options?.default_value || null;
      },
      set(updated) {
        const options = { ...this.value.options, default_value: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    unit: {
      get() {
        return this.value.options?.unit || null;
      },
      set(updated) {
        const options = { ...this.value.options, unit: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    increment: {
      get() {
        return this.value.options?.increment || null;
      },
      set(updated) {
        const options = { ...this.value.options, increment: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    tolerance_basis: {
      get() {
        return this.value.options?.tolerance_basis || null;
      },
      set(updated) {
        const options = { ...this.value.options, tolerance_basis: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    tolerance_of: {
      get() {
        return this.value.options?.tolerance_of || null;
      },
      set(updated) {
        const options = { ...this.value.options, tolerance_of: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    tolerance_direction: {
      get() {
        if (this.value.options?.tolerance_direction == null) return '';
        return this.value.options?.tolerance_direction;
      },
      set(updated) {
        updated = updated == '' ? null : updated;
        const options = { ...this.value.options, tolerance_direction: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    tolerance: {
      get() {
        return this.value.options?.tolerance || null;
      },
      set(updated) {
        const options = { ...this.value.options, tolerance: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    tolerance_type: {
      get() {
        return this.value.options?.tolerance_type || null;
      },
      set(updated) {
        const options = { ...this.value.options, tolerance_type: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },

    explainerSentence() {
      return buildSentenceFromField(this.field);
    },
  },
  methods: {
    toggleToleranceEnabled() {
      this.toleranceEnabled = !this.toleranceEnabled;
      if (!this.toleranceEnabled) {
        const options = { ...this.options };
        delete options.tolerance;
        delete options.tolerance_basis;
        delete options.tolerance_direction;
        delete options.tolerance_type;
        delete options.tolerance_of;
        this.options = options;
      }
    },
  },
  mounted() {
    if (
      this.tolerance ||
      this.tolerance_basis ||
      this.tolerance_direction ||
      this.tolerance_type ||
      this.tolerance_of
    ) {
      this.toleranceEnabled = true;
    }
  },
};
</script>
