<script lang="ts" setup>
import Modal from '@component-library/components/Modal.vue';
import useAuth from '@component-library/composables/useAuth';
import { Project } from '@component-library/project';
import { onBeforeUnmount, onMounted, ref } from 'vue';

defineProps<{
  project: Project;
}>();
const auth = useAuth();
const emit = defineEmits(['unlock']);
const acceptInput = ref('');

function submit() {
  if (acceptInput.value.toLowerCase().trim() !== 'i accept') {
    return;
  }

  emit('unlock');
}

function keyup(e) {
  // on ESC key, check if admin or impersonator and close modal
  if (e.key === 'Escape') {
    const authUser = auth.user();
    if (authUser.role === 'admin' || auth.impersonating()) {
      emit('unlock');
    }
  }
}

onMounted(() => {
  document.addEventListener('keyup', keyup);
});
onBeforeUnmount(() => {
  document.removeEventListener('keyup', keyup);
});
</script>

<template>
  <Modal :closable="false" :shouldCloseOnShadeClick="false">
    <template #header> Editor Locked </template>

    <template #default>
      <div class="text-center">
        <h1 class="fad fa-lock"></h1>
        <h6>Editing Is Currently Locked.</h6>
        <p class="text-muted mb-0">
          Data has been gathered in the application, meaning
          <span class="fw-bold"
            >changes to this template could cause data to be lost.</span
          >
        </p>
      </div>
      <div class="mt-3">
        <label for="accept-text" class="form-label text-left"
          >To continue, type <span class="fw-bold">I accept</span></label
        >
        <input
          id="accept-text"
          v-model="acceptInput"
          type="text"
          class="form-control"
          autofocus
          @keyup.enter="submit"
        />
      </div>
    </template>

    <template #footer>
      <button
        type="submit"
        class="btn btn-primary btn-lg w-100"
        :disabled="acceptInput.toLowerCase().trim() !== 'i accept'"
        @click="submit"
      >
        Unlock
      </button>
      <RouterLink
        class="btn btn-outline-secondary w-100 mb-2"
        :to="`/${project.project_id}/map`"
      >
        Go to Collection
      </RouterLink>
    </template>
  </Modal>
</template>
