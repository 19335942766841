<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Status</label>
      <select v-model="status" class="form-control">
        <option value="secondary">Default</option>
        <option value="success">Success</option>
        <option value="warning">Warning</option>
        <option value="danger">Error</option>
      </select>
    </div>

    <div class="form-group mb-3">
      <label class="form-label" for="content">Preview</label>
      <div
        id="caption-content"
        class="alert text-break"
        :class="'alert-' + status"
        v-html="content"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  emits: ['updateField'],
  computed: {
    field: {
      get() {
        return this.value;
      },
      set(updated) {
        this.$emit('updateField', updated);
      },
    },
    options: {
      get() {
        return this.value?.options || {};
      },
      set(updated) {
        this.$emit('updateField', { ...this.value, options: updated });
      },
    },
    status: {
      get() {
        return this.value?.options?.status || 'secondary';
      },
      set(updated) {
        const options = { ...(this.value?.options || {}), status: updated };
        this.$emit('updateField', { ...this.value, options });
      },
    },
    content() {
      return this.value?.options?.content || '';
    },
  },
};
</script>

<style>
img {
  max-width: 100% !important;
}
</style>
