import { Map } from 'ol';
import { getUnit } from '../adapter/project';
import type { Permission } from '../adapter/uac';
import type { CriteriaType, Exceedance, Scenario } from '../evalu8/types';
import type { FigureStylingRule } from '../figure/styling-rule';
import type { Figure, Project } from '../figure/types';
import type { Sample, SampleGroup, SampleScope } from '../layer/sample/types';
import type { Integration } from '../layer/service/integration';
import type { LayerModel } from '../layer/types';
import { LayerType } from '../layer/types';
import { AbsoluteUnit } from '../measurement/types';
import type { Extent, Id, Pid } from './types';
import { App, GatherField } from '@component-library/gather';

export type StoreApi = {
  // Figure related
  getProject: () => Project;
  getSelectedFigure: () => Figure | undefined;
  findFigureStylingRulesByFigureId: (figureId: Id) => FigureStylingRule[];
  // Layer model related
  getLayerModels: () => LayerModel[];
  findLayerModelById: (id: Id) => LayerModel | undefined;
  findLayerModelsByType: (type: LayerType) => LayerModel[];
  getAllSampleGroups: () => SampleGroup[];
  getDefaultSampleGroup: () => SampleGroup;
  getSampleLayerModel: (sample: Sample) => LayerModel | undefined;
  addLayerModel?(figure: Figure, layerModel: LayerModel, afterLayerId?: number);
  removeLayerModel?(id: Id);
  clearTempLayerModels?(): void;
  // Sample related
  loadSamples: (sampleGroupId: Id, extentInWgs84: Extent) => Promise<Sample[]>;
  findSampleById: (id: Id) => Sample | undefined;
  findSampleByIdAsync: (id: Pid) => Promise<Sample | undefined>;
  findPolySampleByLayerModelId: (layerModelId: Id) => Sample | undefined;
  getScopedSamples: (sampleScope: SampleScope) => Sample[];
  addSample(sample: Sample);
  removeSample(id: Id): void;
  // Evalu8 related
  getExceedance: (criteriaType: CriteriaType) => Exceedance;
  findScenarioById: (id: Id) => Scenario | undefined;
  getExceededCriteriaTypes: (
    figure: Figure,
    sample: Sample,
    scenarios: Scenario[],
    chemicalIds: Id[]
  ) => CriteriaType[];
  // App related
  findAppById(id: Pid): App | undefined;
  findFieldById(id: Id): GatherField | undefined;
  getSampleOnlyExport(): boolean;
  getStylableLayerModels(): LayerModel[];
  getIntegrations(): Integration[];
  showError(error: string): void;
  showWarning(warning: string): void;
  checkHasPermission(p: Permission): boolean;
  checkIsRenderableNonSpatialSampleGroup(layerModelId: Id): boolean;
};

export function getStoreApi(map: Map): StoreApi {
  return map.get('storeApi');
}

export function setStoreApi(map: Map, storeApi: StoreApi) {
  map.set('storeApi', storeApi);
}

export function getProjectUnit(map: Map): AbsoluteUnit {
  const storeApi = getStoreApi(map);
  const project = storeApi.getProject();
  return getUnit(project);
}
