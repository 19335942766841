<!-- Capture CTRL+S -->
<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue';

const emit = defineEmits<{
  (e: 'save'): void;
}>();

function handleKeyDown(event: KeyboardEvent) {
  if ((event.ctrlKey || event.metaKey) && event.key === 's') {
    event.preventDefault(); // Prevent the browser's save dialog
    emit('save');
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown);
});
</script>

<template></template>
