<script lang="ts" setup>
import { computed } from 'vue';
import { getResultType } from '../../../business-logic/expression';
import {
  RESULT_TYPE_BOOLEAN,
  RESULT_TYPE_NUMBER,
} from '../../../business-model/expression';
import type { GatherField } from '../../../gather';
import CheckboxTestValueInput from './Checkbox.vue';
import NumberTestValueInput from './Number.vue';
import TextTestValueInput from './Text.vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});
const model = defineModel({ type: Object, required: true });

const resultType = computed(() => {
  return getResultType(props.field as GatherField);
});

const value_ = computed({
  get() {
    switch (resultType.value) {
      case RESULT_TYPE_BOOLEAN:
        return {
          value: JSON.parse(model.value.value) ? 'yes' : 'no',
          value2: model.value.value2,
        };
      default:
        return model.value;
    }
  },
  set(value) {
    switch (resultType.value) {
      case RESULT_TYPE_BOOLEAN:
        model.value = {
          value: JSON.stringify(value.value === 'yes' ? 1 : 0),
          value2: value.value2,
        };
        return;
      default:
        model.value = value;
    }
  },
});
</script>

<template>
  <NumberTestValueInput
    v-if="resultType === RESULT_TYPE_NUMBER"
    v-model="value_"
    :field="field"
  />
  <CheckboxTestValueInput
    v-else-if="resultType === RESULT_TYPE_BOOLEAN"
    v-model="value_"
    :field="field"
  />
  <TextTestValueInput v-else v-model="value_" :field="field" />
</template>
