<script lang="ts" setup>
import { computed } from 'vue';
import { User } from '../user';

const props = defineProps<{
  users: User[];
  showAll?: boolean;
  isClickable?: boolean;
}>();
const emit = defineEmits<{
  (event: 'onClickRemoveButton', user: User): void;
}>();

const getListOfUsers = computed(() => {
  if (props.showAll) {
    return props.users;
  }

  return props.users.slice(0, 3);
});

function getDynamicTitle(user: User) {
  let title = user.name ?? user.email;
  if ([1, 2, 3].includes(user.current_monthly_rank)) {
    title += ` - Top monthly user`;
  }
  return title;
}

function getUserInitials(user: User) {
  const name = user.name ? user.name : user.email;
  const names = name.split(' ');

  let initials = '';
  if (names.length > 0) {
    initials += names[0][0].toUpperCase();
  }
  if (names.length > 1) {
    initials += names[names.length - 1][0].toUpperCase();
  }

  return initials;
}
</script>

<template>
  <div class="d-flex align-items-center user-list">
    <div
      v-for="(user, userIndex) in getListOfUsers"
      :key="userIndex"
      class="user"
      :style="{
        'z-index': users.length - userIndex,
      }"
      :class="{
        clickable: isClickable,
      }"
      @click="emit('onClickRemoveButton', user)"
    >
      <div class="user-outline">
        <div class="user-circle">
          <div
            class="user-short bg-dark"
            :title="getDynamicTitle(user)"
            :style="{
              color:
                user.current_monthly_rank == 1
                  ? 'gold'
                  : user.current_monthly_rank == 2
                  ? 'silver'
                  : user.current_monthly_rank == 3
                  ? '#cd7f32'
                  : 'white',
            }"
          >
            {{ getUserInitials(user) }}
          </div>
        </div>
      </div>
    </div>
    <slot />

    <div v-if="users.length > 3 && !showAll" class="user">
      <div class="user-outline">
        <div class="user-circle">
          <div class="user-short bg-secondary">+{{ users.length - 3 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
