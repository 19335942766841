import { getOfflineMethod, triggerOfflineMethod } from './offline-responses';
import { setInterceptors } from '@component-library/api';
import { useToastStore } from '@component-library/store/toasts';
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { toRaw } from 'vue';

const isOfflineResponse = (error) => Boolean(error.offlineData);

export const doBeforeRequestIsSent = async (config) => {
  if (!navigator.onLine) {
    if (getOfflineMethod(config) != null) {
      return await getOfflineData(config);
    }

    console.error('Could not find offline method for request ' + config.url);
  }

  return config;
};

export const handleRequestError = (error) => {
  return Promise.reject(error);
};

export const doAfterResponseIsReady = (response) => response;

export const handleResponseError = (error) => {
  if (isOfflineResponse(error)) {
    return getOfflineResponse(error);
  }

  if (
    error.response &&
    (error.response.status === 429 || error.response.status === 420)
  ) {
    try {
      useToastStore().error(
        'Too many requests. Please try again in a few minutes.'
      );
    } catch (err) {
      console.error(err);
    }
  }

  return Promise.reject(error);
};

setInterceptors({
  doBeforeRequestIsSent,
  handleRequestError,
  doAfterResponseIsReady,
  handleResponseError,
});

const getOfflineData = async (config) => {
  const offlineResponse = new Error();

  const offlineStorageManager = useOfflineStorageManagerStore();
  if (offlineStorageManager.offlineProjects.length === 0) {
    await offlineStorageManager.loadProjects();
  }

  try {
    console.log('trigger offline API: ' + config.url);
    console.log('params ', config.params);

    offlineResponse.offlineData = await triggerOfflineMethod({
      ...config,
      offlineStorageManager,
    });

    console.log('response offline API', offlineResponse.offlineData);

    offlineResponse.config = config;

    return Promise.reject(offlineResponse);
  } catch (e) {
    console.log('get offline data failed');
    console.error(e);

    return Promise.reject({
      offlineData: {
        status: 500,
        message: e.stack,
      },
    });
  }
};

const getOfflineResponse = (offlineResponse) => {
  const { offlineData, config } = offlineResponse;

  if (offlineData.status && offlineData.status !== 200) {
    const err = new Error(offlineData.message || 'offline error');
    err.code = offlineData.status;

    if (offlineData.message) {
      let offlineErrors = localStorage.getItem('offline_errors');
      offlineErrors = offlineErrors ? JSON.parse(offlineErrors) : [];
      offlineErrors.push(offlineData.message);

      localStorage.setItem('offline_errors', JSON.stringify(offlineErrors));

      console.error(offlineData.message);
    }

    return Promise.reject(err);
  }

  return Promise.resolve({
    data: offlineData,
    status: 200,
    statusText: 'OK',
    headers: {},
    config,
    isOffline: true,
  });
};
