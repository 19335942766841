<script setup lang="ts">
import InputTextFloating from '../InputTextFloating.vue';

withDefaults(
  defineProps<{
    label?: string;
    value?: string;
    placeholder?: string;
    isRequired?: boolean;
  }>(),
  {
    label: 'Title',
    placeholder: 'Type text here...',
    isRequired: true,
  }
);

const emit = defineEmits<{
  (e: 'input', value: string): void;
}>();

function handleInput(value: string) {
  emit('input', value.trim());
}
</script>

<template>
  <div>
    <InputTextFloating
      name="title"
      :label="label"
      :required="isRequired"
      :value="value"
      :placeholder="placeholder"
      @input="handleInput"
    />
  </div>
</template>
