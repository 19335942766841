import {
  checkIsNewStylingRule,
  deleteOneOfStylingRules,
  updateOneOfStylingRules,
} from '@component-library/business-logic/mapping/styling-rule';
import { produce } from 'immer';
import localForage from 'localforage';
import _cloneDeep from 'lodash/cloneDeep';
import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { checkIsAtTop } from '../types/modal-stack';
import { clone } from 'pouchdb-utils';

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  key: 'offline_vuex',
  storage: localForage,
  asyncStorage: true,
  reducer: (state) => {
    // The modalStack is skipped because its items' payload is a function which can not be serialized.
    const { modalStack, ...persistedState } = state;
    return clone(persistedState);
  },
});

const store = createStore({
  plugins: [vuexLocal.plugin],
  state: {
    project: null,
    isOnline: true,

    // The follwing states are used to manage an app's Styling Rules.
    shapeProperties: {},
    tempStylingRules: [],
    activeTempStylingRuleId: undefined,

    persistence: {
      sections: [],
      sectionIndex: null,
      isTracking: false,
      vertexCreationInterval: 20,
      sampleIdentifier: null,
      sampleData: null,
      sampleTabId: null,
      inputValues: [],
      useCamera: false,
      useVideo: false,
      useDrawing: false,
      drawing: null,
      assetFile: null,
      imageCount: 0,
      usePreview: false,
      previewFile: null,
      inputIndex: null,
      projectId: null,
    },
    isScaleVisible: false,
    selectedFigureId: undefined,
    // Used for the item breadcrumbs, e.g. going back and forth between a master item and its linked items.
    modalStack: [],
    hasUnsavedItem: false,
  },
  mutations: {
    UPDATE_PERSISTENCE(state, persistence) {
      state.persistence = { ...state.persistence, ...persistence };
    },
    UPDATE_PROJECT(state, project) {
      state.project = project;
      if (project?.project_id) {
        localStorage.setItem('project_id', project.project_id);
      } else {
        localStorage.removeItem('project_id');
      }
    },
    RESET_PROJECT(state) {
      state.project = null;
      localStorage.removeItem('project_id');
    },
    UPDATE_NETWORK_STATE(state, isOnline) {
      state.isOnline = isOnline;
    },
    SET_SHAPE_PROPERTIES(state, value) {
      state.shapeProperties = value;
    },
    SET_TEMP_STYLING_RULES(state, value) {
      state.tempStylingRules = value;
    },
    SET_ACTIVE_TEMP_STYLING_RULE_ID(state, value) {
      state.activeTempStylingRuleId = value;
    },
    ADD_NEW_STYLING_RULE(state, value) {
      if (!checkIsNewStylingRule(value)) {
        return;
      }

      state.tempStylingRules.push(value);
    },
    UPDATE_ONE_OF_TEMP_STYLING_RULES(state, payload) {
      updateOneOfStylingRules(state.tempStylingRules, payload);
    },
    DELETE_ONE_OF_TEMP_STYLING_RULES(state, id) {
      deleteOneOfStylingRules(state.tempStylingRules, id);
    },
    SET_IS_SCALE_VISIBLE(state, value) {
      state.isScaleVisible = value;
    },
    SET_SELECTED_FIGURE_ID(state, value) {
      state.selectedFigureId = value;
    },
    PUSH_TO_MODAL_STACK(state, modal) {
      state.modalStack = produce([...state.modalStack], (draft) => {
        draft.push(modal);
      });
    },
    POP_FROM_MODAL_STACK(state) {
      state.modalStack = produce([...state.modalStack], (draft) => {
        draft.pop();
      });
    },
    UPDATE_TOP_MODAL_PAYLOAD(state, value) {
      state.modalStack = produce([...state.modalStack], (draft) => {
        const top = draft.pop();
        top.payload = value;
        draft.push(top);
      });
    },
    SET_HAS_UNSAVED_ITEM(state, value) {
      state.hasUnsavedItem = value;
    },
  },
  actions: {
    changeMatrix(context, matrix) {
      context.commit('SET_MATRIX', matrix || 'soil');

      localStorage.matrix = matrix;
    },
    enableMatrix(context, matrix) {
      context.commit('ENABLE_PROJECT_MATRIX', matrix);
    },
    changeMatrixBasedOnProject(context, project) {
      let specifiedProject = project || context.state.project;

      let selectedMatrix = 'soil';

      if (specifiedProject) {
        for (let matrix of globals.matrices) {
          if (specifiedProject[`has_${matrix}_upload`] == true) {
            selectedMatrix = matrix;
            break;
          }
        }
      }

      context.commit('SET_MATRIX', selectedMatrix);
      localStorage.matrix = selectedMatrix;
    },
    updateProject(context, updatedProjectData) {
      if (!updatedProjectData) {
        context.dispatch('resetProject');
        return;
      }

      let project = { ...context.state.project, ...updatedProjectData };
      context.commit('UPDATE_PROJECT', project);
    },
    updatePersistence(context, updated) {
      context.commit('UPDATE_PERSISTENCE', updated);
    },
    resetProject(context) {
      context.commit('RESET_PROJECT');
    },
    loadSpecificProject(context, data) {
      let project_data = data['project'];
      context.dispatch('updateProject', project_data);
    },
    updateNetworkState(context, isOnline) {
      context.commit('UPDATE_NETWORK_STATE', isOnline);
    },
    setShapeProperties(context, value) {
      context.commit('SET_SHAPE_PROPERTIES', value);
    },
    updateShapeProperties(context, update) {
      context.dispatch('setShapeProperties', {
        ...context.state.shapeProperties,
        ...update,
      });
    },
    setTempStylingRules(context, value) {
      context.commit('SET_TEMP_STYLING_RULES', value);
    },
    setActiveTempStylingRuleId(context, value) {
      context.commit('SET_ACTIVE_TEMP_STYLING_RULE_ID', value);
    },
    addNewStylingRule(context, value) {
      context.commit('ADD_NEW_STYLING_RULE', value);
    },
    updateOneOfTempStylingRules(context, payload) {
      context.commit('UPDATE_ONE_OF_TEMP_STYLING_RULES', payload);
    },
    deleteOneOfTempStylingRules(context, id) {
      context.commit('DELETE_ONE_OF_TEMP_STYLING_RULES', id);
    },
    setIsScaleVisible(context, value) {
      context.commit('SET_IS_SCALE_VISIBLE', value);
    },
    setSelectedFigureId(context, value) {
      context.commit('SET_SELECTED_FIGURE_ID', value);
    },
    pushToModalStack(context, modal) {
      const { modalStack } = context.state;
      if (checkIsAtTop(modalStack, modal)) {
        return;
      }

      context.commit('PUSH_TO_MODAL_STACK', modal);
    },
    popFromModalStack(context) {
      const { modalStack } = context.state;
      if (modalStack.length === 0) {
        return;
      }

      context.commit('POP_FROM_MODAL_STACK');
    },
    updateTopModalPayload(context, value) {
      const { modalStack } = context.state;
      if (modalStack.length === 0) {
        return;
      }

      context.commit('UPDATE_TOP_MODAL_PAYLOAD', value);
    },
    setHasUnsavedItem(context, value) {
      context.commit('SET_HAS_UNSAVED_ITEM', value);
    },
  },
  getters: {
    get_project_field_by_key:
      (state) =>
      (key, multiKey = null) => {
        if (!state.project) {
          return null;
        }

        if (multiKey) {
          let first = state.project[key];
          return first ? first[multiKey] : null;
        }

        return state.project[key] || null;
      },
    get_project_data: (state) => state.project,
    get_project_country: (state) => {
      if (!state.project || !state.project['location']) {
        return 'NZ';
      }
      return state.project['location']['country'];
    },
    get_project_timezone: (state) => {
      return state.project?.timezone || null;
    },
    get_date_format_by_country: (state) => {
      if (!state.project || !state.project['location']) {
        return 'DD-MM-YYYY';
      }

      if (state.project['location']['country'] == 'US') {
        return 'YYYY-MM-DD';
      }

      return 'DD-MM-YYYY';
    },
    get_datetime_format_by_country: (state) => {
      if (!state.project || !state.project['location']) {
        return 'DD-MM-YYYY HH:mm:ss';
      }

      if (state.project['location']['country'] == 'US') {
        return 'MM-DD-YYYY HH:mm:ss';
      }

      return 'DD-MM-YYYY HH:mm:ss';
    },
    get_persistence: (state) => {
      return state.persistence;
    },
    isModalStackEmpty: (state) => {
      return state.modalStack.length === 0;
    },
  },
});

export default store;
export const useStore = () => store;
