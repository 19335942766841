<script>
export default {
  name: 'TextOperandInput',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});
const model = defineModel({ type: Object, required: true });

const text = computed({
  get() {
    return model.value.value;
  },
  set(value) {
    model.value = {
      value,
      value2: props.value.value2,
    };
  },
});
</script>

<template>
  <input v-model="text" type="text" class="form-control form-control-sm" />
</template>
