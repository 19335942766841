<script>
export default {
  name: 'YearMonthValueContentInput',
};
</script>

<script setup>
import { computed, onMounted, nextTick } from 'vue';
import moment from 'moment';
import { padValue } from '@component-library/business-logic/expression';

const yearMonth = defineModel({ type: String, required: true });

const year = computed({
  get() {
    return yearMonth.value ? parseInt(yearMonth.value.split('-')[0], 10) : null;
  },
  set(value) {
    yearMonth.value = `${value}-${padValue(month.value, 2)}`;
  },
});

const month = computed({
  get() {
    return yearMonth.value ? parseInt(yearMonth.value.split('-')[1], 10) : null;
  },
  set(value) {
    yearMonth.value = `${year.value}-${padValue(value, 2)}`;
  },
});

const moment_ = new moment();
const getYearRange = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 20; i < currentYear + 19; i++) {
    years.push(i);
  }
  return years;
};

onMounted(async () => {
  const now = new Date();
  if (!year.value) {
    year.value = now.getFullYear();
  }
  await nextTick();
  if (!month.value) {
    month.value = now.getMonth() + 1;
  }
});
</script>

<template>
  <div class="form-group mb-2 d-flex flex-fill">
    <select v-model="month" class="form-control flex-fill">
      <option v-for="n in 12" :key="`month-${n}`" :value="n">
        {{ moment_.month(n - 1).format('MMMM') }}
      </option>
    </select>
    <select v-model="year" class="form-control flex-fill">
      <option v-for="item of getYearRange()" :key="item" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>
