<script>
export default {
  name: 'ExpressionTestValueInput',
};
</script>

<script setup>
import { computed } from 'vue';
import * as cl_bm from '@component-library/business-model';
import { getResultType } from '@component-library/business-logic/expression';
import NumberTestValueInput from './Number.vue';
import CheckboxTestValueInput from './Checkbox.vue';
import TextTestValueInput from './Text.vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['update:modelValue']);

const resultType = computed(() => {
  return getResultType(props.field);
});

const value = computed({
  get() {
    switch (resultType.value) {
      case cl_bm.expression.RESULT_TYPE_BOOLEAN:
        return {
          value: JSON.parse(props.modelValue.value) ? 'yes' : 'no',
          value2: props.modelValue.value2,
        };
      default:
        return props.modelValue;
    }
  },
  set(value) {
    switch (resultType.value) {
      case cl_bm.expression.RESULT_TYPE_BOOLEAN:
        emit('update:modelValue', {
          value: JSON.stringify(value.value === 'yes' ? true : false),
          value2: value.value2,
        });
        return;
      default:
        emit('update:modelValue', value);
    }
  },
});
</script>

<template>
  <NumberTestValueInput
    v-if="resultType === cl_bm.expression.RESULT_TYPE_NUMBER"
    v-model="value"
    :field="field"
  />
  <CheckboxTestValueInput
    v-else-if="resultType === cl_bm.expression.RESULT_TYPE_BOOLEAN"
    v-model="value"
    :field="field"
  />
  <TextTestValueInput v-else v-model="value" :field="field" />
</template>
