<script lang="ts" setup>
import { ref } from 'vue';
import {
  getAppIcon,
  getAppShareGroupTitle,
} from '@component-library/business-logic/app';
import type { App, Shareable } from '@component-library/gather';

const props = defineProps<{
  apps: (Shareable & { app: App })[];
  selectedApps: App[];
  selectedApp: (Shareable & { app: App }) | null;
}>();

const emit = defineEmits(['selectApp']);
const iconErrorIds = ref<number[]>([]);

const hasSelectedApp = (id) => {
  return props.selectedApp && props.selectedApp.id == id;
};

const hasImportedApp = (id) => {
  return (
    props.selectedApps.findIndex(
      (selectedApp) => selectedApp.id == id || selectedApp.cloned_from_id == id
    ) != -1
  );
};

function iconError(app: App) {
  iconErrorIds.value.push(app.id);
}

function showAppIcon(shareable: Shareable) {
  return (
    !iconErrorIds.value.includes(shareable.app!.id) && getAppIcon(shareable)
  );
}
</script>

<template>
  <div
    v-if="apps && apps.length == 0"
    class="d-flex align-items-center justify-content-center flex-column py-4 flex-fill"
  >
    <h2 class="fal fa-exclamation-triangle"></h2>
    <h2 class="fw-bolder">No apps exist for this filter.</h2>
    <h6 class="text-muted mb-3">
      Simply click the "create app" button, or select another filter.
    </h6>
  </div>
  <div v-else class="row">
    <div
      v-for="app in apps"
      :key="app.id"
      class="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3"
      @click="emit('selectApp', app)"
    >
      <div
        class="card h-100 border clickable"
        :class="{
          'border-body': hasSelectedApp(app.id),
          selected: !hasSelectedApp(app.id) && hasImportedApp(app.id),
        }"
      >
        <div class="card-body d-flex flex-column justify-content-between">
          <h6 class="mb-0">{{ getAppShareGroupTitle(app) }}</h6>
          <img
            v-if="showAppIcon(app)"
            :src="getAppIcon(app)"
            class="mt-2"
            @error="iconError(app.app)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  height: 64px;
  width: 64px;
}

.selected {
  opacity: 0.4;
}
</style>
