<script setup>
const props = defineProps({
  apps: Array,
  type: String,
});
const emit = defineEmits('getApps');
</script>
<template>
  <ul class="nav nav-pills nav-fill mb-3">
    <li class="nav-item" @click.prevent="emit('getApps', 'all')">
      <div
        class="nav-link"
        :class="{
          'bg-dark text-white fw-bold': type == 'all',
        }"
      >
        All Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('getApps', 'workflow')">
      <div
        class="nav-link"
        :class="{
          'bg-dark text-white fw-bold': type == 'workflow',
        }"
      >
        Workflow Optimization
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('getApps', 'company')">
      <div
        class="nav-link"
        :class="{
          'bg-dark text-white fw-bold': type == 'company',
        }"
      >
        Company Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('getApps', 'user')">
      <div
        class="nav-link"
        :class="{
          'bg-dark text-white fw-bold': type == 'user',
        }"
      >
        Your Apps
      </div>
    </li>
    <li class="nav-item" @click.prevent="emit('getApps', 'location')">
      <div
        class="nav-link"
        :class="{
          'bg-dark text-white fw-bold': type == 'location',
        }"
      >
        Datanest Apps
      </div>
    </li>
  </ul>
</template>
<style scoped>
.nav-link {
  padding: 1rem;
}
</style>
