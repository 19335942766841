<script setup lang="ts">
import { ref } from 'vue';
import ButtonSelectFile from '../../components/ButtonSelectFile.vue';
import { useToastStore } from '../../store/toasts';

type CompressedFile = {
  name: string;
  blob: Blob | null;
  src?: string;
};

const props = withDefaults(
  defineProps<{
    modelValue: any[];
    captureOnly: boolean;
    hasMultiple: boolean;
    allowMultiple: boolean;
    allowRenaming: boolean;
    hasError: boolean;
  }>(),
  {
    captureOnly: false,
    hasMultiple: false,
    allowMultiple: false,
    allowRenaming: false,
    hasError: false,
  }
);

const emit = defineEmits(['isLoading', 'update:modelValue']);

const toastStore = useToastStore();

const loading = ref(false);
const imageInput = ref<HTMLInputElement | null>(null);

function triggerFileUpload() {
  if (loading.value) {
    return;
  }
  imageInput.value!.click();
}

function handleFileUpload(e) {
  const files = e.target.files;
  if (!files.length) {
    return;
  }
  loading.value = true;
  emit('isLoading', true);
  try {
    const compressedFiles: Promise<CompressedFile>[] = [];
    for (let x = 0; x < files.length; x++) {
      if (!files[x].type.includes('image')) {
        toastStore.error(
          `${files[x].name} file type is not supported for image upload`
        );
        loading.value = false;
        emit('isLoading', false);
        return;
      }
      compressedFiles.push(compressFile(files[x]));
    }
    Promise.all(compressedFiles).then((blobs) => {
      const blobArr = blobs.map((data) => {
        if (!data?.blob) return data;
        return {
          src: URL.createObjectURL(data.blob),
          blob: data.blob,
          name: data.name,
        };
      });
      emit('update:modelValue', [...props.modelValue, ...blobArr]);
      loading.value = false;
      emit('isLoading', false);
    });
  } catch (e) {
    loading.value = false;
    emit('isLoading', false);
    throw e;
  }
}

function compressFile(file): Promise<CompressedFile> {
  return new Promise((resolve) => {
    const maxWidth = 3072;
    const maxHeight = 3072;
    const mimeType = 'image/jpeg';
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = (e) => {
      URL.revokeObjectURL(img.src);
      toastStore.error(
        `${file.name} file type is not supported for image upload`
      );
      loading.value = false;
      emit('isLoading', false);
      throw e;
    };
    img.onload = () => {
      const [newWidth, newHeight] = calculateSize(img, maxWidth, maxHeight);
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      URL.revokeObjectURL(img.src);
      canvas.toBlob(
        (blob) => {
          let { name } = file;
          if (name && !name.endsWith('.jpg')) {
            const parts = name.split('.');
            if (parts.length > 1) {
              parts.pop();
            }
            name = parts.join('.');
            name = name + '.jpg';
          }
          resolve({ name, blob });
        },
        mimeType,
        0.8
      );
    };
  });
}

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}
</script>

<template>
  <div class="w-100">
    <ButtonSelectFile
      :hasError="hasError"
      :isUploading="loading"
      :icon="captureOnly ? 'fa-camera-alt' : 'fa-upload'"
      @triggerUpload="triggerFileUpload"
    >
      {{ captureOnly ? 'Capture' : `Select ${hasMultiple ? 'files' : 'file'}` }}
    </ButtonSelectFile>

    <input
      v-if="!captureOnly"
      ref="imageInput"
      type="file"
      :multiple="allowMultiple"
      accept="image/*"
      class="form-control-file d-none"
      @change="handleFileUpload"
    />
    <input
      v-else
      ref="imageInput"
      type="file"
      accept="image/*"
      class="form-control-file d-none"
      capture="enviroment"
      @change="handleFileUpload"
    />
  </div>
</template>

<style scoped>
.btn-outline-primary {
  width: 5.8em;
  height: 5.8em;
}

.icon {
  display: inline-block;
  background-size: cover;
  position: relative;
  left: -0.9em;
  top: -0.9em;
}

.icon-datanest {
  width: 5em;
  height: 5em;
  mask: url('/images/datanest-logo.svg');
}

.icon:hover,
.icon:focus {
  background: #fff !important;
}
</style>
