<script setup lang="ts">
defineProps<{
  title: string;
  mobileSize: boolean;
}>();

const show = defineModel();

function toggle() {
  show.value = !show.value;
}
</script>

<template>
  <div>
    <div
      class="section-header p-3 d-flex align-items-center justify-content-between bg-light mb-0 clickable"
      @click.prevent="toggle"
    >
      <h6 class="mb-0">{{ title }}</h6>

      <a href="#" class="text-dark p-1">
        <i
          class="fas"
          :class="{
            'fa-chevron-down': !show,
            'fa-chevron-up': show,
          }"
        />
      </a>
    </div>

    <div
      v-show="show"
      class="pt-3"
      :class="{
        'px-3': mobileSize,
      }"
    >
      <slot name="body" />
    </div>
  </div>
</template>
