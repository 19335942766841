<template>
  <div class="table-responsive">
    <table class="table">
      <thead class="position-sticky">
        <tr>
          <th class="bg-light text-dark fw-bold">Subject</th>
          <th class="bg-light text-dark fw-bold">Test Value</th>
          <th class="bg-light text-dark text-center fw-bold">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="field in fields" :key="field.id">
          <td>
            <div class="d-flex align-items-center">
              <FieldIcon
                :icon="Object.keys(getFieldIconById(field.field_type_id))[0]"
              />
              {{ field.label }}
            </div>
          </td>
          <td>
            <component
              :is="getTestValueInput(field)"
              v-bind="{ field }"
              v-model="field.testValue"
            />
          </td>
          <td class="text-center">
            <select
              v-if="isRepeatableField(field)"
              v-model="field.action"
              class="form-control form-control-sm"
            >
              <option
                v-for="item in getFieldActions(field)"
                :key="`fieldAction-${item}`"
                :value="item"
              >
                {{ ACTIONS[item].text }}
              </option>
            </select>
            <button
              class="btn btn-outline-dark btn-sm ms-1"
              @click="handleAdd(field)"
            >
              <i class="fas fa-plus" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getFieldIconById, FieldTypeIds } from '@component-library/fields';
import TextTestValueInput from './test-value-inputs/Text.vue';
import NumberTestValueInput from './test-value-inputs/Number.vue';
import DateTestValueInput from './test-value-inputs/Date.vue';
import DropdownTestValueInput from './test-value-inputs/Dropdown.vue';
import CheckboxTestValueInput from './test-value-inputs/Checkbox.vue';
import ExpressionTestValueInput from './test-value-inputs/Expression.vue';
import DepthTestValueInput from './test-value-inputs/Depth.vue';
import FieldIcon from '../template/FieldIcon.vue';
import {
  ACTIONS,
  INPUT_TYPE_FIELD,
} from '@component-library/business-model/expression';
import { getActions } from '@component-library/business-logic/expression';

export default {
  components: {
    FieldIcon,
    TextTestValueInput,
    NumberTestValueInput,
    DateTestValueInput,
    DropdownTestValueInput,
    CheckboxTestValueInput,
    DepthTestValueInput,
  },
  props: {
    fields: Array,
    sections: Array,
  },
  data: () => ({
    ACTIONS: ACTIONS,
  }),
  methods: {
    getFieldIconById(id) {
      return getFieldIconById(id);
    },
    getTestValueInput(field) {
      switch (field.field_type_id) {
        case FieldTypeIds.TEXT:
        case FieldTypeIds.DUPLICATE:
        case FieldTypeIds.TRIPLICATE:
          return TextTestValueInput;
        case FieldTypeIds.NUMBER:
          return NumberTestValueInput;
        case FieldTypeIds.DATE:
          return DateTestValueInput;
        case FieldTypeIds.DROPDOWN:
        case FieldTypeIds.LITHOLOGY:
          return DropdownTestValueInput;
        case FieldTypeIds.CHECKBOX:
          return CheckboxTestValueInput;
        case FieldTypeIds.EXPRESSION:
          return ExpressionTestValueInput;
        case FieldTypeIds.DEPTH:
          return DepthTestValueInput;
        default:
          return TextTestValueInput;
      }
    },
    getFieldActions(field) {
      return getActions(field);
    },
    handleAdd(field) {
      this.$emit('add', {
        type: INPUT_TYPE_FIELD,
        value: field.id,
      });
    },
    isRepeatableField(field) {
      return this.sections.find((s) => s.id == field.template_section_id)
        ?.is_repeatable;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  thead {
    top: 0px;
    z-index: 3;

    th {
      border-bottom-color: #ebedf2 !important;
    }
  }
}
</style>
