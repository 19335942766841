<template>
  <Teleport to="body">
    <div
      id="videoModal"
      class="modal fade isVideo"
      aria-hidden="true"
      aria-labelledby="videoModalLabel"
      tabindex="-1"
    >
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-body bg-dark">
            <div class="row h-100 align-items-center">
              <div
                class="col h-100 d-flex flex-column justify-content-center align-items-center"
              >
                <div>
                  <div class="d-flex justify-content-end mb-3">
                    <button
                      class="btn btn-danger"
                      data-bs-dismiss="modal"
                      data-bs-target="#videoModal"
                      aria-label="Close"
                      @click.prevent="close"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                  <video
                    id="video"
                    class="mb-3"
                    playsinline
                    width="100%"
                    height="auto"
                    muted
                  >
                    Video stream not available.
                  </video>
                  <div class="d-flex justify-content-center">
                    <div
                      :class="[
                        {
                          'bg-secondary': !mediaRecorder,
                          'bg-danger': !!mediaRecorder,
                        },
                        'rounded-circle dot',
                      ]"
                      @click="processVideo()"
                    >
                      <i class="fas fa-video fa-3x text-white-50 p-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal as BootstrapModal } from 'bootstrap';
import { Teleport } from 'vue';

export default {
  components: {
    Teleport,
  },
  data: () => ({
    width: 1200,
    height: 0,
    streaming: false,
    video: null,
    modal: null,
    stream: null,
    mediaRecorder: null,
    recordedChunks: [],
    isSafari: false,
  }),
  mounted() {
    this.isSafari =
      navigator.userAgent.indexOf('Safari') > -1 &&
      navigator.userAgent.indexOf('Chrome') <= 0;
    this.modal = BootstrapModal.getOrCreateInstance('#videoModal');
    this.modal.show();
    this.video = document.getElementById('video');
    this.requestvideo();
  },
  beforeUnmount() {
    this.stopRecorder();
  },
  methods: {
    stopRecorder() {
      try {
        this.mediaRecorder?.stop();
        this.stream.getTracks().forEach((track) => track.stop());
        this.video.srcObject = null;
      } catch (e) {
        console.warn('media recorder already stopped!', e);
      }
    },
    close() {
      this.stopRecorder();
      this.$emit('close');
    },
    requestvideo() {
      return navigator.mediaDevices
        .getUserMedia({
          video: {
            width: 1280,
            height: 720,
            facingMode: 'environment',
          },
          audio: true,
        })
        .then((stream) => {
          this.stream = stream;
          this.video.srcObject = stream;
          this.video.play();
          this.video.addEventListener(
            'canplay',
            () => {
              if (!this.streaming) {
                this.streaming = true;
              }
            },
            false
          );
        })
        .catch((err) => {
          throw err;
        });
    },
    processVideo() {
      if (!this.mediaRecorder) {
        this.takeVideo();
      } else {
        this.stopRecorder();
      }
    },
    takeVideo() {
      this.recordedChunks = [];
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: this.isSafari ? 'video/mp4' : 'video/webm',
      });
      this.mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          this.recordedChunks.push(e.data);
        }
        const blob = new Blob([...this.recordedChunks], { type: e.data.type });
        this.$emit('setVideo', {
          src: URL.createObjectURL(blob),
          blob,
          type: this.isSafari ? 'mp4' : 'webm',
        });
        this.modal.hide();
        this.$nextTick(() => {
          this.close();
        });
      };
      this.mediaRecorder.start();
    },
  },
};
</script>

<style scoped>
#video {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  pointer-events: none;
  z-index: 10 !important;
}

.dot {
  width: 4.5em;
  height: 4.5em;
  cursor: pointer;
}

.modal-dialog {
  padding: 0;
}

.modal-content {
  max-height: 100%;
}

.modal-content,
.modal-body {
  max-height: none;
}
</style>
