<template>
  <div
    :class="[
      'list-group-item list-group-item-action rounded-0 field d-flex align-items-center justify-content-between',
      {
        active: selected,
        'field-draggable': !isLabelInputFocused && canEdit,
      },
    ]"
    :style="disabled ? '' : 'cursor: grab'"
    data-cy="field-draggable"
    @click.stop="selectField"
  >
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="d-flex a-items-center draggable-field">
        <div class="draggable-layer">
          <div class="draggable-control"></div>
        </div>
        <span class="icon-width">
          <FieldIcon
            v-show="
              modelValue.id && !modelValue.isUpdating && !modelValue.isDeleting
            "
            :icon="Object.keys(getFieldIcon)[0]"
            class="mb-0 h5 fw-light"
          />

          <Spinner
            v-if="
              !modelValue.id || modelValue.isUpdating || modelValue.isDeleting
            "
          />
        </span>

        <InfoButton
          v-if="invalidNameError"
          class="me-2"
          :danger="true"
          backgroundColor="#FF0000"
          :info="invalidNameError"
        />

        <span
          v-show="!isLabelEditable"
          v-tooltip="invalidNameError"
          class="mb-0 field-label"
          :class="{ 'text-danger': invalidNameError }"
        >
          {{ label }}
        </span>

        <input
          v-show="isLabelEditable"
          ref="fieldLabelInput"
          v-model="label"
          v-tooltip="invalidNameError"
          type="text"
          class="border-0 bg-transparent mb-0 w-100"
          :class="{ 'text-danger is-invalid': invalidNameError }"
          placeholder="Name field here"
          @keyup.enter="emit('clearSection')"
          @focus="handleLabelInputFocus"
          @blur="handleLabelInputBlur"
        />
      </div>
      <div class="d-flex align-items-center gap-1">
        <div class="d-flex align-items-center gap-1">
          <span
            v-if="modelValue.id && section.primary_field_id == modelValue.id"
            class="badge bg-primary"
          >
            Is Primary
          </span>
          <span
            v-if="modelValue.id && section.secondary_field_id == modelValue.id"
            class="badge bg-success"
          >
            Is Secondary
          </span>
          <span
            v-if="
              hasPublic &&
              !section.is_public_form &&
              modelValue.options.is_public_form
            "
            class="badge bg-danger"
          >
            <i class="fas fa-globe-asia"></i>
            Included on Public Form
          </span>
          <span v-if="conditionCheck" class="badge bg-info">
            Is Conditional
          </span>
          <span
            v-if="toleranceExplainer"
            class="badge bg-warning"
            :title="toleranceExplainer"
          >
            Tolerance Check</span
          >
          <span class="badge bg-secondary">{{ badge }}</span>
        </div>

        <div v-if="selected && !modelValue.is_permanent && canEdit">
          <div v-if="showDeleteConfirmation" class="btn-group btn-group-sm">
            <button
              class="btn btn-outline-secondary"
              @click="() => (showDeleteConfirmation = false)"
            >
              <i class="fas fa-times" />
            </button>
            <button
              class="btn btn-outline-danger"
              :disabled="isTemplateEditorOperating"
              @click="emit('delete')"
            >
              <i class="fas fa-check" />
            </button>
          </div>
          <button
            v-else
            data-cy="field-remove"
            class="btn btn-outline-danger btn-sm ms-2"
            :disabled="isTemplateEditorOperating"
            @click="() => (showDeleteConfirmation = true)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  getFieldTypeById,
  getFieldIconById,
  FieldTypeIds,
} from '@component-library/fields';
import { App } from '@component-library/gather';
import { buildSentenceFromField } from '@component-library/utils/tolerance';
import FieldIcon from './FieldIcon.vue';
import sectionTemplates from '../../helpers/section-templates';
import InfoButton from '@component-library/components/InfoButton.vue';
import { getFieldReferenceError } from '@component-library/gather';
import Spinner from '@component-library/components/Spinner.vue';
import { computed, onMounted, ref, watch } from 'vue';
import Field from '@component-library/classes/Field';
import Section from '@component-library/classes/Section';

const showDeleteConfirmation = ref(false),
  isLabelInputFocused = ref(false),
  labelValue = ref<string | null>(null),
  fieldLabelInput = ref<HTMLInputElement>();

const props = defineProps<{
  modelValue: Field;
  index: number;
  section: Section;
  tab: App;
  selected?: boolean;
  disabled?: boolean;
  isTemplateEditorOperating: boolean;
}>();

const emit = defineEmits<{
  (event: 'delete'): void;
  (event: 'selectField', payload: number): void;
  (event: 'updateLabel', payload: { index: number; label: string }): void;
  (event: 'clearSection'): void;
}>();

watch(
  () => props.modelValue.label,
  () => {
    if (!isLabelInputFocused.value) {
      labelValue.value = props.modelValue.label;
    }
  }
);

const label = computed({
  get() {
    return labelValue.value;
  },
  set(updated) {
    labelValue.value = updated;
    const label = updated?.trim();
    if (!label || label === '') {
      return;
    }

    emit('updateLabel', { index: props.index, label });
  },
});
const invalidNameError = computed(() => {
  return getFieldReferenceError(props.modelValue as any, props.section as any);
});
const hasPublic = computed(() => {
  return props.tab?.public_link;
});
const conditionCheck = computed(() => {
  return (
    props.modelValue.options?.conditions &&
    props.modelValue.options.conditions.length > 0
  );
});
const canEdit = computed(() => {
  return !props.modelValue.options?.disabled;
});
const badge = computed(() => {
  const fieldType = props.modelValue.field_type_id
    ? getFieldTypeById(props.modelValue.field_type_id)
    : undefined;

  if (fieldType) {
    return fieldType.short_name || fieldType.display_name;
  }

  return 'Unknown';
});
const getFieldIcon = computed(() => {
  return props.modelValue.field_type_id
    ? getFieldIconById(props.modelValue.field_type_id)
    : {};
});
const toleranceExplainer = computed(() => {
  if (props.modelValue.field_type_id != FieldTypeIds.NUMBER) {
    return false;
  }
  return buildSentenceFromField(props.modelValue);
});
const isLabelEditable = computed(() => {
  if (!props.selected || !canEdit.value) {
    return false;
  }

  if (!props.section.is_gps_point_metadata) {
    return true;
  }

  return !sectionTemplates
    .getTemplateById('gps_point_metadata')
    .fields.map((item) => item.system_reference)
    .includes(props.modelValue.system_reference);
});

function selectField() {
  if (props.disabled || !props.modelValue.id) {
    return;
  }
  emit('selectField', props.index);
}

function handleLabelInputFocus() {
  if (canEdit.value) {
    isLabelInputFocused.value = true;
  }
}

function handleLabelInputBlur() {
  isLabelInputFocused.value = false;
}
onMounted(() => {
  labelValue.value = props.modelValue.label;
  if (isLabelEditable.value) {
    if (!fieldLabelInput.value) {
      console.warn('Field label input not found, cannot focus');
      return;
    }
    fieldLabelInput.value.focus();
    fieldLabelInput.value.select();
  }
});
</script>

<style lang="scss" scoped>
.list-group-item {
  height: 46px;
  padding-left: 0px;
}

.list-group-item:hover .draggable-control {
  visibility: visible;
}

.list-group-item.active {
  background: #f9f9f9;
  border-color: rgba(0, 0, 0, 0.125);

  i.h5,
  span.field-label {
    color: #212529;
  }
}

.field-label {
  height: 19px;
  overflow: hidden;
}

.icon-width {
  min-width: 3em;
  max-width: 3em;
}
</style>
