<script setup lang="ts">
import useMapsApi from '@/js/composables/useMapsApi';
import useFigureStore from '@/js/stores/figure';
import useLayerModelStore from '@/js/stores/layer-model';
import useSampleStore from '@/js/stores/sample';
import ArrowStyleEditor from '@component-library/components/mapping/style-editors/ArrowStyleEditor.vue';
import CircleStyleEditor from '@component-library/components/mapping/style-editors/CircleStyleEditor.vue';
import HedgeStyleEditor from '@component-library/components/mapping/style-editors/HedgeStyleEditor.vue';
import OverrideDefaultStylingAlert from '@component-library/components/mapping/style-editors/OverrideDefaultStylingAlert.vue';
import PolygonStyleEditor from '@component-library/components/mapping/style-editors/PolygonStyleEditor.vue';
import PolylineStyleEditor from '@component-library/components/mapping/style-editors/PolylineStyleEditor.vue';
import RectangleStyleEditor from '@component-library/components/mapping/style-editors/RectangleStyleEditor.vue';
import { provideContext } from '@component-library/components/mapping/style-editors/context';
import { useToastStore } from '@component-library/store/toasts';
import { APP_GATHER } from '@component-library/widgets/marker-picker/constants';
import type { Id } from '@maps/lib/olbm/common/types';
import type { ShapeProperties } from '@maps/lib/olbm/layer/shape/types';
import type { LayerModel, Properties } from '@maps/lib/olbm/layer/types';
import { LayerType } from '@maps/lib/olbm/layer/types';
import {
  getLayerTitle,
  getPropertiesByLayerModelId,
} from '@maps/lib/olbm/layer/utils';
import { StylingPriority } from '@maps/lib/olbm/style/types';
import {
  CHANGE_TO_APPBASED_STYLING,
  CHANGE_TO_CUSTOM_STYLING,
  CHANGE_TO_RULEBASED_STYLING,
  getDefaultStylingPriority,
  getStylingName,
} from '@maps/lib/olbm/style/utils';
import { Offcanvas } from 'bootstrap';
import { produce } from 'immer';
import type { Ref } from 'vue';
import { computed, inject, onMounted, ref, watch } from 'vue';
import Map from './Map.vue';
import makeId from '@component-library/local-id.mjs';
import InputTextFloating from '@component-library/components/InputTextFloating.vue';
import InputSelectFloating from '@component-library/components/InputSelectFloating.vue';

type Option = {
  id: Id;
  text: string;
};

const map: Ref<typeof Map> = inject('map')!;
const { savePlainShape } = useMapsApi();
const toastStore = useToastStore();
const layerModelStore = useLayerModelStore();
const { findLayerModelById, getStylableLayerModels, updateLayerModel } =
  layerModelStore;
const figureStore = useFigureStore();
const sampleStore = useSampleStore();
const { findPolySampleByLayerModelId } = sampleStore;

const layerStylingOffcanvasEl = ref<HTMLDivElement | undefined>(undefined);
const isVisible = ref(false);
const selectedLayerModelId = ref<Id | undefined>(undefined);
// used for newly created layer styling
const isForNewLayer = ref(false);
// used for layer style restoration.
const propertiesByLayerModelId = ref<Record<Id, ShapeProperties>>({});
const layerSelectId = ref(makeId());
const layerStylingOffcanvasLabelId = ref(makeId());
const titleInputId = ref(makeId());

function findVisibleLayerModelById(id: Id): LayerModel | undefined {
  const layerModel = findLayerModelById(id);
  return layerModel?.visible ? layerModel : undefined;
}

const title = computed({
  get() {
    return (
      (selectedLayerModel.value && getLayerTitle(selectedLayerModel.value)) ??
      ''
    );
  },
  set(value) {
    selectedLayerModelId.value &&
      _updateShapeProperties(selectedLayerModelId.value, { title: value });
  },
});
const hasApp = computed(() => {
  return selectedLayerModel.value
    ? !!findPolySampleByLayerModelId(selectedLayerModel.value.id)
    : false;
});
const hasFsrsOnLayer = computed(() => {
  if (!selectedLayerModel.value) {
    return false;
  }

  const fsrsOnLayer =
    map.value?.getFigureStylingRulesOnLayer(selectedLayerModel.value.id) ?? [];
  return fsrsOnLayer.length > 0;
});
const stylingPriority = computed(() => {
  return context.getShapeProperty(
    'stylingPriority',
    getDefaultStylingPriority(hasFsrsOnLayer.value)
  );
});

const reverts = computed(() => {
  const revertToAppbasedStyling = {
    label: CHANGE_TO_APPBASED_STYLING,
    action: async () => {
      await context.replaceShapeProperties({
        ...selectedLayerModel.value!.geojson.properties,
        stylingPriority: StylingPriority.Appbased,
      });
    },
  };
  const revertToRulebasedStyling = {
    label: CHANGE_TO_RULEBASED_STYLING,
    action: async () => {
      await context.replaceShapeProperties({
        ...selectedLayerModel.value!.geojson.properties,
        stylingPriority: StylingPriority.Rulebased,
      });
    },
  };
  const revertToCustomStyling = {
    label: CHANGE_TO_CUSTOM_STYLING,
    action: async () => {
      await context.replaceShapeProperties({
        ...selectedLayerModel.value!.geojson.properties,
        stylingPriority: StylingPriority.Custom,
      });
    },
  };

  if (stylingPriority.value === StylingPriority.Appbased) {
    const result: any[] = [revertToCustomStyling];
    if (hasFsrsOnLayer.value) {
      result.unshift(revertToRulebasedStyling);
    }
    return result;
  } else if (stylingPriority.value === StylingPriority.Rulebased) {
    const result: any[] = [revertToCustomStyling];
    if (hasApp.value) {
      result.unshift(revertToAppbasedStyling);
    }
    return result;
  } else if (stylingPriority.value === StylingPriority.Custom) {
    const result: any[] = [];
    if (hasApp.value) {
      result.push(revertToAppbasedStyling);
    }
    if (hasFsrsOnLayer.value) {
      result.push(revertToRulebasedStyling);
    }
    return result;
  }

  return [];
});

const selectedLayerModel = computed(() => {
  return selectedLayerModelId.value
    ? findVisibleLayerModelById(selectedLayerModelId.value)
    : undefined;
});

const options = computed(() => {
  let result: Option[] = [];

  if (isForNewLayer.value) {
    if (!selectedLayerModel.value) {
      throw `Invalid state: the selected layer model is null.`;
    }

    result = [
      {
        id: selectedLayerModel.value.id,
        text: getLayerTitle(selectedLayerModel.value),
      },
    ];
  } else {
    result = getStylableLayerModels()
      .filter((layerModel) => layerModel.visible)
      .map((layerModel) => {
        return {
          id: layerModel.id,
          text: getLayerTitle(layerModel),
        };
      });
    result.sort((option1, option2) => option1.text.localeCompare(option2.text));
  }

  return result;
});

watch(
  options,
  (newValue) => {
    selectedLayerModelId.value =
      (selectedLayerModelId.value &&
        findVisibleLayerModelById(selectedLayerModelId.value)?.id) ??
      (newValue.length ? newValue[0].id : undefined);
  },
  {
    immediate: true,
  }
);

watch(
  selectedLayerModelId,
  (newValue, oldValue) => {
    // Skip temp layer because the type of its id is string.
    if (!isForNewLayer.value && oldValue && typeof oldValue === 'number') {
      // This happens when a layer is deleted.
      const layerModel = findVisibleLayerModelById(oldValue);
      if (!layerModel) {
        return;
      }

      restoreStyle(oldValue);
    }

    if (isVisible.value && newValue) {
      centerLayer(newValue);
    }
  },
  {
    immediate: true,
  }
);

watch(isVisible, (newValue) => {
  if (newValue && selectedLayerModelId.value) {
    updatePropertiesByLayerModelId(isForNewLayer.value);
    centerLayer(selectedLayerModelId.value);
  }
});

function centerLayer(layerModelId: Id) {
  const layerManager = map.value.getLayerManager();
  const layer = layerManager.findLayerByModelId(layerModelId);
  if (layer) {
    const feature = layer.getFirstFeature();
    const geom = feature.getGeometry();
    const { height: occupiedBottomHeight } =
      layerStylingOffcanvasEl.value!.getBoundingClientRect();
    map.value.centerGeometry(geom, occupiedBottomHeight);
  } else {
    const layerModel = findLayerModelById(layerModelId);
    if (!layerModel) {
      throw `The layer model was not found: layer model id is ${layerModelId}`;
    }
    toastStore.info(`The layer ${getLayerTitle(layerModel)} is hidden.`);
  }
}

async function _updateShapeProperties(
  layerModelId: Id,
  update: object,
  replace: boolean = false
) {
  const layerModel = findLayerModelById(layerModelId);
  if (!layerModel) {
    throw `Invalid state: there isn't a layer model with id ${layerModelId}.`;
  }

  const geojson = produce(layerModel.geojson, (draft) => {
    if (!replace) {
      for (const prop in update) {
        draft.properties[prop] = update[prop];
      }
    } else {
      draft.properties = update as Properties;
    }
  });
  await updateLayerModel(layerModelId, { geojson });

  return geojson.properties;
}

function updatePropertiesByLayerModelId(isForNewLayer) {
  if (isForNewLayer) {
    if (!selectedLayerModel.value) {
      throw `Invalid state: the selected layer model is null.`;
    }

    propertiesByLayerModelId.value = {
      [selectedLayerModel.value.id]:
        selectedLayerModel.value.geojson.properties,
    };
  } else {
    propertiesByLayerModelId.value = getPropertiesByLayerModelId(
      layerModelStore.layerModels
    );
  }
}

function restoreStyle(layerModelId: Id) {
  _updateShapeProperties(
    layerModelId,
    propertiesByLayerModelId.value[layerModelId],
    true
  );
}

function show(newLayerModelId: number | undefined) {
  if (!layerStylingOffcanvasEl.value) {
    throw 'The layer styling offcanvas is not rendered';
  }

  if (newLayerModelId) {
    isForNewLayer.value = true;
    selectedLayerModelId.value = newLayerModelId;
  }

  const layerStylingOffcanvas = Offcanvas.getOrCreateInstance(
    layerStylingOffcanvasEl.value
  );
  layerStylingOffcanvas.show();
}

function hide() {
  if (!layerStylingOffcanvasEl.value) {
    throw 'The layer styling offcanvas is not rendered';
  }

  isForNewLayer.value = false;

  const layerStylingOffcanvas = Offcanvas.getOrCreateInstance(
    layerStylingOffcanvasEl.value
  );
  layerStylingOffcanvas.hide();
}

const context = {
  getShapeProperty(name, defaultValue) {
    return selectedLayerModel.value!.geojson.properties[name] ?? defaultValue;
  },
  async setShapeProperty(name: string, value: any) {
    return await _updateShapeProperties(selectedLayerModel.value!.id, {
      [name]: value,
      stylingPriority: StylingPriority.Custom,
    });
  },
  async updateShapeProperties(update) {
    return await _updateShapeProperties(selectedLayerModel.value!.id, {
      ...update,
      stylingPriority: StylingPriority.Custom,
    });
  },
  async replaceShapeProperties(value) {
    await _updateShapeProperties(selectedLayerModel.value!.id, value, true);
  },
  checkIsStylingTypeVisible(stylingType: string) {
    return true;
  },
  checkIsRenderableNonSpatialSampleGroup() {
    return false;
  },
  getMarkerPickerAppId() {
    return APP_GATHER;
  },
};
provideContext(context);

function handleCancel() {
  if (selectedLayerModelId.value) {
    restoreStyle(selectedLayerModelId.value);
  }

  hide();
}

async function handleUpdate() {
  hide();

  if (!figureStore.selectedFigureId) {
    throw `Invalid state: the selected figure id is null.`;
  }

  if (!selectedLayerModel.value) {
    throw `Invalid state: the selected layer model is null.`;
  }

  if (typeof selectedLayerModel.value.id !== 'number') {
    throw `Invalid state: the id of the selected layer model is not a number.`;
  }

  const layerModel = await savePlainShape(
    figureStore.selectedFigureId,
    selectedLayerModel.value.id,
    selectedLayerModel.value.geojson
  );
  map.value.updateLayerModel(selectedLayerModel.value.id, layerModel);
  updatePropertiesByLayerModelId(isForNewLayer.value);
}

onMounted(() => {
  layerStylingOffcanvasEl.value!.addEventListener('show.bs.offcanvas', () => {
    isVisible.value = true;
  });
  layerStylingOffcanvasEl.value!.addEventListener('hide.bs.offcanvas', () => {
    isVisible.value = false;
  });
});

defineExpose({ show });
</script>

<template>
  <div
    id="layerStylingOffcanvas"
    ref="layerStylingOffcanvasEl"
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    style="--bs-offcanvas-height: calc(100vh / 2 - 56px)"
    data-bs-backdrop="false"
    :aria-labelledby="layerStylingOffcanvasLabelId"
  >
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between"
    >
      <h6 :id="layerStylingOffcanvasLabelId" class="offcanvas-title mb-0">
        {{ `${isForNewLayer ? 'New ' : ''}Layer Styling` }}
      </h6>
      <div class="d-flex align-items-center">
        <button
          v-if="selectedLayerModel"
          class="btn btn-outline-primary btn-sm me-3"
          @click="handleUpdate"
        >
          Update
        </button>
        <button
          type="button"
          class="btn-close text-reset"
          @click="handleCancel"
        />
      </div>
    </div>

    <hr class="m-0" />

    <div class="offcanvas-body">
      <form v-if="selectedLayerModel">
        <InputSelectFloating
          v-if="!isForNewLayer"
          v-model="selectedLayerModelId"
          :name="layerSelectId"
          label="Layer"
          class="mb-2"
        >
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.text }}
          </option>
        </InputSelectFloating>

        <InputTextFloating
          v-model.trim="title"
          :name="titleInputId"
          label="Title"
          type="text"
          class="mb-2"
        />

        <OverrideDefaultStylingAlert
          v-if="reverts.length > 0"
          :stylingName="getStylingName(stylingPriority)"
          :reverts="reverts"
          class="mb-2"
        />

        <RectangleStyleEditor
          v-if="
            selectedLayerModel?.geojson.properties.type === LayerType.RECTANGLE
          "
        />
        <CircleStyleEditor
          v-if="
            selectedLayerModel?.geojson.properties.type === LayerType.CIRCLE
          "
        />
        <PolygonStyleEditor
          v-if="
            selectedLayerModel?.geojson.properties.type === LayerType.POLYGON
          "
        />
        <PolylineStyleEditor
          v-if="
            selectedLayerModel?.geojson.properties.type === LayerType.POLYLINE
          "
        />
        <ArrowStyleEditor
          v-if="selectedLayerModel?.geojson.properties.type === LayerType.ARROW"
        />
        <HedgeStyleEditor
          v-if="selectedLayerModel?.geojson.properties.type === LayerType.HEDGE"
        />
      </form>
      <div v-else>No visible area and line layers found.</div>
    </div>
  </div>
</template>
