<script>
export default {
  name: 'TextValueContentInput',
};
</script>

<script setup>
import { onMounted } from 'vue';

const text = defineModel({ type: String, required: true });

onMounted(() => {
  if (!text.value) {
    text.value = '';
  }
});
</script>

<template>
  <input v-model="text" type="text" class="form-control form-control-sm" />
</template>
