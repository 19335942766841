<script lang="ts" setup>
import Modal from '../components/Modal.vue';
import AppGrid from './AppGrid.vue';

const emit = defineEmits<{
  (event: 'close'): void;
}>();
</script>

<template>
  <Modal @close="emit('close')">
    <template #header> Where to next? </template>
    <AppGrid @closeDropdown="emit('close')" />
  </Modal>
</template>
