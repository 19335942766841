<template>
  <Modal @close="close">
    <template #header>
      <ul class="nav nav-pills nav-fill me-3">
        <li class="nav-item">
          <a
            href="#"
            :class="[
              'nav-link',
              {
                active: isPointsTabActive,
                'bg-primary': isPointsTabActive,
                'text-white': isPointsTabActive,
              },
            ]"
            @click.prevent="() => (currentTab = 'points')"
          >
            Manage Items
          </a>
        </li>
        <li class="nav-item me-0">
          <a
            href="#"
            :class="[
              'nav-link',
              {
                active: isLayersTabActive,
                'bg-primary': isLayersTabActive,
                'text-white': isLayersTabActive,
              },
            ]"
            @click.prevent="() => (currentTab = 'layers')"
          >
            Manage Layers
          </a>
        </li>
      </ul>
    </template>
    <template #default>
      <ManageItemTab
        v-if="isPointsTabActive"
        :templateTabs="apps"
        :editSample="editSample"
        :goToSample="goToSample"
        :isViewOnly="!!gatherPermission.isViewOnly()"
        :openSampleVersionControl="openSampleVersionControl"
      />
      <ManageLayerTab
        v-if="isLayersTabActive"
        :apps="apps"
        :setTab="setTab"
        @hiddenSubFoldersChange="handleHiddenSubFoldersChange"
      />
    </template>
    <template #footer>
      <AlertBox v-if="gatherPermission.isViewOnly()">
        You do not have permission to edit items. Contact your project manager
        to request access.
      </AlertBox>
      <button
        v-else-if="currentTab === 'points'"
        class="btn btn-outline-primary w-100"
        @click="
          EventBus.$emit('openAddPopup');
          close();
        "
      >
        <i class="fas fa-plus fa-fw"></i>
        New Item
      </button>
      <button class="btn w-100" @click="$emit('close')">Close</button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from '@component-library/components/Modal.vue';
import useLayerModelStore from '@/js/stores/layer-model';
import useSampleStore from '@/js/stores/sample';
import { App, isItemNonSpatial, Item } from '@component-library/gather';
import { computed, inject, ref } from 'vue';
import { StackableModalType } from '../../../../types/modal-stack';
import ManageItemTab from './ManageItemTab.vue';
import ManageLayerTab from './ManageLayerTab.vue';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import AlertBox from '@component-library/components/AlertBox.vue';
import EventBus from '@component-library/EventBus';

defineProps<{
  apps: App[];
  items: Item[];
}>();

const map = inject('map') as any;
const currentTab = ref('points');
const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'showVersionControlModal'): void;
}>();

const gatherPermission = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER);
const sampleStore = useSampleStore();
const layerModelStore = useLayerModelStore();
const legacyStore = useLegacyStore();

const isLayersTabActive = computed(() => {
  return currentTab.value === 'layers';
});

const isPointsTabActive = computed(() => {
  return currentTab.value === 'points';
});
function close() {
  emit('close');
}
function setTab(tab: string) {
  currentTab.value = tab;
}

function editSample(sample) {
  if (isItemNonSpatial(sample)) {
    sampleStore.addSample(sample);
  }

  legacyStore.dispatch('pushToModalStack', {
    type: StackableModalType.SampleModal,
    payload: sample.id,
  });
}
function goToSample(sample) {
  EventBus.$emit('goToSampleOnMap', sample);
  close();
}
function openSampleVersionControl(sample) {
  EventBus.$emit('setSampleDataToModify', sample);
  emit('showVersionControlModal');
}
function handleHiddenSubFoldersChange({ sampleGroupId, subFolder }) {
  const sampleGroup = layerModelStore.findLayerModelById(sampleGroupId);
  const isVisible = sampleGroup?.hidden_sub_folders?.indexOf(subFolder) === -1;
  const samples = sampleStore.getScopedSamples({
    sampleGroupId,
    name: subFolder,
  });
  const layerManager = map.getLayerManager();
  const sampleLayer = layerManager.findLayerByModelId(sampleGroupId);
  samples.forEach((sample) => {
    if (isVisible) {
      layerManager.addSampleFeature(sampleLayer, sample, false);
    } else {
      layerManager.removeSampleFeature(sampleLayer, sample.id);
    }
  });
}
</script>
