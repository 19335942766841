<template>
  <div>
    <div class="form-group mb-3">
      <label class="form-label">Unit</label>
      <input
        class="form-control"
        :value="field.options.unit"
        :list="depthUnitsId"
        @keypress.enter="$emit('blur')"
        @input="
          $emit('update', {
            key: 'unit',
            value: $event.target.value,
          })
        "
      />
      <datalist :id="depthUnitsId">
        <option value="m">Metre</option>
        <option value="mm">Millimetre</option>
        <option value="cm">Centimetre</option>
        <option value="ft">Feet</option>
        <option value="in">Inch</option>
        <option value="metre">Metre</option>
        <option value="millimeter">Millimeter</option>
        <option value="centimeter">Centimeter</option>
        <option value="feet">Feet</option>
        <option value="inch">Inch</option>
      </datalist>
    </div>

    <div class="form-check">
      <input
        id="is_range"
        class="form-check-input"
        type="checkbox"
        :checked="field.options.is_range"
        @keypress.enter="$emit('blur')"
        @input="
          $emit('update', {
            key: 'is_range',
            value: $event.target.checked,
          })
        "
      />
      <label class="form-check-label" for="is_range">
        Is the depth a range?
      </label>
    </div>

    <template v-if="field.options.is_range">
      <div class="form-group mb-2">
        <label class="form-label">Range depth interval</label>
        <input
          v-model="rangeInterval"
          type="number"
          class="form-control"
          @keypress.enter="handleRangeIntervalClick"
        />
      </div>

      <div v-if="section.is_repeatable" class="form-check">
        <input
          :id="shouldKeepContinuityId"
          class="form-check-input"
          type="checkbox"
          :checked="field.options.should_keep_continuity"
          @keypress.enter="$emit('blur')"
          @input="
            $emit('update', {
              key: 'should_keep_continuity',
              value: $event.target.checked,
            })
          "
        />
        <label class="form-check-label me-2" :for="shouldKeepContinuityId">
          Keep continuity
        </label>
        <InfoButton
          :info="`When adding a section during data collection, the <b>Start Depth</b> of this field
          in the new section is the <b>End Depth</b> of this field in the previous section.`"
        />
      </div>
    </template>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import InfoButton from '@component-library/components/InfoButton.vue';
import makeId from '@component-library/local-id.mjs';

export default {
  components: {
    InfoButton,
  },
  props: {
    field: Object,
    section: Object,
  },
  emits: ['blur', 'update'],
  data: () => ({
    depthUnitsId: makeId(),
    shouldKeepContinuityId: makeId(),
  }),
  computed: {
    ...mapState({
      project: 'project',
    }),
    defaultUnit() {
      if (this.project && this.project.location.country == 'US') {
        return 'ft';
      }

      return 'm';
    },
    rangeInterval: {
      get() {
        return this.field.options?.range_interval || null;
      },
      set(value) {
        this.setRangeIntervalDebounced(value);
      },
    },
  },
  methods: {
    handleRangeIntervalClick() {
      if (this.rangeInterval <= 0) {
        return;
      }

      this.$emit('blur');
    },
    setRangeInterval(value) {
      const rangeInterval = parseFloat(value);
      if (rangeInterval !== null && rangeInterval <= 0) {
        this.$toastStore.error(
          'The range depth interval must be greater than 0.'
        );
        return;
      }

      this.$emit('update', {
        key: 'range_interval',
        value: value,
      });
    },
  },
  created() {
    this.setRangeIntervalDebounced = _debounce(this.setRangeInterval, 500);
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.field.options) {
        this.field.options = {};
      }
      if (!this.field.options.unit || this.field.options.unit == '') {
        this.field.options.unit = this.defaultUnit;

        this.$emit('update', {
          key: 'unit',
          value: this.defaultUnit,
        });
      }
    });
  },
};
</script>
