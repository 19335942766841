<script setup lang="ts">
import useStylingRules from '@/js/composables/useStylingRules';
import { useStore } from '@/js/store';
import type {
  ConditionOnGatherApp as Condition,
  StylingRuleDataOnGatherApp as StylingRuleData,
  GatherAppTarget as Target,
} from '@component-library/business-logic/mapping/styling-rule';
import { getShapeProperty } from '@component-library/business-logic/mapping/styling-rule';
import type { App } from '@component-library/gather';
import { DrawingType } from '@component-library/gather';
import HintCard from '@component-library/components/mapping/HintCard.vue';
import SingleLineTextEditor from '@component-library/components/mapping/SingleLineTextEditor.vue';
import PolygonStyleEditor from '@component-library/components/mapping/style-editors/PolygonStyleEditor.vue';
import PolylineStyleEditor from '@component-library/components/mapping/style-editors/PolylineStyleEditor.vue';
import SampleStyleEditor from '@component-library/components/mapping/style-editors/SampleStyleEditor.vue';
import type { ShapeProperties } from '@component-library/components/mapping/style-editors/context';
import { provideContext } from '@component-library/components/mapping/style-editors/context';
import { APP_GATHER } from '@component-library/widgets/marker-picker/constants';
import _cloneDeep from 'lodash/cloneDeep';
import { computed, ref, watch } from 'vue';
import TargetAndConditionEditor from './TargetAndConditionEditor.vue';

const props = defineProps<{ app: App }>();
const emit = defineEmits(['change']);
const store = useStore();

async function updateShapeProperties(update: object): Promise<ShapeProperties> {
  await store.dispatch('updateShapeProperties', update);
  return _cloneDeep(store.state.shapeProperties);
}

async function replaceShapeProperties(value: object): Promise<void> {
  await store.dispatch('setShapeProperties', value);
}

provideContext({
  getShapeProperty(name: string, defaultValue?: any): any {
    return getShapeProperty(store.state.shapeProperties, name, defaultValue);
  },
  setShapeProperty(name: string, value: any): Promise<ShapeProperties> {
    return updateShapeProperties({ [name]: value });
  },
  updateShapeProperties,
  replaceShapeProperties,
  checkIsStylingTypeVisible(stylingType: string): boolean {
    if (stylingType === 'hideMarkerLabel') {
      return true;
    } else if (
      [
        'hideDuplicateLabel',
        'markerLabelColor',
        'markerLabelShadowColor',
        'labelSize',
        'markerLabelUnderlined',
        'markerLabelAsteriskAppended',
      ].includes(stylingType)
    ) {
      return !getShapeProperty(
        store.state.shapeProperties,
        'hideMarkerLabel',
        false
      );
    }

    return false;
  },
  checkIsRenderableNonSpatialSampleGroup() {
    return false;
  },
  getMarkerPickerAppId(): number {
    return APP_GATHER;
  },
});

const {
  activeTempStylingRule,
  getConditionDescription,
  createStylingRuleStyle,
} = useStylingRules(props.app);

const name = ref<string | undefined>(activeTempStylingRule.value?.name);

const targetAndCondition = ref<
  | {
      target: Target;
      condition: Condition;
    }
  | undefined
>(undefined);

const conditionDescription = computed(() => {
  return getConditionDescription(targetAndCondition.value?.condition);
});

const isSampleStyleEditorVisible = computed(
  () => targetAndCondition.value?.target.collectionType === DrawingType.Point
);

const isPolygonStyleEditorVisible = computed(
  () => targetAndCondition.value?.target.collectionType === DrawingType.Polygon
);

const isPolylineStyleEditorVisible = computed(
  () => targetAndCondition.value?.target.collectionType === DrawingType.Polyline
);

const data = computed<StylingRuleData | undefined>(() => {
  if (!name.value || !targetAndCondition.value) {
    return undefined;
  }

  return {
    name: name.value,
    target: targetAndCondition.value.target,
    condition: targetAndCondition.value.condition,
    style: createStylingRuleStyle(
      targetAndCondition.value?.target.collectionType
    ),
  };
});
watch(
  data,
  (newValue) => {
    emit('change', newValue);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div class="d-flex styling-rule-editor">
    <div class="flex-grow-1 left-pane">
      <SingleLineTextEditor
        class="mb-3"
        label="Name"
        :value="name"
        @input="(value) => (name = value)"
      />

      <TargetAndConditionEditor
        :app="app"
        @targetAndConditionChange="(value) => (targetAndCondition = value)"
      />
    </div>

    <div class="flex-grow-1 ps-2 right-pane">
      <div class="mb-2">
        <HintCard :hint="conditionDescription.content" />
      </div>
      <div>
        <SampleStyleEditor v-if="isSampleStyleEditorVisible" />
        <PolygonStyleEditor v-if="isPolygonStyleEditorVisible" />
        <PolylineStyleEditor v-if="isPolylineStyleEditorVisible" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.styling-rule-editor {
  .left-pane,
  .right-pane {
    width: 50%;
  }
}
</style>
