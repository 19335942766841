<script lang="ts" setup>
import AppCard from './AppCard.vue';
import { goToApp as _goToApp } from './handover';
import { AVAILABLE_PERMISSIONS } from '../company-role-profile';
import useViewRestriction from '../composables/useViewRestriction';
import { computed, onBeforeMount } from 'vue';
import { useProjectStore } from '../store/project';
import { captureMessage } from '@sentry/browser';

const { hasPermissionToAccess } = useViewRestriction();
defineProps<{
  hideProjectNavigation?: boolean;
}>();

const emit = defineEmits(['closeDropdown', 'goToApp']);

const projectStore = useProjectStore();
const project = computed(() => projectStore.currentProject);

onBeforeMount(() => {
  if (!projectStore.currentProject) {
    captureMessage('AppGrid: No project selected');
    emit('closeDropdown');
  }
});
</script>

<template>
  <div style="display: flex; flex-direction: column">
    <div v-if="!hideProjectNavigation" class="row">
      <AppCard
        app="hub"
        route="project_create"
        title="Create Project"
        colour="#343a40"
        icon="fal fa-plus"
        :class="{
          'col-6 pe-1 pr-1': project,
          'col-12 mb-2': !project,
        }"
      />

      <AppCard
        app="hub"
        route="project_pathway"
        title="All Projects"
        colour="#343a40"
        icon="fal fa-th"
        :class="{
          'col-6 ps-1 pl-1': project,
          'col-12': !project,
        }"
      />
    </div>

    <template v-if="project">
      <template
        v-if="
          hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER) ||
          hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR)
        "
      >
        <h6
          class="mb-2"
          :class="{
            'mt-2': !hideProjectNavigation,
          }"
        >
          Gather
        </h6>

        <div class="row">
          <AppCard
            v-if="hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER)"
            app="gather"
            route="project_map"
            title="Collection"
            colour="#743996"
            icon="fal fa-map-marker-plus"
            tooltip="Collect data using your custom built data collection templates."
            class="col"
          />

          <AppCard
            v-if="
              hasPermissionToAccess(AVAILABLE_PERMISSIONS.GATHER_APP_EDITOR)
            "
            app="gather"
            route="template_editor"
            title="App Editor"
            colour="#743996"
            icon="fal fa-tasks-alt"
            tooltip="View & manage data collection templates."
            class="col"
          />
        </div>
      </template>

      <h6 class="my-2">Hub</h6>

      <div class="row">
        <AppCard
          app="hub"
          route="project_dashboard"
          title="Dashboard"
          colour="#32639e"
          icon="fal fa-home"
          tooltip="View & manage all data collected and uploaded into Datanest."
          class="col-12"
          @gotoapp="() => {}"
        />

        <AppCard
          v-if="hasPermissionToAccess(AVAILABLE_PERMISSIONS.MAPS)"
          app="hub"
          route="map"
          title="Maps"
          colour="#0a1a66"
          icon="fal fa-map"
          tooltip="View & manage multiple figures containing GIS related data."
          class="mt-2 col-12"
        />

        <AppCard
          v-if="
            project.has_templates &&
            hasPermissionToAccess(AVAILABLE_PERMISSIONS.DATA_INSIGHTS)
          "
          app="hub"
          route="data-insights"
          title="Insights"
          colour="#32639e"
          icon="fal fa-analytics"
          tooltip="View & manage charts relating to field collected data."
          class="col-12 col-md-6 mt-2 pe-1 pr-1"
        />

        <AppCard
          v-if="hasPermissionToAccess(AVAILABLE_PERMISSIONS.DELIVER)"
          app="hub"
          route="deliver_overview"
          title="Deliver"
          colour="#0a1a66"
          icon="fal fa-file-alt"
          tooltip="Produce automated exports based on field collected data."
          class="d-none d-md-block mt-2"
          :class="{
            'col-12': !project.has_templates,
            'col-6 ps-1 pl-1': project.has_templates,
          }"
        />

        <AppCard
          v-if="hasPermissionToAccess(AVAILABLE_PERMISSIONS.PROJECT_SETTINGS)"
          app="hub"
          route="project_settings"
          title="Project Settings"
          colour="#32639e"
          icon="fal fa-cog"
          tooltip="Configure project, manage team members."
          class="mt-2 col-12"
        />
      </div>
    </template>
  </div>
</template>
