<script setup></script>
<template>
  <div class="layout position-relative">
    <router-view></router-view>
  </div>
</template>

<style scoped>
.layout {
  width: 100%;
}
</style>
