<template>
  <Teleport to="body">
    <div id="canvasModalToggle" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div id="canvasBody" class="modal-body p-0">
            <PaintingField v-if="showCanvas" ref="paintingField" />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { Modal } from 'bootstrap';
import {
  computed,
  defineAsyncComponent,
  nextTick,
  onMounted,
  ref,
  Teleport,
  watch,
} from 'vue';
import { InputValue } from '../gather';
import type IPaintingField from './PaintingField.vue';
const PaintingField = defineAsyncComponent({
  loader: () => import('./PaintingField.vue'),
});

const props = defineProps<{
  modelValue: boolean;
  inputValue: InputValue;
}>();

const paintingField = ref<typeof IPaintingField>();
const showCanvas = ref(false),
  modal = ref<Modal>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (
    event: 'setDrawing',
    value:
      | { json: string }
      | { src: string; blob: Blob; type: string; json: string }
      | null
  ): void;
  (event: 'setCloseDrawing', value: boolean): void;
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(updated) {
    emit('update:modelValue', updated);
  },
});

watch(
  () => props.modelValue,
  (updated) => {
    if (updated) {
      modal.value.show();
    }
    if (!updated) {
      modal.value.hide();
      const data = paintingField.value?.getData();
      dataUrlToBlob(data.dataURL)
        .then((file) => {
          emit('setDrawing', {
            src: URL.createObjectURL(file),
            blob: file,
            type: 'png',
            json: JSON.stringify(data.json),
          });
        })
        .catch((e) => {
          emit('setDrawing', { json: data.json });
        })
        .finally(() => {
          nextTick(() => {
            emit('setCloseDrawing', true);
          });
        });
    }
  }
);

function dataUrlToBlob(data) {
  return new Promise<File>(async (resolve, reject) => {
    try {
      const response = await fetch(data);
      const blob = await response.blob();
      const file = new File([blob], 'canvas-drawing.png', {
        type: 'image/png',
      });
      resolve(file);
    } catch (e) {
      console.error('Failed to create image', e);
      reject(e);
    }
  });
}

onMounted(() => {
  const modalEl = document.getElementById('canvasModalToggle');
  if (!modalEl) {
    throw new Error('Modal element not found');
  }
  modal.value = new Modal(modalEl);
  modalEl.addEventListener('shown.bs.modal', () => {
    show.value = true;
    showCanvas.value = true;
    nextTick(() => {
      if (!!props.inputValue?.value2) {
        if (!paintingField.value) {
          throw new Error('PaintingField not found');
        }
        const data =
          typeof props.inputValue.value2 == 'string'
            ? props.inputValue.value2
            : JSON.stringify(props.inputValue.value2);
        paintingField.value.setData(data);
      }
    });
  });
  modalEl.addEventListener('hidden.bs.modal', () => {
    showCanvas.value = false;
    show.value = false;
  });
});
</script>

<style scoped>
.offcanvas {
  z-index: 1090 !important;
}

#canvasModalToggle .modal-fullscreen {
  padding: 0px !important;
}

#canvasModalToggle .modal-content {
  max-height: 100% !important;
}

#canvasModalToggle .modal-body {
  max-height: calc(100vh - 50px);
  top: 50px;
}
</style>
