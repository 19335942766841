<template>
  <div>
    <label class="form-label" :for="fieldId">Default Value</label>
    <div class="input-group">
      <input
        :id="fieldId"
        v-model="default_value"
        type="number"
        class="form-control"
        @keypress.enter="$emit('blur')"
      />
      <div class="input-group-append">
        <span class="input-group-text">{{ options.unit }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import makeId from '@component-library/local-id.mjs';

export default {
  props: {
    value: Object,
  },
  computed: {
    options: {
      get() {
        return this.value.options;
      },
      set(value) {
        this.$emit('input', { ...this.value, options: value });
      },
    },
    default_value: {
      get() {
        return this.options?.default_value || null;
      },
      set(value) {
        this.options = { ...this.options, default_value: value };
      },
    },
    fieldId() {
      return makeId();
    },
  },
};
</script>
