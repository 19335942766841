<template>
  <div v-if="!disabled">
    <AppEditorAccordion v-if="hasFieldsAccordion" title="Fields">
      <Draggable
        :key="`field-draggable-${draggableCounter}`"
        class="list-group"
        :itemKey="(item) => item.id"
        :list="draggableFieldTypes"
        :group="fieldsDraggableGroup"
        :sort="false"
        :drop="false"
        :handle="draggableHandle"
        @end="draggableCounter++"
      >
        <template #item="{ element }">
          <div
            class="list-group-item list-group-item-action d-flex align-items-center user-select-none"
            @click="() => mobileSize && addField(element)"
          >
            <i
              class="fal fa-grip-lines-vertical grab-item me-2 d-none d-md-block"
            />
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div class="flex-grow-1">
                <FieldIcon :icon="element.icon" />
                {{ element.label }}
              </div>

              <i
                v-if="selectedSection !== null"
                class="fal fa-plus clickable add-btn me-2"
                @click="addField(element)"
              />
            </div>
          </div>
        </template>
      </Draggable>
    </AppEditorAccordion>

    <AppEditorAccordion
      title="Sections"
      :class="{
        'mt-3': sections.length > 0 && hasFieldsAccordion,
        'mb-0': mobileSize,
      }"
    >
      <Draggable
        :key="`section-draggable-${draggableCounter}`"
        class="list-group pb-3"
        :itemKey="(item) => item.id"
        :list="sectionTypes"
        :group="sectionsDraggableGroup"
        :sort="false"
        :clone="cloneSection"
        :disabled="disabled"
        :handle="draggableHandle"
        @end="draggableCounter++"
      >
        <template #item="{ element }">
          <div
            class="list-group-item list-group-item-action d-flex align-items-center user-select-none"
            @click="() => mobileSize && addSection(element)"
          >
            <i
              class="fal fa-grip-lines-vertical grab-item me-2 d-none d-md-block"
            />
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div>
                <i
                  v-if="element.icon"
                  :class="['fal me-2 fa-fw', element.icon]"
                />
                {{ element.title }}
              </div>

              <i
                class="fal fa-plus clickable add-btn me-2"
                @click="addSection(element)"
              />
            </div>
          </div>
        </template>
      </Draggable>
    </AppEditorAccordion>
  </div>
</template>

<script lang="ts" setup>
import sectionTemplates from '@/js/helpers/section-templates';
import { draggableFieldTypes, FieldTypeIds } from '@component-library/fields';
import Draggable from 'vuedraggable';
import AppEditorAccordion from './AppEditorAccordion.vue';
import FieldIcon from './FieldIcon.vue';
import { computed, ref } from 'vue';
import EventBus from '@component-library/EventBus';
import Field from '@component-library/classes/Field';
import Section from '@component-library/classes/Section';
import { App } from '@component-library/gather';
import { useToastStore } from '@component-library/store/toasts';

const toastStore = useToastStore();
const props = defineProps<{
  fields?: Field[];
  template: App;
  modelValue: Section[];
  disabled: boolean;
  selectedSection: number | null;
  isTemplateEditorOperating: boolean;
  mobileSize: boolean;
}>();

const emit = defineEmits(['addSection', 'update:modelValue', 'addField']);

const sectionTypes = ref([
  { id: 'regular', title: 'Empty Section', icon: 'fa-folder' },
  { id: 'sample_matrix', title: 'Sample Matrix', icon: 'fa-map-marker' },
  { id: 'sample_lab', title: 'Sample Lab', icon: 'fa-map-marker' },
  { id: 'soil_log', title: 'Soil Log', icon: 'fa-bookmark' },
  { id: 'site_visit', title: 'Site Visit', icon: 'fa-walking' },
  { id: 'photolog', title: 'Photo Log', icon: 'fa-images' },
  { id: 'health_safety', title: 'Health and Safety', icon: 'fa-hospital' },
  {
    id: 'gps_point_metadata',
    title: 'GPS Point Metadata',
    icon: 'fa-map-pin',
  },
]);
const draggableCounter = ref(0);

const sections = computed({
  get() {
    return props.modelValue || [];
  },
  set(updated) {
    emit('update:modelValue', updated);
  },
});
const section = computed<Section | null>({
  get() {
    return props.selectedSection !== null
      ? props.modelValue[props.selectedSection]
      : null;
  },
  set(updated) {
    if (!updated) {
      throw 'Cannot delete section by providing null as an update';
    }
    if (!props.selectedSection) {
      throw 'Cannot update section, no section selected';
    }
    const sections = [...props.modelValue];
    sections.splice(props.selectedSection, 1, updated);
    emit('update:modelValue', sections);
  },
});

const draggableHandle = computed(() => {
  return window.innerWidth < 500 ? '.grab-item' : null;
});

const fieldsDraggableGroup = computed(() => {
  return {
    name: 'fields',
    pull: !props.isTemplateEditorOperating ? 'clone' : false,
    put: false,
  };
});

const sectionsDraggableGroup = computed(() => {
  return {
    name: 'sections',
    pull: !props.isTemplateEditorOperating ? 'clone' : false,
    put: false,
  };
});

const hasFieldsAccordion = computed(() => {
  return (
    sections.value.length > 0 &&
    (!props.mobileSize || props.selectedSection !== null)
  );
});

function cloneSection(element) {
  const type = element.id;

  let section: Partial<Section> = {
    label: 'Section ' + (sections.value.length + 1),
    template_fields: [],
    is_repeatable: false,
    primary_field_id: null,
  };

  if (type != 'regular') {
    const template = sectionTemplates.getTemplateById(type);
    section = {
      label: template.label,
      template_fields: template.fields,
      is_lab_sample: type == 'sample_matrix' || type == 'sample_lab',
      is_soil_log: type == 'soil_log',
      is_site_visit: type == 'site_visit',
      is_health_safety: type == 'health_safety',
      is_gps_point_metadata: type === 'gps_point_metadata',
    };
  }

  return section;
}

function addField(field) {
  if (!section.value) {
    throw 'Cannot add field, no section selected';
  }

  if (
    field.field_type_id === FieldTypeIds.COPY_DATA_LINK &&
    section.value.template_fields.some(
      (f) => f.field_type_id === FieldTypeIds.COPY_DATA_LINK
    )
  ) {
    toastStore.error('A section can have only one Copy Data Link field.');
    return;
  }

  const fieldIndex = section.value.template_fields.length;
  emit('addField', {
    sectionIndex: props.selectedSection,
    fieldIndex,
    value: {
      ...field,
      template_section_id: section.value.id,
    },
  });

  EventBus.$emit('selectField', fieldIndex);
}

function addSection(section) {
  const newSection = cloneSection(section);

  emit('addSection', {
    element: newSection,
    index: sections.value.length + 1,
  });
}
</script>

<style scoped>
.list-group-item {
  cursor: grab;
}

.grab-item {
  opacity: 0.2;
}

.list-group-item:hover {
  cursor: all-scroll;
}

.list-group-item:hover .add-btn {
  display: block;
}
</style>
