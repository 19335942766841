import { checkIfAppReachedLimit, checkIsEmbedded, checkIsPhaseVisible, checkIsPoiApp } from "@component-library/business-logic/app";
import { FEATURES, hasAccess } from "@component-library/feature-manager";
import { App, Item } from "@component-library/gather";
import { LayerType } from "@maps/lib/olbm/types";
import { computed, ComputedRef, onBeforeUnmount, onMounted, Ref, ref } from "vue";
import { useGatherSchemaStore } from "@component-library/store/gather-schema";
import EventBus from "@component-library/EventBus";

const hedgeIconSrc = '/styled-map-icon/tool-icons/hedge';

type MenuActionItem = {
    icon: string;
    label: string;
    tabId?: number;
    click: () => void;
};

export function useMapActions(
    apps: ComputedRef<App[]>,
    items: ComputedRef<Item[]>,
    isScaleVisible: Ref<boolean>,
    isOnline: Ref<boolean>,
    isBasemapMenuVisible: Ref<boolean>,
    clickRootEmitAction: (method, options?: any) => void,
    clickNormalEmitAction: (
        method:
            | 'showProjectInfoModal'
            | 'scaleToggle'
            | 'showAddressLookupModal'
            | 'showOfflineManagerModal'
    ) => void,
    openManagersModal: () => void,
    checkIsAppEnabled: (appId: number, allApps: App[], isNonSpatialView?: boolean) => boolean
) {
    const gatherSchema = useGatherSchemaStore();
    const isToolSelected = ref(false);
    const selectedPhaseId = ref<number | null>(null);
    const hasLocation = ref(false);

    const drawingItems = computed(() => {
        const drawingItems = {
            [LayerType.POINT]: {
                title: 'Point',
                icon: 'fa-map-marker',
                method: 'startDrawMarker',
            },
            [LayerType.POLYGON]: {
                title: 'Polygon',
                icon: 'fa-hexagon',
                method: 'startDrawPoly',
            },
            [LayerType.POLYLINE]: {
                title: 'Line',
                icon: 'fa-wave-triangle',
                method: 'startDrawPoly',
            },
            [LayerType.ARROW]: {
                title: 'Arrow',
                icon: 'fa-arrow-right',
                method: 'startDrawPoly',
            },
            [LayerType.CIRCLE]: {
                title: 'Circle',
                icon: 'fa-circle',
                method: 'startDrawPoly',
            },
            [LayerType.RECTANGLE]: {
                title: 'Rectangle',
                icon: 'fa-rectangle-wide',
                method: 'startDrawPoly',
            },
        };

        if (hasAccess(FEATURES.MAPS_HEDGE)) {
            drawingItems[LayerType.HEDGE] = {
                title: 'Hedge',
                icon: hedgeIconSrc,
                method: 'startDrawPoly',
            };
        }

        return drawingItems;
    });

    const appMenu = computed(() => {
        const menuItems: MenuActionItem[] = [];

        const _drawingItems = drawingItems.value;
        _drawingItems['non-spatial'] = {
            title: 'Non-spatial',
            icon: 'fa-chart-bar',
            method: 'startNonSpatial',
        };

        function addMenuItem(type, label, tabId) {
            const drawingItem = _drawingItems[type];
            // The drawing item could not be ready. For example the Hedge is controlled
            // by a permission. If the permission is not ready the Hedge is not available.
            if (!drawingItem) {
                return;
            }
            menuItems.push({
                icon: drawingItem.icon,
                label,
                tabId,
                click: () => {
                    isToolSelected.value = false;
                    let param = {
                        tabId,
                        drawingType: type,
                    } as { tabId: number; drawingType: string; isPlacedAtCenter?: boolean; };
                    if (type === LayerType.POINT) {
                        param = {
                            ...param,
                            isPlacedAtCenter: true,
                        };
                    }
                    clickRootEmitAction(drawingItem.method, param);
                },
            });
        }

        const templateTabs = apps.value
            .filter(
                (t) => {
                    if (t.is_read_only) {
                        return false;
                    }
                    return !checkIfAppReachedLimit(t) &&
                        !checkIsEmbedded(t, apps.value) &&
                        (selectedPhaseId.value !== null
                            ? t.app_phase_group_id === selectedPhaseId.value
                            : checkIsPhaseVisible(t, gatherSchema.phases));
                }
            )
            .sort((a, b) => a.order - b.order);

        const hasAnyType = templateTabs.some(
            (t) =>
                t.drawing_type === 'any' &&
                checkIsAppEnabled(t.id, apps.value)
        );

        if (hasAnyType) {
            Object.keys(_drawingItems).forEach((type) => {
                const { title: label } = _drawingItems[type];
                addMenuItem(type, label, null);
            });
            return menuItems;
        }

        function isPreviousAppComplete(tab: App) {
            const previousShareableAppId = tab.previous_app_requirement;

            const previousApp = apps.value.find(
                (t) => t.parent_shareables &&
                    t.parent_shareables.findIndex(
                        (shareable) => shareable.share_group === previousShareableAppId
                    ) !== -1
            );

            return previousApp
                ? items.value.findIndex((s) => s.template_tab_id === previousApp.id) !==
                -1
                : false;
        }

        for (const tab of templateTabs.filter((t) => t.drawing_type !== 'any')) {
            if (
                tab.previous_app_requirement
                    ? isPreviousAppComplete(tab)
                    : !checkIsPoiApp(templateTabs, tab.id)
            ) {
                const { drawing_type: type, title: label, id: tabId } = tab;
                addMenuItem(type, label, tabId);
            }
        }

        return menuItems;
    });

    const toolMenu = computed(() => {
        const menuItems: MenuActionItem[] = [];

        const addMenuItem = (type) => {
            const drawingItem = drawingItems.value[type];
            if (!drawingItem) {
                throw new Error(`Drawing item ${type} is not available`);
            }

            menuItems.push({
                icon: drawingItem.icon,
                label: drawingItem.title,
                click: () => {
                    isToolSelected.value = true;
                    clickRootEmitAction('startDrawPoly', {
                        tabId: null,
                        drawingType: type,
                        isPlainLayer: true,
                    });
                },
            });
        };

        const types = [
            LayerType.POLYGON,
            LayerType.RECTANGLE,
            LayerType.CIRCLE,
            LayerType.POLYLINE,
            LayerType.ARROW,
        ];

        if (hasAccess(FEATURES.MAPS_HEDGE)) {
            types.push(LayerType.HEDGE);
        }

        types.forEach((type) => {
            addMenuItem(type);
        });

        return menuItems;
    });

    const actionMenu = computed(() => {
        let menuItems: MenuActionItem[] = [];

        if (hasLocation.value) {
            menuItems.push({
                icon: 'fa-location',
                label: 'Go to My Location',
                click: () => {
                    clickRootEmitAction('gotoMyLocation');
                },
            });
        }

        menuItems = [
            ...menuItems,
            ...[
                {
                    icon: 'fa-home-alt',
                    label: 'Go to Project Address',
                    click: () => {
                        clickRootEmitAction('backToAddress');
                    },
                },
                {
                    icon: 'fa-info',
                    label: 'Project Information',
                    click: () => {
                        clickNormalEmitAction('showProjectInfoModal');
                    },
                },
                {
                    icon: 'fa-horizontal-rule',
                    label: `${isScaleVisible.value ? 'Hide' : 'Show'} Scale Line`,
                    click: () => {
                        clickNormalEmitAction('scaleToggle');
                    },
                },
            ],
        ];

        if (isOnline.value) {
            menuItems = [
                ...menuItems,
                ...[
                    {
                        icon: 'fa-map-marked',
                        label: 'Find Address',
                        click: () => {
                            clickNormalEmitAction('showAddressLookupModal');
                        },
                    },
                    {
                        icon: 'fa-map',
                        label: 'Change Basemap',
                        click: () => {
                            isBasemapMenuVisible.value = true;
                        },
                    },
                ],
            ];

            if (apps.value.length) {
                menuItems.push({
                    icon: 'fa-wifi-slash',
                    label: 'Offline Manager',
                    click: () => {
                        clickNormalEmitAction('showOfflineManagerModal');
                    },
                });
            }
        }

        menuItems.push({
            icon: 'fa-layer-group',
            label: 'Layer Manager',
            click: () => {
                openManagersModal();
            },
        });

        return menuItems;
    });

    function onHasLocation(state: boolean) {
        hasLocation.value = state;
    }
    onMounted(() => {
        EventBus.$on('hasLocation', onHasLocation);
    });
    onBeforeUnmount(() => {
        EventBus.$off('hasLocation', onHasLocation);
    });

    return {
        appMenu,
        actionMenu,
        toolMenu,

        selectedPhaseId,
        isToolSelected,
        isScaleVisible,
    };

}