<script setup>
import { ref, computed } from 'vue';
import ContextMenu from '../../components/ContextMenu.vue';
import * as constants from './constants';

const props = defineProps({
  appId: Number,
  markers: Array,
  group: Number,
  selectedMarkerId: Number,
});
const emit = defineEmits(['markerSelect', 'like', 'unlike']);

const menu = ref(null);

const selectedMarkerBorderColor = computed(() => {
  if (props.appId === constants.APP_GATHER) {
    return '#805fb4';
  }

  return '#3366a2';
});

const isLikeAvailable = computed(() => {
  return [
    constants.MARKER_GROUP_BUILTIN,
    constants.MARKER_GROUP_CUSTOM,
  ].includes(props.group);
});

const isUnlikeAvailable = computed(() => {
  return props.group === constants.MARKER_GROUP_FAVORITE;
});

const contextOptions = computed(() => {
  const options = [];
  if (isLikeAvailable.value) {
    options.push({
      label: 'Favourite',
      click: (markerId) => onLikeClick(markerId),
    });
  }
  if (isUnlikeAvailable.value) {
    options.push({
      label: 'Un-favourite',
      click: (markerId) => onUnlikeClick(markerId),
    });
  }
  return options;
});

const onMarkerClick = (id) => {
  emit('markerSelect', id);
};

const onLikeClick = (id) => {
  emit('like', id);
};

const onUnlikeClick = (id) => {
  emit('unlike', id);
};
</script>

<template>
  <div class="marker-palette border-0">
    <div
      v-if="markers.length"
      class="content-pane d-flex flex-wrap align-items-start align-content-start"
    >
      <div
        v-for="item in markers"
        :key="`marker_${item.id}`"
        class="marker"
        :class="{ selected: props.selectedMarkerId === item.id }"
        :title="item.title"
        @click="onMarkerClick(item.id)"
        @contextmenu.prevent="menu.open($event, item.id)"
      >
        <img :src="`/markers/${item.id}`" width="24" height="24" />
      </div>

      <!-- The data of the menu is the marker id, see the contextmenu event listener. -->
      <ContextMenu ref="menu" :options="contextOptions" />
    </div>
    <div v-else class="notice-pane alert alert-info mb-0" role="alert">
      <template v-if="props.group === constants.MARKER_GROUP_CUSTOM">
        Contact <b>Datanest</b> team to get custom markers.
      </template>
      <template v-else-if="props.group === constants.MARKER_GROUP_FAVORITE">
        Right click a marker in the <b>Default</b> or <b>Custom</b> tab and
        click the <b>Favourite</b> menu item to add the marker as a favourite
        one.
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$border_color: #dee2e6;
$selected_marker_border_color: v-bind(selectedMarkerBorderColor);

.marker-palette {
  border-left: 1px solid $border_color;
  border-right: 1px solid $border_color;
  border-bottom: 1px solid $border_color;
  padding: 10px;

  .content-pane {
    gap: 10px;

    .marker {
      cursor: pointer;
      padding: 3px;

      &.selected {
        border: 3px solid $selected_marker_border_color;
        padding: 0;
      }
    }
  }

  .content-pane,
  .notice-pane {
    min-height: 200px;
  }
}

.small-palette .marker-palette {
  max-height: 100px;
  overflow: auto;
}
</style>
