<template>
  <div class="d-flex align-content-start flex-wrap operator-picker">
    <button
      v-for="(o, oIndex) in operators"
      :key="`operator-${oIndex}`"
      class="btn btn-outline-dark"
      @click="handleAdd(o)"
    >
      {{ o }}
    </button>
  </div>
</template>

<script>
import * as cl_bm from '@component-library/business-model';

export default {
  props: {
    operators: Array,
  },
  methods: {
    handleAdd(operator) {
      this.$emit('add', {
        type: cl_bm.expression.INPUT_TYPE_OPERATOR,
        value: operator,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.operator-picker {
  gap: 0.5rem;

  button {
    width: 60px;
    height: 60px;
  }
}
</style>
