<script setup lang="ts">
import FileUploader from '@component-library/components/FileUploader.vue';
import useFileUpload from '@component-library/composables/useFileUpload';
import { getFileExtension } from '@component-library/utils';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import {
  getSupportedVideoFileExtensions,
  getVideoMimeTypeByFileExtension,
} from '@component-library/business-logic/media';

const options = defineModel({ type: Object, required: true });

const route = useRoute();
const { project_id: projectId } = route.params;

const defaultValue = computed({
  get() {
    return options.value.default;
  },
  set(value) {
    options.value = {
      ...options.value,
      default: value,
    };
  },
});

const fileUrl = computed(() => {
  return defaultValue.value
    ? `/api/images/value/${projectId}/${defaultValue.value.assetName}`
    : null;
});

const videoPlayer = ref<HTMLVideoElement | null>(null);

const mimeType = computed<string | undefined>(() => {
  if (!defaultValue.value?.fileName) {
    return undefined;
  }

  const ext = getFileExtension(defaultValue.value.fileName);
  return getVideoMimeTypeByFileExtension(ext) ?? undefined;
});

const canPlay = computed(() => {
  if (!mimeType.value || !videoPlayer.value) {
    return false;
  }

  return videoPlayer.value.canPlayType(mimeType.value);
});

const {
  result,
  isUploading,
  uploadingProgress,
  isUploadingComplete,
  uploadFile,
} = useFileUpload(projectId);

watch(result, () => {
  defaultValue.value = result.value ? { ...result.value } : null;
});

async function handleFileUpload(value: File | null) {
  if (value) {
    uploadFile(value);
  }
}

function handleFileRemove() {
  defaultValue.value = null;
}
</script>

<template>
  <div>
    <label> Default Value </label>
    <FileUploader
      v-if="!fileUrl"
      :supportedType="getSupportedVideoFileExtensions()"
      :pending="isUploading"
      :progress="uploadingProgress"
      :complete="isUploadingComplete"
      maxFileSize="100"
      @uploader="handleFileUpload"
    />
    <div v-else>
      <div class="col text-center">
        <p>{{ defaultValue.fileName }}</p>
        <video ref="videoPlayer" controls class="w-100">
          <source :src="fileUrl" :type="mimeType" />
          Your device does not support the
          <code>video</code> element.
        </video>
      </div>

      <div v-if="!canPlay" class="col text-center">
        <p>
          Device does not support playing <code>{{ mimeType }}</code> file
          types<br />
          Download file to preview content
        </p>

        <a :href="fileUrl" download
          ><i class="fas fa-file-download fa-3x"></i
        ></a>
      </div>

      <div class="text-center mt-2">
        <button
          type="button"
          class="btn btn-outline-danger mx-2 remove-file"
          @click.prevent="handleFileRemove"
        >
          Remove File
        </button>
      </div>
    </div>
  </div>
</template>
