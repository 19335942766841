<template>
  <div class="d-flex align-items-center justify-content-between">
    <h6 class="text-muted d-flex align-items-center mb-0">
      <router-link :to="`/${project?.project_id}/map`"> Project </router-link>
      <div
        v-for="(route, routeIndex) in routes"
        :key="routeIndex"
        class="d-flex align-items-center"
      >
        <i class="fal fa-chevron-right mx-3" />
        <div v-if="routeIndex == routes.length - 1">
          {{ getRouteTitleFromName(route) }}
        </div>
        <router-link v-else :to="{ name: route }">
          {{ getRouteTitleFromName(route) }}
        </router-link>
      </div>
    </h6>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    routes: Array,
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
    }),
  },
  methods: {
    getRouteTitleFromName(route) {
      const routes = this.$router.getRoutes();
      return routes.find((r) => r.name == route)?.meta?.title || route;
    },
  },
};
</script>
<style scoped>
i {
  font-size: 10px;
}

a {
  text-decoration: underline;
  color: inherit;
}
</style>
