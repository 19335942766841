<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void;
}>();

const props = withDefaults(
  defineProps<{
    isLoading?: boolean;
    status?: string | boolean;
    showSuccess?: boolean;
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
  }>(),
  {
    isLoading: false,
    status: '',
    showSuccess: false,
    disabled: false,
    type: 'button',
  }
);

const currentButtonStatus = ref<string | boolean>('');

watch(
  () => props.status,
  (newVal) => {
    currentButtonStatus.value = newVal;

    if (newVal == true) {
      setTimeout(() => {
        currentButtonStatus.value = '';
      }, 2500);
    }
  }
);

const getSpinnerClass = computed(() => {
  return {
    spinner: props.isLoading,
  };
});

const getButtonStatus = computed(() => {
  return {
    'btn-success': isSuccess.value && props.showSuccess,
    'is-loading': props.isLoading,
  };
});

const isSuccess = computed(() => {
  return (
    currentButtonStatus.value == true && !emptyStatus.value && !props.isLoading
  );
});

const isDefault = computed(() => {
  return props.isLoading || (!props.isLoading && emptyStatus.value);
});

const emptyStatus = computed(() => {
  return currentButtonStatus.value == '';
});
</script>

<template>
  <button
    class="loading-btn"
    :class="getButtonStatus"
    :disabled="isSuccess || isLoading || disabled"
    :type="type"
    @click="($event) => $emit('click', $event)"
  >
    <div :class="getSpinnerClass"></div>
    <i v-if="isSuccess && showSuccess" class="fas fa-check"></i>
    <slot v-if="isDefault || !showSuccess"></slot>
  </button>
</template>

<style scoped>
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-btn {
  transition: 0.3s all ease;
}

.loading-btn:disabled {
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 4px;
  opacity: 1;
  filter: alpha(opacity=100);
  animation: rotation 0.7s infinite linear;
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top-color: #9e9e9e;
  border-radius: 100%;
  transition: 0.3s all ease;
}
</style>
