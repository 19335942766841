import axios from 'axios';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';

export type FileUploadResult = {
  assetName: string;
  fileName: string;
};

export default function useFileUpload(projectId) {
  const result: Ref<FileUploadResult | null> = ref(null);
  const isUploading = ref(false);
  const uploadingProgress = ref(0);
  const isUploadingComplete = computed(() => {
    return uploadingProgress.value === 100;
  });

  async function uploadFile(file: File) {
    isUploading.value = true;

    try {
      const { fileName, assetName } = await uploadAsset(
        projectId,
        file,
        (value) => {
          uploadingProgress.value = value;
        }
      );

      result.value = {
        assetName,
        fileName,
      };
    } finally {
      isUploading.value = false;
      uploadingProgress.value = 0;
    }
  }

  return {
    result,
    isUploading,
    uploadingProgress,
    isUploadingComplete,
    uploadFile,
  };
}

export async function uploadAsset(
  projectId: number | null,
  file: File,
  onProgress: (value: number) => void
) {
  const data = new FormData();
  if (projectId) {
    data.append('project_id', String(projectId));
  }
  data.append('file', file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (e) => {
      const { lengthComputable } = e;
      if (lengthComputable) {
        const { loaded, total } = e;
        onProgress(Math.round((loaded / total) * 100));
      }
    },
  };

  try {
    const {
      data: { src: assetName },
    } = await axios.post(`/api/project/asset`, data, config);
    return { fileName: file.name, assetName };
  } catch (e: any) {
    if ('response' in e) {
      const { status, statusText, data } = e.response;
      throw {
        status: `${status} - ${statusText}`,
        message: data?.message ? data.message : null,
      };
    } else {
      throw {
        status: e,
      };
    }
  }
}
