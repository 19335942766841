<script setup lang="ts">
import _kebabCase from 'lodash/kebabCase';
import { onMounted, ref, watch } from 'vue';
import makeId from '../../local-id.mjs';

const props = withDefaults(
  defineProps<{
    name: string;
    label: string;
    checked?: boolean | 1 | 0;
    indeterminate?: boolean | 1 | 0;
  }>(),
  {
    checked: false,
    indeterminate: false,
  }
);

const emit = defineEmits<{
  (e: 'change', value: boolean): void;
}>();

const toggle = ref<HTMLInputElement | null>(null);
const id = `${_kebabCase(props.name)}-toggle-${makeId()}`;

function handleChange(eventTarget: EventTarget) {
  emit('change', (eventTarget as HTMLInputElement).checked);
}

watch(
  () => props.indeterminate,
  (value) => {
    if (toggle.value) {
      toggle.value.indeterminate = value ? true : false;
    }
  }
);
onMounted(() => {
  if (toggle.value) {
    toggle.value.indeterminate = props.indeterminate ? true : false;
  }
});

defineExpose({
  setChecked(checked) {
    if (toggle.value) {
      toggle.value.checked = checked;
    }
  },
});
</script>

<template>
  <div class="form-check form-switch toggle">
    <input
      :id="id"
      ref="toggle"
      type="checkbox"
      class="form-check-input"
      :checked="checked ? true : false"
      @change="handleChange($event.target)"
    />
    <label class="form-label clickable" :for="id">
      {{ label }}
    </label>
  </div>
</template>
