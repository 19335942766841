<script setup lang="ts">
import makeId from '../local-id.mjs';
import InfoButton from './InfoButton.vue';

withDefaults(
  defineProps<{
    name: string;
    label: string;
    modelValue?: number;
    formatValue?: (value: number) => string;
    units?: string;
    min: number;
    max: number;
    step: number;
    info?: string;
  }>(),
  {
    formatValue: (value: number): string => {
      return String(value);
    },
    units: '',
  }
);

const id = makeId();
</script>

<template>
  <div>
    <label class="d-flex form-label align-items-center" :for="id">
      {{ label }} ({{ `${formatValue(modelValue ?? 0)}${units}` }})
      <sup class="text-danger">*</sup>
      <InfoButton v-if="info" class="ms-1" :info="info" />
    </label>
    <input
      :id="id"
      type="range"
      class="form-range"
      :min="min"
      :max="max"
      :step="step"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>
