<script lang="ts" setup>
import ImageList from './ImageList.vue';
import ImageExplorer from './ImageExplorer.vue';
import { checkIsMobile } from '../../utils';
import InputText from '../../components/InputText.vue';
import { computed, inject, onMounted, ref } from 'vue';
import { GatherField, InputValue } from '../../gather';
import {
  InputValueValueFile,
  parseInputValueFileName,
  downloadInputValueFile,
} from '../../file-utils';
import InputSelect from '../../components/InputSelect.vue';
import EventBus from '../../EventBus';

const formContext = inject<any | null>('formContext');
const props = defineProps<{
  value: InputValue;
  inputValues: InputValue[];
  field: GatherField;
  repeatable: boolean;
}>();
const emit = defineEmits<{
  (event: 'clickSetPreview', data: any): void;
  (event: 'isLoading', value: boolean): void;
}>();

const inputIndex = ref<null | number>(null),
  isMobile = ref(checkIsMobile());

const isRequired = computed(() => {
  return props.field?.is_required || false;
});

const allowMultiple = computed(() => {
  return props.field?.options?.has_multiple || props.repeatable;
});

const files = computed<InputValueValueFile[]>({
  get() {
    const projectId =
      props.value.project_id ?? formContext?.project?.project_id;
    const { value } = props.value;
    let result: InputValueValueFile[] = (value as InputValueValueFile[]) ?? [];
    if (!Array.isArray(result)) {
      // compatibility for legacy data of single media fields
      const { value2 } = props.value;
      result = [
        {
          name: String(value2),
          src: `/api/images/value/${projectId}/${value}`,
        },
      ];
    } else {
      result = result.map((asset) => {
        const { blob, name, src } = asset;
        if (blob) {
          return asset;
        }

        return {
          name,
          src: !src.includes('/')
            ? `/api/images/value/${projectId}/${src}`
            : src,
        };
      });
    }
    return result;
  },
  set(value: InputValueValueFile[]) {
    const has_multiple = props.field.options?.has_multiple;
    if (has_multiple || value.length <= 1) {
      EventBus.$emit('updateInputValue', {
        inputValue: { ...props.value, value, value2: null },
        field: props.value.template_field_id,
        sectionIndex: props.value.template_section_index,
        templateTabId: props.value.template_tab_id,
      });
    } else {
      EventBus.$emit('updateInputValue', {
        inputValue: {
          ...props.value,
          value: value.slice(0, 1),
          value2: null,
        },
        field: props.value.template_field_id,
        sectionIndex: props.value.template_section_index,
        templateTabId: props.value.template_tab_id,
      });

      // The left images are used to create new input values
      if (props.repeatable) {
        const basedInputValue = props.inputValues
          .filter(
            (item) =>
              item.template_section_id == props.value.template_section_id &&
              item.template_field_id == props.value.template_field_id
          )
          .reduce((prev, next) =>
            prev.template_section_index > next.template_section_index
              ? prev
              : next
          );

        for (let i = 1; i < value.length; i++) {
          const newSectionIndex = basedInputValue.template_section_index + i;
          EventBus.$emit('updateInputValue', {
            inputValue: {
              ...basedInputValue,
              value: value.slice(i, i + 1),
              value2: null,
              template_section_index: newSectionIndex,
            },
            field: basedInputValue.template_field_id,
            sectionIndex: newSectionIndex,
            templateTabId: basedInputValue.template_tab_id,
          });
        }
      }
    }
  },
});

const isMultipleInput = computed(() => {
  return props.field.options?.has_multiple && files.value?.length > 0;
});

const imageDirection = computed({
  get() {
    return props.value.options?.direction || 'N';
  },
  set(value) {
    const options = { ...(props.value.options ?? {}), direction: value };
    EventBus.$emit('updateInputValue', {
      inputValue: {
        ...props.value,
        options,
      },
      field: props.value.template_field_id,
      sectionIndex: props.value.template_section_index,
      templateTabId: props.value.template_tab_id,
    });
  },
});

const isImageDirectionVisible = computed(() => {
  const { has_multiple, track_direction } = props.field.options ?? {};
  return !has_multiple && track_direction;
});

function clickSetPreview(file) {
  emit('clickSetPreview', file);
}

function isLoading(value: boolean) {
  emit('isLoading', value);
}

function updateFileName({ index, name }) {
  const newFiles = [...files.value];
  newFiles[index].name = parseInputValueFileName(newFiles[index], name);
  files.value = newFiles;
}

function handleRemoveImage() {
  files.value = [];
}

onMounted(() => {
  const inputValueIndex = props.inputValues.findIndex(
    (inputValue) => JSON.stringify(inputValue) === JSON.stringify(props.value)
  );
  inputIndex.value = inputValueIndex;
});
</script>

<template>
  <div>
    <label id="file">
      {{ field.label }}<sup v-if="isRequired" class="text-danger">*</sup>
      <br />
      <small class="text-muted">(png,jpg,jpeg,webp)</small>
    </label>

    <div class="mt-2 w-100">
      <div v-if="isMultipleInput || !files.length" class="d-flex w-100">
        <ImageExplorer
          v-if="isMobile"
          v-model="files"
          :captureOnly="isMobile"
          :hasError="isRequired && files.length == 0"
          :hasMultiple="isMultipleInput"
          :allowMultiple="allowMultiple"
          :allowRenaming="field.options?.allow_renaming"
          class="me-2"
          @isLoading="isLoading"
        />
        <ImageExplorer
          v-model="files"
          :hasError="isRequired && files.length == 0"
          :allowMultiple="allowMultiple"
          :allowRenaming="field.options?.allow_renaming"
          @isLoading="isLoading"
        />
      </div>
      <template v-else>
        <img loading="lazy" :src="files[0].src" width="90%" />
        <div class="mt-2 d-flex gap-2 align-items-end">
          <InputText
            v-if="field.options?.allow_renaming"
            v-model="files[0].name"
            label="File name"
            isSmall
            placeholder="Enter file name"
            class="mb-1 flex-grow-1"
            @change.native="updateFileName({ index: 0, name: files[0].name })"
          />
          <span v-else class="d-block overflow-hidden">{{
            files[0]?.name
          }}</span>
          <span
            v-if="(files[0].blob || files[0].src) && files[0].name"
            class="clickable"
            @click.stop.prevent="downloadInputValueFile(files[0])"
          >
            <i class="fas fa-fw fa-download text-primary"></i>
          </span>
        </div>
        <button
          class="btn btn-outline-danger mt-2 w-100"
          @click="handleRemoveImage"
        >
          Remove Image
        </button>
      </template>
    </div>

    <InputSelect
      v-if="isImageDirectionVisible"
      v-model="imageDirection"
      label="Image direction"
      class="mt-2"
      :class="{
        'border-danger':
          field.is_required && (!value || String(imageDirection) === ''),
      }"
      :required="!!field.is_required"
    >
      <option value="N">N</option>
      <option value="NE">NE</option>
      <option value="E">E</option>
      <option value="SE">SE</option>
      <option value="S">S</option>
      <option value="SW">SW</option>
      <option value="W">W</option>
      <option value="NW">NW</option>
    </InputSelect>

    <ImageList
      v-if="isMultipleInput"
      v-model="files"
      :field="field"
      @updateFileName="updateFileName"
      @setPreview="clickSetPreview"
    />
  </div>
</template>
