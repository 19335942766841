<script setup lang="ts">
import { getFields } from '@component-library/business-logic/app';
import type {
  App,
  AppLinkConfig,
  GatherField,
} from '@component-library/gather';
import { produce } from 'immer';
import { ref } from 'vue';
import LinkedApp from './LinkedApp.vue';

const props = defineProps<{
  app: App;
  linkedApps: App[];
}>();

const emit = defineEmits<{
  (
    event: 'change',
    value: { linkedAppId: number; linkConfig: AppLinkConfig }
  ): void;
}>();

const isExpandedByLinkedAppId = ref<Record<number, boolean>>(
  props.linkedApps.reduce((accu, la) => {
    accu[la.id] = false;
    return accu;
  }, {})
);

function getLinkedField(linkedAppId: number): GatherField {
  const linkedApp = props.linkedApps.find((la) => la.id === linkedAppId)!;
  return getFields(props.app).find(
    (f: GatherField) => f.options?.template_tab_title === linkedApp.title
  )!;
}

function handledLinkedAppToggle(linkedAppId: number, value: boolean) {
  isExpandedByLinkedAppId.value = produce(
    isExpandedByLinkedAppId.value,
    (draft) => {
      draft[linkedAppId] = value;
    }
  );
}

function handleLinkConfigChange(linkedAppId: number, value: AppLinkConfig) {
  emit('change', { linkedAppId, linkConfig: value });
}
</script>

<template>
  <div>
    <div class="mb-2">Linked Apps</div>
    <div>
      <LinkedApp
        v-for="(la, index) in linkedApps"
        :key="`linked-app-${la.id}`"
        :class="{ 'mb-1': index < linkedApps.length - 1 }"
        :isExpanded="isExpandedByLinkedAppId[la.id]"
        :app="app"
        :linkField="getLinkedField(la.id)"
        :linkedApp="la"
        @toggle="(value) => handledLinkedAppToggle(la.id, value)"
        @change="(value) => handleLinkConfigChange(la.id, value)"
      />
    </div>
  </div>
</template>
