<template>
  <div>
    <h1 class="fal fa-tally"></h1>
    <h6 class="mb-1">
      It appears that no fields exist that can be used by this editor.
    </h6>
    <small class="text-muted">
      You must have either a number field or a dropdown with only number values.
    </small>
  </div>
</template>
