<template>
  <Modal v-if="show" :half="true" @close="close">
    <template #header> Project Information </template>
    <template #default>
      <table class="table table-bordered w-100">
        <tr>
          <td class="bg-primary text-white fw-bold">Job Number</td>
          <td>
            {{ project.project_number }}
          </td>
        </tr>
        <tr>
          <td class="bg-primary text-white fw-bold">Project Name</td>
          <td>{{ project.project_name }}</td>
        </tr>
        <tr>
          <td class="bg-primary text-white fw-bold">Client</td>
          <td>
            {{ project.project_client }}
          </td>
        </tr>
        <tr>
          <td class="bg-primary text-white fw-bold">Address</td>
          <td>{{ project.project_address }}</td>
        </tr>
      </table>
    </template>

    <template #footer>
      <button class="btn btn-outline-secondary w-100" @click="close">
        Close
      </button>
    </template>
  </Modal>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@component-library/components/Modal.vue';
export default {
  props: ['show', 'error'],
  components: { Modal },
  computed: {
    ...mapState({
      project: (state) => state.project,
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
tr td:first-child {
  width: 30%;
}
tr td:last-child {
  width: 70%;
  text-align: left;
}
</style>
