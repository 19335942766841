<script lang="ts" setup>
import { getDefaultValuePair } from '@component-library/business-logic/expression';
import { FieldTypeIds } from '@component-library/fields';
import { computed, nextTick, onMounted } from 'vue';

const datetime = defineModel({ type: String, required: true });

const date = computed({
  get() {
    return datetime.value.split(' ')[0] ?? null;
  },
  set(value) {
    datetime.value = `${value} ${datetime.value.split(' ')[1] ?? ''}`.trim();
  },
});
const time = computed<string | null>({
  get() {
    return datetime.value.split(' ')[1] ?? null;
  },
  set(value) {
    datetime.value = `${date.value} ${value}`;
  },
});
onMounted(async () => {
  const { value, value2 } = getDefaultValuePair({
    field_type_id: FieldTypeIds.DATE,
  });
  if (!date.value) {
    date.value = value;
  }
  await nextTick();
  if (!time.value) {
    time.value = value2;
  }
});
</script>

<template>
  <div class="row">
    <div class="form-group mb-2 col-12 col-md-6">
      <input v-model="date" type="date" class="form-control" name="date" />
    </div>
    <div class="form-group mb-2 col-12 col-md-6">
      <input v-model="time" type="time" class="form-control" name="time" />
    </div>
  </div>
</template>
