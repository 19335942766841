import { Feature, Map, getUid } from 'ol';
import BaseObject from 'ol/Object';
import { Geometry, LineString, Polygon } from 'ol/geom';
import {
  DragPan,
  DragRotate,
  MouseWheelZoom,
  PinchRotate,
  PinchZoom,
} from 'ol/interaction';
import { Code, throwError } from '../common/error';
import type { StoreApi } from '../common/store-api';
import { getStoreApi } from '../common/store-api';
import type { Id } from '../common/types';
import {
  Event,
  FeatureCreatedEventPayload,
  PostFeatureCreatedEventPayload,
} from '../event/types';
import LayerManager from '../layer/LayerManager';
import type { Shape } from '../layer/shape/types';
import type { Layer } from '../layer/types';
import { LayerType } from '../layer/types';
import { getMaxZIndex, getModelId, getType } from '../layer/utils';
import createCircleEdit from './createCircleEdit';
import createDraw from './createDraw';
import createPoiEdit from './createPoiEdit';
import createPolyEdit from './createPolyEdit';
import createSampleEdit from './createSampleEdit';
import createSquareEdit from './createSquareEdit';

export default class InteractionManager extends BaseObject {
  layerManager: LayerManager;
  session: any;

  constructor(layerManager: LayerManager) {
    super();
    this.layerManager = layerManager;

    this.addEventListener(
      'feature-created',
      // @ts-expect-error matt
      this.handleFeatureCreated.bind(this)
    );
  }

  getMap(): Map {
    return this.layerManager.map;
  }

  getStoreApi(): StoreApi {
    return getStoreApi(this.getMap());
  }

  setActiveOfInteractions(isActive: boolean): void {
    const map = this.layerManager.map;
    const interactions = map.getInteractions();
    interactions.forEach((interaction) => {
      if (
        interaction instanceof DragPan ||
        interaction instanceof MouseWheelZoom ||
        interaction instanceof DragRotate ||
        interaction instanceof PinchRotate ||
        interaction instanceof PinchZoom
      ) {
        return;
      }

      interaction.setActive(isActive);
    });
  }

  /**
   * Begin an interaction session which is used to draw and edit features
   * on the map.
   */
  beginSession(data: any = {}) {
    if (this.session) {
      throwError(Code.InteractionSessionAlreadyExisted);
    }

    this.session = {
      // Draw is used to draw a shape.
      draw: null,
      // Edit is used to modify a shape.
      editsByLayerUid: {},
      data,
    };

    this.setActiveOfInteractions(false);
  }

  hasSession() {
    return !!this.session;
  }

  getData() {
    if (!this.hasSession()) {
      throwError(Code.NoInteractionSession);
    }

    return this.session.data;
  }

  /**
   * End the interaction session.
   */
  endSession() {
    if (!this.session) {
      throwError(Code.NoInteractionSession);
    }

    this.endDraw();

    const { editsByLayerUid } = this.session;
    const keys = Object.keys(editsByLayerUid);
    if (keys.length > 0) {
      for (const key of keys) {
        this._removeEdits(key);
      }
    }

    this.session = null;

    this.getStoreApi().clearTempLayerModels!();
    this.setActiveOfInteractions(true);
  }

  requestDraw(options) {
    if (!this.session) {
      throwError(Code.InteractionSessionRequired);
    }
    if (this.isDrawing()) {
      throwError(Code.DrawAlreadyExisted);
    }

    const map = this.getMap();
    this.session.draw = createDraw(this, options);
    map.addInteraction(this.session.draw);

    return this.session.draw;
  }

  endDraw() {
    if (!this.session) {
      throwError(Code.NoInteractionSession);
    }

    const { draw } = this.session;
    if (draw) {
      this.getMap().removeInteraction(draw);
    }
    this.session.draw = null;
  }

  getDraw() {
    return this.session.draw;
  }

  isDrawing() {
    return this.session?.draw?.getActive();
  }

  isEditing() {
    return Object.keys(this.session?.editsByLayerUid || {}).length > 0;
  }

  isLayerBeingEdited(layer) {
    return !!this.session?.editsByLayerUid[getUid(layer)];
  }

  _addEdit(layer: Layer, edit) {
    const key = getUid(layer);
    if (!this.session.editsByLayerUid[key]) {
      this.session.editsByLayerUid[key] = [];
    }
    this.session.editsByLayerUid[key].push(edit);
    edit.layer = layer;
    edit.activate(this.getMap());
    return edit;
  }

  _removeEdits(key: string) {
    const edits = this.session.editsByLayerUid[key];
    delete this.session.editsByLayerUid[key];
    edits.forEach((edit) => {
      edit.destroy(this.getMap());
    });
  }

  /**
   * Request an edit.
   * @param {ol.layer.Layer} layer
   * @param {ol.Feature} feature The feature must be specified for sample layers.
   * @param {Boolean} isForNewFeature A bool value used to represent whether the edit is used for
   *                                a newly created object or an existing object.
   * @returns
   */
  requestEdit(feature: Feature<Geometry>, isForNewFeature = true) {
    if (!this.session) {
      throwError(Code.InteractionSessionRequired);
    }

    const layer = this.layerManager.findLayerByFeature(feature);
    const layerType = getType(layer);

    if (layerType !== LayerType.SAMPLE && this.isLayerBeingEdited(layer)) {
      const layerModelId = getModelId(layer);
      throwError(Code.LayerBeingEdited, layerModelId);
    }

    if ([LayerType.RECTANGLE, LayerType.HEDGE].includes(layerType)) {
      return this._addEdit(layer, createSquareEdit(this));
    } else if (layerType === LayerType.CIRCLE) {
      return this._addEdit(layer, createCircleEdit(this));
    } else if (
      [
        LayerType.POLYGON,
        LayerType.SITE_BOUNDARY,
        LayerType.POLYLINE,
        LayerType.ARROW,
      ].includes(layerType)
    ) {
      return this._addEdit(layer, createPolyEdit(this, layerType));
    } else if (layerType === LayerType.SAMPLE) {
      const storeApi = this.getStoreApi();
      const sample = storeApi.findSampleById(feature.getId()! as Id)!;
      const edit = createSampleEdit(this, sample, isForNewFeature);
      return this._addEdit(layer, edit);
    } else {
      throwError(Code.LayerTypeNotSupported, layerType);
    }
  }

  requestPoiEdit(feature: Feature<Polygon> | Feature<LineString>) {
    if (!this.session) {
      throwError(Code.InteractionSessionRequired);
    }

    const layer = this.layerManager.findLayerByFeature(feature);
    return this._addEdit(layer, createPoiEdit(this));
  }

  // For layers which have only one feature, e.g. Square, Circle, use this method.
  getEdit(layer: Layer) {
    const edits = this.getEdits(layer);
    return edits ? edits[0] : null;
  }

  getEdits(layer: Layer) {
    if (!this.session) {
      throwError(Code.InteractionSessionRequired);
    }

    return this.session.editsByLayerUid[getUid(layer)];
  }

  // For sample layers.
  findSampleEdit(layer: Layer, sampleId: Id) {
    return this.getEdits(layer)?.find(
      (edit) => edit.getFeature().getId() === sampleId
    );
  }

  changeEditsKey(oldKey, newKey) {
    if (!this.session) {
      throwError(Code.InteractionSessionRequired);
    }

    const edits = this.session.editsByLayerUid[oldKey];
    delete this.session.editsByLayerUid[oldKey];
    this.session.editsByLayerUid[newKey] = edits;
  }

  isFeatureBeingEdited(feature: Feature<Shape>) {
    const layer = this.layerManager.findLayerByFeature(feature);
    return layer && this.isLayerBeingEdited(layer);
  }

  checkIsPolyEditWorking() {
    if (!this.isEditing()) {
      return false;
    }

    const { editsByLayerUid } = this.session;
    const keys = Object.keys(editsByLayerUid);
    if (keys.length !== 1) {
      return false;
    }

    const [uid] = keys;
    const layer = this.layerManager.findLayerByUid(uid);
    if (
      !layer ||
      ![
        LayerType.POLYGON,
        LayerType.RECTANGLE,
        LayerType.CIRCLE,
        LayerType.SITE_BOUNDARY,
        LayerType.HEDGE,
        LayerType.POLYLINE,
        LayerType.ARROW,
        LayerType.CALL_OUT,
      ].includes(getType(layer))
    ) {
      return false;
    }

    const [edit] = editsByLayerUid[uid];
    return edit.checkIsWorking();
  }

  private handleFeatureCreated(evt: Event<FeatureCreatedEventPayload>) {
    const { layer, activateEdit } = evt.payload;

    layer.setZIndex(getMaxZIndex());
    this.layerManager.addLayer(layer);

    if (activateEdit) {
      const feature = layer.getFirstFeature()!;
      const edit = this.requestEdit(feature, !feature.get('isDuplicate'));

      edit.selectFeature(feature);

      if (getType(layer) === LayerType.SAMPLE) {
        this.layerManager.hideFeature(feature);
      }
    }

    this.dispatchEvent(
      new Event<PostFeatureCreatedEventPayload>('post-feature-created', {
        layer,
      })
    );
  }

  getAllEditStates() {
    const result = {};

    if (this.session) {
      const { editsByLayerUid } = this.session;
      const keys = Object.keys(editsByLayerUid);
      for (const key of keys) {
        const edits = editsByLayerUid[key];
        result[key] = edits.map((edit) => edit.getState());
      }
    }

    return result;
  }
}

export function enableInteractions(map) {
  map.getInteractions().forEach((interaction) => interaction.setActive(true));
}

export function disableInteractions(map) {
  map.getInteractions().forEach((interaction) => interaction.setActive(false));
}
