<script setup lang="ts">
import Pagination from '@/js/components/Pagination.vue';
import useMapsApi from '@/js/composables/useMapsApi';
import {
  findAppByFieldId,
  findAppById,
  getLinkConfigs,
} from '@component-library/business-logic/app';
import type {
  App,
  AppLinkConfig,
  AppLinkConfigContext,
  Item,
  ProjectPhase,
} from '@component-library/gather';
import type { Sample } from '@maps/lib/olbm/layer/sample/types';
import { produce } from 'immer';
import { computed, provide, ref, watch } from 'vue';
import LinkableItem from './LinkableItem.vue';
import LinkedApp from './LinkedApp.vue';
import LinkedAppCopier from './LinkedAppCopier.vue';

type Group = {
  id: string;
  linkedApp: App;
  linkFieldId: number;
  maxNumberOfItems: number;
};

type GroupState = {
  isExpanded: boolean;
  isCopierVisible: boolean;
  pagination: {
    current_page: number;
    last_page: number;
    linkedItems: Sample[];
    linkingPendingItems: Sample[];
  };
  allLinkedItemsCount: number;
};

const props = defineProps<{
  appId: number;
  apps: App[];
  item: Item;
  hasChangedInputValues: boolean;
  iconButtonContainer?: string;
  phases: ProjectPhase[];
}>();

const mapsApi = useMapsApi();
const app = computed<App>(() => {
  return findAppById(props.apps, props.appId)!;
});

const hasChangedInputValues = ref<boolean>(props.hasChangedInputValues);

provide<AppLinkConfigContext>('appLinkConfigContext', {
  app: app.value,
  item: props.item,
  hasChangedInputValues,
  iconButtonContainer: props.iconButtonContainer,
});

const linkConfigs = computed<AppLinkConfig[]>(() => {
  return getLinkConfigs(app.value);
});

const groups = computed<Group[]>(() => {
  return linkConfigs.value
    .reduce((accu, lc) => {
      const { linkFieldId, maxNumberOfItems } = lc;
      const linkedApp = findAppByFieldId(props.apps, linkFieldId);
      if (linkedApp) {
        const group: Group = {
          id: `group-${linkedApp.id}`,
          linkedApp,
          linkFieldId,
          maxNumberOfItems,
        };
        accu.push(group);
        return accu;
      }
      return accu;
    }, [] as Group[])
    .filter((g) =>
      g.linkedApp.app_phase_group_id !== null
        ? props.phases.find((p) => p.id === g.linkedApp.app_phase_group_id)
            ?.is_visible
        : true
    );
});

const groupStateById = ref<Record<string, GroupState>>(
  groups.value.reduce((accu, group) => {
    accu[group.id] = {
      isExpanded: true,
      isCopierVisible: false,
      pagination: {
        current_page: 1,
        last_page: 1,
        linkedItems: [],
        linkingPendingItems: [],
      },
      allLinkedItemsCount: 0,
    };
    return accu;
  }, {} as Record<string, GroupState>)
);

const getLinkedItems = (samples: Sample[], group: Group): Sample[] => {
  const { linkedApp, linkFieldId } = group;
  return samples.filter(
    (s) =>
      s.template_tab_id === linkedApp.id &&
      s.input_values_for_linking.some(
        (iv) =>
          iv.template_tab_id === linkedApp.id &&
          iv.template_field_id === linkFieldId &&
          // The type of id could be either string or number so use == here.
          iv.value == props.item.id
      )
  );
};

const getLinkingPendingItems = (samples: Sample[], group: Group): Sample[] => {
  const { linkedApp, linkFieldId } = group;
  return samples.filter(
    (s) =>
      s.template_tab_id === linkedApp.id &&
      !s.input_values_for_linking.some(
        (iv) =>
          iv.template_tab_id === linkedApp.id &&
          iv.template_field_id === linkFieldId
      )
  );
};

async function loadSamples(group: Group, page: number) {
  const { linkFieldId } = group;
  const {
    pagination: { current_page, last_page, data },
    allLinkedItemsCount,
  } = await mapsApi.loadLinkableSamples(
    props.item.id ?? null,
    linkFieldId,
    page
  );
  groupStateById.value = produce(groupStateById.value, (draft) => {
    draft[group.id].pagination = {
      current_page,
      last_page,
      linkedItems: getLinkedItems(data, group),
      linkingPendingItems: getLinkingPendingItems(data, group),
    };
    draft[group.id].allLinkedItemsCount = allLinkedItemsCount;
  });
}

function setLinkedAppCopierVisible(group: Group, value: boolean) {
  groupStateById.value = produce(groupStateById.value, (draft) => {
    draft[group.id].isCopierVisible = value;
  });
}

function handleLinkedAppClick(group: Group) {
  groupStateById.value = produce(groupStateById.value, (draft) => {
    const { isExpanded } = draft[group.id];
    draft[group.id].isExpanded = !isExpanded;
  });
}

function handleLinkedAppCopy(group: Group) {
  setLinkedAppCopierVisible(group, true);
}

function handlePaginate({ group, page }: { group: Group; page: number }) {
  loadSamples(group, page);
}

function handleLinkedAppCopierClose(group: Group) {
  setLinkedAppCopierVisible(group, false);
}

async function handleLinkedAppCopied(group: Group) {
  await loadSamples(group, 1);
  setLinkedAppCopierVisible(group, false);
}

watch(
  () => props.hasChangedInputValues,
  (value) => {
    hasChangedInputValues.value = value;
  }
);

watch(
  () => props.appId,
  () => {
    for (let i = 0; i < groups.value.length; i++) {
      loadSamples(groups.value[i], 1);
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div>
    <div
      v-if="groups.length > 0"
      class="d-flex mb-2 gap-2 linkable-items-legend"
    >
      <div class="d-flex align-items-center">
        <div class="me-2 linkable-item linked-item"></div>
        <small>Linked Item</small>
      </div>
      <div class="d-flex align-items-center">
        <div class="me-2 linkable-item linking-pending-item"></div>
        <small>Linking Pending Item</small>
      </div>
    </div>

    <div
      v-for="(group, index) in groups"
      :key="group.id"
      :class="{ 'mb-1': index < groups.length - 1 }"
    >
      <LinkedApp
        :linkedApp="group.linkedApp"
        :isExpanded="groupStateById[group.id].isExpanded"
        :isCopierVisible="groupStateById[group.id].isCopierVisible"
        :numberOfItems="groupStateById[group.id].allLinkedItemsCount"
        :maxNumberOfItems="group.maxNumberOfItems"
        @click="handleLinkedAppClick(group)"
        @copy="handleLinkedAppCopy(group)"
      />

      <div v-if="groupStateById[group.id].isCopierVisible" class="p-2">
        <div class="card">
          <div
            class="card-header bg-primary text-white py-3 d-flex justify-content-between"
          >
            <div>Copy</div>
            <div
              class="close clickable"
              @click="handleLinkedAppCopierClose(group)"
            >
              <i class="fas fa-times"></i>
            </div>
          </div>
          <LinkedAppCopier
            v-if="groupStateById[group.id].isExpanded"
            class="card-body"
            :item="item"
            :app="app"
            :linkedApp="group.linkedApp"
            @copied="handleLinkedAppCopied(group)"
          />
        </div>
      </div>

      <div
        v-if="
          groupStateById[group.id].isExpanded &&
          (groupStateById[group.id].pagination.linkedItems.length > 0 ||
            groupStateById[group.id].pagination.linkingPendingItems.length > 0)
        "
        class="pt-2 px-2"
      >
        <div
          :class="[
            'd-flex justify-content-end',
            { 'mb-3': groupStateById[group.id].pagination.last_page > 1 },
          ]"
        >
          <Pagination
            :pagination="{
              current_page: groupStateById[group.id].pagination.current_page,
              last_page: groupStateById[group.id].pagination.last_page,
            }"
            @paginate="(page) => handlePaginate({ group, page })"
          />
        </div>
        <div
          :class="{
            'mb-3':
              groupStateById[group.id].pagination.linkingPendingItems.length >
              0,
          }"
        >
          <LinkableItem
            v-for="(li, index) in groupStateById[group.id].pagination
              .linkedItems"
            :key="`linkedItem-${li.id}`"
            :class="{
              'mb-1':
                index <
                groupStateById[group.id].pagination.linkedItems.length - 1,
            }"
            :item="li"
            :isLinked="true"
          />
        </div>
        <div>
          <LinkableItem
            v-for="(li, index) in groupStateById[group.id].pagination
              .linkingPendingItems"
            :key="`linkedItem-${li.id}`"
            :class="{
              'mb-1':
                index <
                groupStateById[group.id].pagination.linkingPendingItems.length -
                  1,
            }"
            :item="li"
            :isLinked="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.linkable-items-legend {
  .linkable-item {
    width: 16px;
    height: 16px;
  }

  .linked-item {
    background-color: var(--linked-item-bg-color);
  }

  .linking-pending-item {
    background-color: var(--linking-pending-item-bg-color);
  }
}
</style>
