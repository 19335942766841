<script setup lang="ts">
import useFigureStore from '@/js/stores/figure';
import type { Id } from '@maps/lib/olbm/common/types';
import type { Ref } from 'vue';
import { computed, inject } from 'vue';

const props = defineProps<{
  layerModelId: Id;
}>();

const emit = defineEmits(['edit', 'duplicate', 'delete']);

const figureStore = useFigureStore();
const { getLockedByFigure } = figureStore;
const isLayerLocked = computed(() =>
  typeof props.layerModelId === 'number'
    ? !!getLockedByFigure(props.layerModelId)
    : false
);

const map: Ref<any> = inject('map')!;

const isEditAvailable = computed(() => {
  return !!map.value.findLayerModelById(props.layerModelId);
});

const isDuplicateAvailable = computed(() => {
  return !!map.value.findLayerModelById(props.layerModelId);
});

const isDeleteAvailable = computed(() => {
  return !!map.value.findLayerModelById(props.layerModelId);
});

function handleEdit() {
  emit('edit', props.layerModelId);
}

function handleDuplicate() {
  emit('duplicate', props.layerModelId);
}

function handleDelete() {
  emit('delete', props.layerModelId);
}
</script>

<template>
  <!-- Edit -->
  <button
    v-if="isEditAvailable"
    type="button"
    class="btn btn-outline-secondary"
    :disabled="isLayerLocked"
    @click="handleEdit"
  >
    <i class="fal fa-arrows-alt" />
  </button>
  <!-- Duplicate -->
  <button
    v-if="isDuplicateAvailable"
    type="button"
    class="btn btn-outline-secondary"
    @click="handleDuplicate"
  >
    <i class="fal fa-copy" />
  </button>
  <!-- Delete -->
  <button
    v-if="isDeleteAvailable"
    type="button"
    class="btn btn-outline-danger"
    :disabled="isLayerLocked"
    @click="handleDelete"
  >
    <i class="fal fa-trash-alt" />
  </button>
</template>
