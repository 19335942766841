import { Map } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorLayer } from 'ol/layer';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { get as getProjection, transformExtent } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { createXYZ } from 'ol/tilegrid';
import { Code, throwError } from '../../../common/error';
import {
  intersectsWithoutEdgeOverlap,
  mergeExtents,
  normalizeExtent,
} from '../../../common/extent';
import { createRequestOptions, proxify } from '../../../common/network';
import { Color } from '../../../style/color';
import createShapeStyle from '../../../style/createShapeStyle';
import type { LayerUsage } from '../../constants';
import { LayerType } from '../../types';
import { createLayerProperties, getVisibleExtent } from '../../utils';
import enableLoadingEvents from '../enableLoadingEvents';
import type { WfsFolderLayerModel, WfsItemLayerModel, WfsLayer } from './types';

declare const axios: any;

const SUPPORTED_OUTPUT_FORMATS = [
  'application/json',
  'application/vnd.geo+json',
];

export async function getServiceData(serviceUrl, shouldUseCorsProxy) {
  const url = !shouldUseCorsProxy ? serviceUrl : proxify(serviceUrl);
  const { data } = await axios.get(url, createRequestOptions());
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, 'application/xml');
  const nsResolver = doc.createNSResolver(doc.documentElement);

  const version = getText(
    doc,
    '//ows:ServiceIdentification/ows:ServiceTypeVersion/text()',
    doc,
    nsResolver
  );
  const title = getText(
    doc,
    '//ows:ServiceIdentification/ows:Title/text()',
    doc,
    nsResolver
  );

  const featureTypeListNode = doc.evaluate(
    '//wfs:FeatureTypeList/wfs:FeatureType',
    doc,
    nsResolver,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null
  );
  const featureTypes: any = [];
  let featureTypeNode;
  while ((featureTypeNode = featureTypeListNode.iterateNext())) {
    const featureType = getFeatureType(doc, featureTypeNode, nsResolver);
    featureTypes.push(featureType);
  }

  const layers = featureTypes.map((featureType) => ({
    id: featureType.name,
    name: featureType.title,
    crs: featureType.crs,
    extent: normalizeExtent(featureType.extent),
    outputFormat: featureType.outputFormat,
    parentLayerId: -1,
    subLayerIds: null,
  }));

  const extents = layers.map((l) => l.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    url: serviceUrl,
    attributions: '',
    version,
    title,
    description: title,
    layers,
    fullExtent,
  };
}

export default function createLayer(
  map: Map,
  folderModel: WfsFolderLayerModel,
  itemModel: WfsItemLayerModel,
  layerType: LayerType = LayerType.BASEMAP_SERVICE,
  layerUsage?: LayerUsage
): WfsLayer {
  const { attributions, version, shouldUseCorsProxy } =
    folderModel.geojson.properties.service;
  const { id, crs, outputFormat } = itemModel.geojson.properties;

  const projection = map.getView().getProjection();
  const visibleExtent = getVisibleExtent(folderModel, projection);

  const format = new GeoJSON({
    dataProjection: crs,
    featureProjection: projection,
  });
  // @ts-expect-error - The readProjectionFromObject method is not exposed.
  const readProjectionFromObjectNative = format.readProjectionFromObject;
  // Hacked because crs of type string can't be recognized.
  // See the response from https://opendata.ccc.govt.nz/Park/service.svc/get?request=GetCapabilities&SERVICE=WFS.
  // @ts-expect-error - The readProjectionFromObject method is not exposed.
  format.readProjectionFromObject = function (object) {
    if (typeof object.crs !== 'object') {
      delete object.crs;
    }
    return readProjectionFromObjectNative.call(this, object);
  };

  const layerSource = new VectorSource({
    attributions,
    loader: function (extent, resolution, projection, onLoad, onError) {
      if (!intersectsWithoutEdgeOverlap(visibleExtent, extent)) {
        onLoad!([]);
        return;
      }

      const targetExtent = transformExtent(extent, projection, crs);
      const url = new URL(folderModel.geojson.properties.service.url);
      const query = new URLSearchParams({
        request: 'GetFeature',
        service: 'WFS',
        version,
        typenames: id,
        outputFormat,
        srsname: crs,
        bbox: `${targetExtent.join(',')},${crs}`,
      });
      for (let entry of query.entries()) {
        url.searchParams.set(entry[0], entry[1]);
      }
      let getFeatureUrl = url.toString();
      if (shouldUseCorsProxy) {
        getFeatureUrl = proxify(getFeatureUrl);
      }

      axios
        .get(getFeatureUrl, createRequestOptions())
        .then(({ data }) => {
          const features = format.readFeatures(data, {
            featureProjection: projection,
          });
          features.forEach((feature) => {
            // @ts-expect-error - The id_ property is not exposed.
            if (!layerSource.getFeatureById(feature.id_)) {
              layerSource.addFeature(feature);
            }
          });
          onLoad!(features);
        })
        .catch(() => {
          onError!();
        });
    },
    strategy: tileStrategy(
      createXYZ({
        extent: visibleExtent,
        tileSize: 256,
        maxResolution: map.getView().getMaxResolution(),
        maxZoom: map.getView().getMaxZoom(),
      })
    ),
  });

  const layer = new VectorLayer({
    source: layerSource,
    extent: visibleExtent,
    style: createShapeStyle(map, (feature) => {
      let properties = itemModel.geojson.properties.renderer?.properties;

      if (!properties) {
        let options;
        const gt = feature.getGeometry()!.getType();
        if (['Point', 'MultiPoint'].includes(gt)) {
          options = {
            type: LayerType.POINT,
            icon: 0,
            iconSize: 22,
          };
        } else if (['LineString', 'MultiLineString'].includes(gt)) {
          options = {
            type: LayerType.POLYLINE,
          };
        } else if (['Polygon', 'MultiPolygon'].includes(gt)) {
          options = {
            type: LayerType.POLYGON,
          };
        } else {
          throwError(Code.GeometryTypeNotSupported, gt);
        }
        properties = {
          ...options,
          isForServiceLayer: true,
          title: itemModel.geojson.properties.name,
          color: Color.Black,
          fillStyle: 0,
          outlineStyle: 0,
        };
      }

      return properties!;
    }),
    properties: createLayerProperties(itemModel.id, layerType, layerUsage),
  }) as WfsLayer;

  layer.getFirstFeature = function () {
    return this.getSource()!.getFeatures()[0];
  };

  layer.checkHasFeature = function (feature) {
    return this.getSource()!.hasFeature(feature);
  };

  layer.toGeoJSON = function () {
    throwError(Code.MethodNotImplemented, 'toGeoJSON');
  };

  layer.getBounds = function () {
    throwError(Code.MethodNotImplemented, 'getBounds');
  };

  layer.refresh = function () { };

  enableLoadingEvents(layer);

  return layer;
}

function getText(doc, textXPath, contextNode, nsResolver) {
  const { stringValue } = doc.evaluate(
    textXPath,
    contextNode,
    nsResolver,
    XPathResult.STRING_TYPE,
    null
  );
  return stringValue;
}

function getOutputFormats(doc, nsResolver, contextNode, outputFormatTextXPath) {
  const outputFormats: any[] = [];
  const outputFormatTextNodes = doc.evaluate(
    outputFormatTextXPath,
    contextNode,
    nsResolver,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null
  );
  let outputFormatTextNode;
  while ((outputFormatTextNode = outputFormatTextNodes.iterateNext())) {
    const { wholeText: outputFormat } = outputFormatTextNode;
    outputFormats.push(outputFormat);
  }
  return outputFormats;
}

function getOutputFormatsOfGetFeature(doc, nsResolver) {
  const { singleNodeValue: getFeatureNode } = doc.evaluate(
    `//ows:OperationsMetadata/ows:Operation[@name='GetFeature']`,
    doc,
    nsResolver,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  );
  return getOutputFormats(
    doc,
    nsResolver,
    getFeatureNode,
    `ows:Parameter[@name='outputFormat']/ows:AllowedValues/ows:Value/text()`
  );
}

function getOutputFormatsOfFeatureType(doc, nsResolver, featureTypeNode) {
  return getOutputFormats(
    doc,
    nsResolver,
    featureTypeNode,
    'wfs:OutputFormats/wfs:Format/text()'
  );
}

function getFeatureType(doc, featureTypeNode, nsResolver) {
  const name = getText(doc, 'wfs:Name', featureTypeNode, nsResolver);
  const title = getText(doc, 'wfs:Title', featureTypeNode, nsResolver);

  let crs = getText(doc, 'wfs:DefaultCRS', featureTypeNode, nsResolver);
  crs = getProjection(crs)!.getCode();

  let outputFormats = getOutputFormatsOfFeatureType(
    doc,
    nsResolver,
    featureTypeNode
  );
  if (!outputFormats.length) {
    outputFormats = getOutputFormatsOfGetFeature(doc, nsResolver);
  }
  const outputFormat = SUPPORTED_OUTPUT_FORMATS.find(
    (item) => outputFormats.indexOf(item) !== -1
  );
  if (!outputFormat) {
    throw new Error(
      `The service doesn't support the ${outputFormat} output format.`
    );
  }

  const lowerCornerText = getText(
    doc,
    'ows:WGS84BoundingBox/ows:LowerCorner/text()',
    featureTypeNode,
    nsResolver
  );
  const upperCornerText = getText(
    doc,
    'ows:WGS84BoundingBox/ows:UpperCorner/text()',
    featureTypeNode,
    nsResolver
  );
  const extent = [
    ...lowerCornerText.split(/\s+/).map(parseFloat),
    ...upperCornerText.split(/\s+/).map(parseFloat),
  ];

  return {
    name,
    title,
    crs,
    extent,
    outputFormat,
  };
}
