<script lang="ts" setup>
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import { ref } from 'vue';
import Section from '@component-library/classes/Section';

defineProps<{
  section: Section;
  mobileSize: boolean;
}>();

const emit = defineEmits<{
  (event: 'clearSection'): void;
}>();

const isExpanded = ref(true);

function handleClose() {
  emit('clearSection');
}
</script>

<template>
  <ResponsiveOrSlideUp
    v-if="section"
    v-model="isExpanded"
    title="Section Options"
    :mobileSize="mobileSize"
    @close="handleClose"
  >
    <template #body>
      <div class="alert alert-primary" role="alert">
        This section is not configurable.
      </div>
    </template>
  </ResponsiveOrSlideUp>
</template>
