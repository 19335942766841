<script setup lang="ts">
import type { App } from '@component-library/gather';
import { useFigureStore } from '@component-library/store/figures';
import type { Figure } from '@maps/lib/olbm/types';
import { computed } from 'vue';

const props = defineProps<{ app: App }>();
const emit = defineEmits<{
  (event: 'change', value: number[]): void;
}>();

const CURRENT_FIGURE_ID = 0;

const { projectFigures: figures } = useFigureStore();
const gatherFigure = computed<Figure | undefined>(() => {
  return (figures as unknown as Figure[]).find((f) => f.gather_access);
});
const otherFigures = computed<Figure[]>(() => {
  return (figures as unknown as Figure[]).filter((f) => !f.gather_access);
});
const selectedFigureIds = computed<number[]>({
  get() {
    return props.app.default_figure_ids;
  },
  set(value) {
    emit('change', value);
  },
});
</script>

<template>
  <div>
    <label class="form-label"> Default Figures Visible </label>
    <ul class="list-group">
      <li class="list-group-item">
        <label class="form-check-label stretched-link fw-bold">
          <input
            v-model.number="selectedFigureIds"
            type="checkbox"
            class="form-check-input me-1"
            :value="CURRENT_FIGURE_ID"
          />
          Current figure
        </label>
      </li>

      <li class="list-group-item">
        <label class="form-check-label stretched-link fw-bold">
          <input
            type="checkbox"
            class="form-check-input me-1"
            checked
            disabled
          />
          {{ gatherFigure ? gatherFigure.title : 'Gather Figure' }}
        </label>
      </li>

      <li
        v-for="f in otherFigures"
        :key="`figure-${f.id}`"
        class="list-group-item"
      >
        <label class="form-check-label stretched-link">
          <input
            v-model.number="selectedFigureIds"
            type="checkbox"
            class="form-check-input me-1"
            :value="f.id"
          />
          {{ f.title }}
        </label>
      </li>
    </ul>
  </div>
</template>
