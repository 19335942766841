<script setup lang="ts">
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import Modal from '@component-library/components/Modal.vue';
import FillPattern from '@component-library/components/FillPattern.vue';

const ExcelWorksheetReader = defineAsyncComponent({
  loader: () => import('../../ExcelWorksheetReader.vue'),
});

const ewr = ref();

const emit = defineEmits(['close', 'import']);

type FillPatternValue = 1 | 2 | 3 | 4 | 5;

export type LithologyOption = {
  name: string;
  color: string;
  fillPatternValue: FillPatternValue;
};

function parseColor(color: string): string {
  return color.match(/^#[\da-fA-F]{6}$/) ? color : '#000000';
}

function parseFillPatternValue(fpv: string): FillPatternValue {
  return (
    ['1', '2', '3', '4', '5'].includes(fpv) ? parseInt(fpv, 10) : 1
  ) as FillPatternValue;
}

const lithologyOptions = computed<LithologyOption[]>(() => {
  return (
    ewr.value?.dataRows.reduce((accu, dr) => {
      const name = dr[0];
      const color = parseColor(dr[1]);
      const fillPatternValue = parseFillPatternValue(dr[2]);

      if (name?.trim()) {
        accu.push({ name, color, fillPatternValue });
      }
      return accu;
    }, [] as LithologyOption[]) ?? []
  );
});

function handleClose() {
  emit('close');
}

function handleImport() {
  emit('import', lithologyOptions.value);
}

watch(lithologyOptions, (newValue, oldValue) => {
  if (newValue.length > 0 && oldValue.length === 0) {
    ewr.value!.setIsExpanded(false);
  }
});
</script>

<template>
  <Modal
    class="import-lithology-options-modal"
    :class="{
      'import-lithology-options-modal-with-rows': lithologyOptions.length > 0,
    }"
    :show="true"
    :half="true"
    @close="handleClose"
  >
    <template #header>Import lithology options from an Excel file</template>

    <template #default>
      <ExcelWorksheetReader
        ref="ewr"
        class="flex-grow-1 d-flex flex-column"
        :style="{ 'min-height': '0px', overflow: 'auto' }"
        :info="`Only the first 3 columns which are <b>Name</b>, <b>Colour</b> and
          <b>Fill Pattern</b> from the first worksheet are available for importing.
          <br/>
          <br/>
          The columns in the first row are headers so that they will not be used as options.
          <br/>
          <br/>
          The colour is in hexadecimal format, e.g. #000000. The available values for the
          fill pattern are:
          <ul>
            <li>1(Solid)</li>
            <li>2(Transparent)</li>
            <li>3(Horizontal Stripe)</li>
            <li>4(Vertical Stripe)</li>
            <li>5(Dot)</li>
          </ul>
          <br/>
          An example data row is:
          <br/>
          Clay #000000 1`"
      >
        <template #worksheetContainer>
          <div
            v-if="lithologyOptions.length"
            class="flex-grow-1 d-flex flex-column mb-2"
            :style="{ 'min-height': '0px', overflow: 'auto' }"
          >
            <label class="form-label"
              >Lithology options ({{ lithologyOptions.length }})</label
            >
            <div class="flex-grow-1 table-container">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="header">Name</th>
                    <th class="header">Colour</th>
                    <th class="header">Fill Pattern</th>
                    <th class="header">Preview</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(lo, loIndex) in lithologyOptions"
                    :key="`lithologyOption-${loIndex}`"
                  >
                    <td>{{ lo.name }}</td>
                    <td>{{ lo.color }}</td>
                    <td>{{ lo.fillPatternValue }}</td>
                    <td>
                      <FillPattern
                        :value="lo.fillPatternValue"
                        :size="[32, 32]"
                        :color="lo.color"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </ExcelWorksheetReader>
    </template>
    <template #footer>
      <button class="btn btn-light me-2" @click="handleClose">Cancel</button>
      <button
        class="btn btn-primary"
        :disabled="!lithologyOptions.length"
        @click="handleImport"
      >
        Import
      </button>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.import-lithology-options-modal {
  .table-container {
    min-height: 0px;
    overflow: auto;
    cursor: pointer;

    .header {
      position: sticky;
      top: 0;
    }
  }

  &-with-rows {
    :deep(.modal-content) {
      max-height: 80%;
    }
  }
}
</style>
