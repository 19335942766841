<script setup lang="ts">
import Modal from '@/js/components/Modal.vue';
import { checkIsLookupDefault } from '@component-library/business-model/auto-assign';
import type { LookupDefault } from '@component-library/business-model/auto-assign';
import { computed } from 'vue';
import Field from '@component-library/classes/Field';

const props = defineProps<{
  field: Field;
  fields: Field[];
}>();
const emit = defineEmits(['close']);

const lookupDefault = computed<LookupDefault | undefined>(() => {
  return props.field.options?.defaults?.find((d) => checkIsLookupDefault(d)) as
    | LookupDefault
    | undefined;
});
const headers = computed<string[]>(() => {
  const sourceFieldLabels =
    lookupDefault.value?.sourceFieldIds.map((sfId) => {
      const field = props.fields.find((f) => f.id === sfId);
      return field?.label ?? '';
    }) ?? [];

  return [...sourceFieldLabels, props.field.label];
});

function checkIsTargetField(index: number): boolean {
  return index === headers.value.length - 1;
}

function handleClose() {
  emit('close');
}
</script>

<template>
  <Modal
    class="auto-assign-by-lookup-dictionary-modal"
    :show="true"
    :half="true"
    @close="handleClose"
  >
    <template #header>Dictionary</template>
    <div
      class="flex-grow-1 d-flex flex-column p-2"
      :style="{ 'min-height': '0px', overflow: 'auto' }"
    >
      <div
        class="flex-grow-1 d-flex flex-column mb-2"
        :style="{ 'min-height': '0px', overflow: 'auto' }"
      >
        <div class="flex-grow-1 table-container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="(h, hIndex) in headers"
                  :key="`th-${hIndex}`"
                  class="bg-primary text-center header"
                  :class="{
                    'bg-success': checkIsTargetField(hIndex),
                  }"
                  :title="
                    checkIsTargetField(hIndex) ? 'Target field' : 'Source field'
                  "
                  scope="col"
                >
                  {{ h }}
                  <div v-if="checkIsTargetField(hIndex)">
                    <i class="fas fa-bullseye"></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(entry, entryIndex) in lookupDefault?.dictionary ?? []"
                :key="`tr-${entryIndex}`"
              >
                <td
                  v-for="(item, itemIndex) in entry"
                  :key="`td${itemIndex}`"
                  class="bg-primary"
                  :class="{
                    'bg-success': checkIsTargetField(itemIndex),
                  }"
                  :style="{
                    '--bs-bg-opacity': 0.2,
                  }"
                >
                  {{ item }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn btn-primary me-2" @click="handleClose">Close</button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.auto-assign-by-lookup-dictionary-modal {
  .table-container {
    min-height: 0px;
    overflow: auto;

    .header {
      position: sticky;
      top: 0;
    }
  }

  :deep(.modal-content) {
    max-height: 80%;
  }
}
</style>
