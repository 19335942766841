<script>
export default {
  name: 'YearValueContentInput',
};
</script>

<script setup>
import { onMounted } from 'vue';

const year = defineModel({
  type: String,
  required: true,
  get(value) {
    return value ? parseInt(value, 10) : null;
  },
  set(value) {
    return String(value);
  },
});

const getYearRange = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = currentYear - 20; i < currentYear + 19; i++) {
    years.push(i);
  }
  return years;
};

onMounted(() => {
  const now = new Date();
  if (!year.value) {
    year.value = now.getFullYear();
  }
});
</script>

<template>
  <div class="form-group mb-2 d-flex flex-fill">
    <select v-model.number="year" class="form-control flex-fill">
      <option v-for="item of getYearRange()" :key="item" :value="item">
        {{ item }}
      </option>
    </select>
  </div>
</template>
