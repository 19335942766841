<script lang="ts" setup>
import {
  extendOption,
  getColor,
  getFillPattern,
  getOptionExtension,
} from '@component-library/business-logic/lithology';
import {
  FILL_PATTERN_DOT,
  FILL_PATTERN_HORIZONTAL_STRIPE,
  FILL_PATTERN_SOLID,
  FILL_PATTERN_TRANSPARENT,
  FILL_PATTERN_VERTICAL_STRIPE,
} from '@component-library/business-model/common';
import FillPattern from '@component-library/components/FillPattern.vue';
import FillPatternChooser from '@component-library/components/FillPatternChooser.vue';
import Tick from '@component-library/components/Tick.vue';
import {
  App,
  GatherField,
  GatherFieldOptions,
} from '@component-library/gather';
import makeId from '@component-library/local-id.mjs';
import _omit from 'lodash/omit';
import { computed, ref } from 'vue';
import Dropdown from './Dropdown.vue';
import ImportLithologyOptionsModal, {
  LithologyOption,
} from './ImportLithologyOptionsModal.vue';

const emit = defineEmits(['updateOptions']);

const colorInputId = makeId();
const props = defineProps<{
  value: GatherField;
  options: GatherFieldOptions;
  tab: App;
  field: GatherField;
}>();

// The updateOptions should not be invoked by the Dropdown field.
function takeOver(listeners) {
  return _omit(listeners, 'updateOptions');
}

function handleExtendOption(field, option, extension) {
  const nextOptions = extendOption(field, option, extension);
  emit('updateOptions', nextOptions);
}

const selectedOption = ref(null);
const color = computed({
  get() {
    return getColor(props.field, selectedOption.value);
  },
  set(value) {
    handleExtendOption(props.field, selectedOption.value, { color: value });
  },
});
const selectedFillPattern = computed({
  get() {
    return getFillPattern(props.field, selectedOption.value);
  },
  set(value) {
    handleExtendOption(props.field, selectedOption.value, {
      fillPattern: value,
    });
  },
});

const dropdownOptions = computed<GatherFieldOptions>(() => {
  return props.options;
});

function handleUpdateOptions(nextOptions) {
  const optionExtensions = nextOptions.options.reduce((accu, o) => {
    accu[o] = getOptionExtension(props.field, o);
    return accu;
  }, {});
  emit('updateOptions', { ...nextOptions, optionExtensions });
}

function handleSelectOption(value) {
  selectedOption.value = value;
}

function handleChangeFillPattern(value) {
  selectedFillPattern.value = value;
}

function handleImport(lithologyOptions: LithologyOption[], closeImportModal) {
  const { options, optionExtensions } = dropdownOptions.value;
  const differentLos = lithologyOptions.filter(
    (lo) => !(options as any[]).includes(lo.name)
  );
  const newOptions = [
    ...(options as any[]),
    ...differentLos.map((dlo) => dlo.name),
  ];
  const nextOptionExtensions = differentLos.reduce(
    (accu, dlo) => {
      const { color, fillPatternValue: fillPattern } = dlo;
      accu[dlo.name] = { color, fillPattern };
      return accu;
    },
    { ...optionExtensions }
  );
  emit('updateOptions', {
    ...dropdownOptions.value,
    options: newOptions,
    optionExtensions: nextOptionExtensions,
  });
  closeImportModal();
}

function handleImportModalClose(closeImportModal) {
  closeImportModal();
}
</script>

<template>
  <div>
    <Dropdown
      :options="dropdownOptions"
      :tab="tab"
      :value="value"
      :optionTypeVisible="false"
      :multipleVisible="false"
      :addOptionsVisible="false"
      :readOnlyVisible="false"
      v-on="takeOver($attrs)"
      @updateOptions="handleUpdateOptions"
      @selectOption="handleSelectOption"
    >
      <template #extensionContainer="{ setSelectedOption }">
        <div class="d-flex flex-column extension-container">
          <div
            v-for="(oe, option) in dropdownOptions.optionExtensions"
            :key="`option-${option}`"
          >
            <FillPattern
              :value="oe.fillPattern"
              :size="[24, 24]"
              :color="oe.color"
              :title="String(option)"
              @click.stop="setSelectedOption(option)"
            >
              <Tick v-if="option === selectedOption" />
            </FillPattern>
          </div>
        </div>
      </template>

      <template #importModal="{ isImportModalVisible, closeImportModal }">
        <ImportLithologyOptionsModal
          v-if="isImportModalVisible"
          @import="handleImport($event, closeImportModal)"
          @close="handleImportModalClose(closeImportModal)"
        />
      </template>
    </Dropdown>

    <div v-if="selectedOption" class="d-flex">
      <div class="d-flex flex-column">
        <div class="form-group mb-3">
          <label class="form-label" :for="colorInputId"> Colour</label>
          <input
            :id="colorInputId"
            v-model="color"
            class="form-control"
            type="color"
          />
        </div>

        <FillPatternChooser
          :fillPatterns="[
            FILL_PATTERN_SOLID,
            FILL_PATTERN_TRANSPARENT,
            FILL_PATTERN_HORIZONTAL_STRIPE,
            FILL_PATTERN_VERTICAL_STRIPE,
            FILL_PATTERN_DOT,
          ]"
          :selectedFillPattern="selectedFillPattern"
          @change="handleChangeFillPattern"
        />
      </div>

      <!-- Fill pattern preview -->
      <div class="flex-grow-1 d-flex justify-content-center align-items-center">
        <FillPattern
          :value="selectedFillPattern"
          :size="[72, 72]"
          :color="color"
        />
      </div>
    </div>
  </div>
</template>
