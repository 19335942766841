<script setup lang="ts">
import {
  STROKE_PATTERN_SOLID,
  STROKE_PATTERN_DASH,
  STROKE_PATTERN_DOT,
  STROKE_PATTERN_DATA,
} from '../business-model/common';

withDefaults(
  defineProps<{
    value?: number;
    title?: string;
    color?: string;
  }>(),
  {
    value: STROKE_PATTERN_SOLID,
    ...STROKE_PATTERN_DATA[STROKE_PATTERN_SOLID],
  }
);

const emit = defineEmits(['click']);
</script>

<template>
  <div
    :class="[
      'd-flex justify-content-center align-items-center stroke-pattern',
      {
        solid: value === STROKE_PATTERN_SOLID,
        dash: value === STROKE_PATTERN_DASH,
        dot: value === STROKE_PATTERN_DOT,
      },
    ]"
    :title="title"
    @click="emit('click', value)"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.stroke-pattern {
  cursor: pointer;
  width: 61px;
  height: 24px;
  border-radius: 6px;

  &.solid {
    background-image: url('../assets/images/stroke-patterns/solid.svg');
    background-size: contain;
  }

  &.dash {
    background-image: url('../assets/images/stroke-patterns/dash.svg');
    background-size: contain;
  }

  &.dot {
    background-image: url('../assets/images/stroke-patterns/dot.svg');
    background-size: contain;
  }
}
</style>
