import { default as turf_center } from '@turf/center';
import { produce } from 'immer';
import _cloneDeep from 'lodash/cloneDeep';
import _upperFirst from 'lodash/upperFirst';
import { Feature, Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { getIntersection } from 'ol/extent';
import { Point } from 'ol/geom';
import type { ProjectionLike } from 'ol/proj';
import { get as getProjection, transformExtent } from 'ol/proj';
import { Code, throwError } from '../common/error';
import { fromGeoJSON, toGeoJSON } from '../common/geojson';
import { StoreApi } from '../common/store-api';
import type { Extent, Id } from '../common/types';
import type { LayerUsage } from './constants';
import type { Service } from './service/types';
import type {
  Layer,
  LayerModel,
  LayerProperties,
  Properties,
  TempLayerModel,
} from './types';
import { LayerType } from './types';
import makeId from '@component-library/local-id.mjs';

export const MAXIMUM_NUMBER_OF_LAYERS = 1e4;

export function getMaxZIndex() {
  return MAXIMUM_NUMBER_OF_LAYERS - 1;
}

export function getType(layer: Layer): LayerType {
  return layer.get('type');
}

export function getUsage(layer: Layer): LayerUsage {
  return layer.get('usage');
}

export function getModelId(layer: Layer): Id {
  return layer.get('modelId');
}

export function getModel(
  storeApi: StoreApi,
  layer: Layer
): LayerModel | undefined {
  return storeApi.findLayerModelById(getModelId(layer));
}

export function checkIsPlainFolderLayerModel(model: LayerModel): boolean {
  const { properties } = model.geojson;
  return (
    properties.type === LayerType.FOLDER &&
    !('esri_type' in properties) &&
    !('service' in properties)
  );
}

export function createLayerProperties(
  modelId: Id,
  type: LayerType,
  usage?: LayerUsage
): LayerProperties {
  return Object.freeze({
    modelId,
    type,
    usage,
  });
}

export function getVisibleExtent(
  layerModel: LayerModel,
  projection: ProjectionLike
): Extent {
  let visibleExtent: Extent;

  if ('visibleExtent' in layerModel.geojson.properties) {
    visibleExtent = layerModel.geojson.properties.visibleExtent as Extent;
  } else if ('service' in layerModel.geojson.properties) {
    visibleExtent = (layerModel.geojson.properties.service as Service)
      .visibleExtent as Extent;
  } else {
    throwError(Code.InvalidLayerModel, layerModel.id);
  }

  const p = getProjection(projection)
  if (!p) {
    console.error('Invalid projection', projection);
    throw new Error('Invalid projection');
  }
  const pwe: Extent = p.getWorldExtent();

  return transformExtent(getIntersection(visibleExtent, pwe), 'EPSG:4326', p);
}

export function checkCanBeAssignedApp(layer: Layer): boolean {
  return [
    LayerType.RECTANGLE,
    LayerType.CIRCLE,
    LayerType.POLYGON,
    LayerType.POLYLINE,
    LayerType.ARROW,
    LayerType.SITE_BOUNDARY,
    LayerType.HEDGE,
    LayerType.SAMPLE,
  ].includes(getType(layer));
}

export function getLayerCenter(map: Map, layer: Layer): Coordinate {
  if (checkCanBeAssignedApp(layer)) {
    const features = toGeoJSON(map, layer.getSource().getFeatures());
    // @ts-expect-error - Turf doesnt understand the same type?
    const [centerFeature] = fromGeoJSON(map, turf_center(features)) as [
      Feature<Point>
    ];
    return centerFeature.getGeometry()!.getCoordinates();
  } else {
    throwError(Code.LayerTypeNotSupported, getType(layer));
  }
}

export function getMaxZoom(layerModel: LayerModel): number {
  let result;

  if ('service' in layerModel.geojson.properties) {
    const { maxZoom } = layerModel.geojson.properties.service as Service;
    result = maxZoom;
  }

  return result ?? 19;
}

export function checkIsTempLayerModel(
  layerModel: LayerModel
): layerModel is TempLayerModel {
  return 'isTemp' in layerModel && layerModel.isTemp === true;
}

export function getLayerTitle(layerModel: LayerModel) {
  const { title } = layerModel.geojson.properties;

  return (
    title ??
    _upperFirst(layerModel.geojson.properties.type.split(/[-_]/).join(' '))
  );
}

export type FindLayerModelsPredicate = (layerModel: LayerModel) => boolean;

export const findLayerModels = (
  layerModels: LayerModel[],
  predicate: FindLayerModelsPredicate
): LayerModel[] => {
  const result: LayerModel[] = [];

  for (let i = 0; i < layerModels.length; i++) {
    const layerModel = layerModels[i];

    if (predicate(layerModel)) {
      result.push(layerModel);
    }

    const resultsFromChildren = findLayerModels(layerModel.children, predicate);
    result.push(...resultsFromChildren);
  }

  return result;
};

export function updateLayerModel(
  layerModels: LayerModel[],
  layerModelId: Id,
  update: object
): LayerModel[] {
  return produce(layerModels, (draft) => {
    const [layerModel] = findLayerModels(
      draft,
      (layerModel) => layerModel.id === layerModelId
    );
    if (!layerModel) {
      throw `The layer model with id ${layerModelId} is not found.`;
    }
    for (const prop in update) {
      layerModel[prop] = update[prop];
    }
  });
}

export function getPropertiesByLayerModelId(
  layerModels: LayerModel[]
): Record<Id, Properties> {
  return layerModels.reduce((accu, layerModel) => {
    accu = produce(accu, (draft) => {
      draft[layerModel.id] = layerModel.geojson.properties;
    });

    if (layerModel.children.length) {
      accu = produce(accu, (draft) => {
        const childPropertiesByLayerModelId = getPropertiesByLayerModelId(
          layerModel.children
        );
        for (const childLayerModelId in childPropertiesByLayerModelId) {
          draft[childLayerModelId] =
            childPropertiesByLayerModelId[childLayerModelId];
        }
      });
    }
    return accu;
  }, {});
}

export function duplicateLayerModel<T extends Properties>(
  layerModel: LayerModel<T>
): LayerModel<T> {
  return {
    ..._cloneDeep(layerModel),
    id: makeId(),
    geojson: {
      ...layerModel.geojson,
      properties: {
        ...layerModel.geojson.properties,
        title: `${getLayerTitle(layerModel)} - copy`,
      },
    },
    isTemp: true,
  };
}
