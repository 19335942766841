<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useGatherSchemaStore } from '../../store/gather-schema';
import { useProjectStore } from '../../store/project';
import InputSelectFloating from '../InputSelectFloating.vue';

const projectStore = useProjectStore();

const props = defineProps<{
  templateTabId: Number | null;
}>();

const emit = defineEmits<{
  (event: 'setTemplateTabId', value: Number | null): void;
}>();

const gatherAppStore = useGatherSchemaStore();
const { getApps } = gatherAppStore;

const cTemplateTabId = computed({
  get() {
    return props.templateTabId;
  },
  set(value) {
    emit('setTemplateTabId', value);
  },
});

onMounted(() => {
  if (!projectStore.currentProject?.project_id) {
    throw new Error('Project ID is not set');
  }
  getApps(projectStore.currentProject.project_id);
});
</script>
<template>
  <InputSelectFloating
    v-model="cTemplateTabId"
    name="appSelector"
    label="Default app for new locations"
  >
    <option :value="null">No default app applied</option>
    <option
      v-for="app in gatherAppStore.apps.filter(
        (a) => a.drawing_type !== 'non-spatial'
      )"
      :value="app.id"
    >
      {{ app.title }}
    </option>
  </InputSelectFloating>
</template>
