<script>
export default {
  name: 'NumberValueContentInput',
};
</script>

<script setup>
import { onMounted } from 'vue';

const number = defineModel({
  type: String,
  required: true,
  get(value) {
    return parseFloat(value);
  },
  set(value) {
    return String(value);
  },
});

onMounted(() => {
  if (typeof number.value !== 'number') {
    number.value = 10;
  }
});
</script>

<template>
  <input v-model="number" type="number" class="form-control form-control-sm" />
</template>
