import Transform from 'ol-ext/interaction/Transform';
import { always } from 'ol/events/condition';
import { getModelId } from '../layer/utils';
import changeTransformStyle from '../style/changeTransformStyle';
import InteractionManager from './InteractionManager';

export default function createCircleEdit(
  interactionManager: InteractionManager
) {
  // The transform interaction is from ol-ext. It is used to do square resizing, rotation and moving.
  // For usage of transform, see https://viglino.github.io/ol-ext/examples/interaction/map.interaction.transform.html
  const transform = new Transform({
    hitTolerance: 2,
    translateFeature: true,
    scale: true,
    rotate: false,
    keepAspectRatio: always,
    translate: false,
    stretch: false,
    translateBBox: false,
    enableRotatedTransform: true,
    // Don't use the Select interaction so that the shape can keep being selected
    selection: false,
  });

  return {
    selectFeature(feature) {
      transform.select(feature, true);
    },
    getFeature() {
      return transform.getFeatures().item(0);
    },
    activate(map) {
      map.addInteraction(transform);
      changeTransformStyle(transform);
    },
    destroy(map) {
      map.removeInteraction(transform);
    },
    checkIsWorking() {
      return true;
    },
    getState() {
      const { layerManager } = interactionManager;
      const feature = this.getFeature();
      const layer = layerManager.findLayerByFeature(feature);

      return {
        layerModelId: getModelId(layer),
      };
    },
  };
}
