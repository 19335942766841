<template>
  <responsive-or-slide-up
    v-if="fields.length > 0"
    v-model="showConditionalOptions"
    title="Visibility"
    data-cy="field-options"
    :mobileSize="mobileSize"
    class="mt-2 clickable"
  >
    <template #body>
      <div v-if="isAlwaysHiddenAvailable" class="form-check form-switch mb-2">
        <input
          :id="alwaysHiddenCheckboxId"
          v-model="isAlwaysHidden"
          type="checkbox"
          class="form-check-input"
        />
        <label class="form-label" :for="alwaysHiddenCheckboxId"
          >Always hidden</label
        ><InfoButton
          class="ms-2"
          info="When Always Hidden is on then the field keeps invisible in the form."
        />
      </div>

      <div
        v-if="!isAlwaysHidden"
        class="d-flex flex-row align-items-center mb-3"
      >
        <label class="form-label me-auto mb-0">
          Only show the current field when a condition is met
        </label>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm ms-2"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Add a condition"
          @click="addCondition"
        >
          <i class="fal fa-plus m-1" />
        </button>
      </div>

      <FieldConditionList
        v-if="modelValue.conditions"
        :modelValue="modelValue.conditions"
        :fields="fields"
        :groups="modelValue.groups"
        :isDefault="false"
        @update:modelValue="updateConditions"
        @removeCondition="removeCondition"
      />

      <div
        v-if="modelValue.conditions && modelValue.conditions.length > 0"
        class="btn-group mt-2"
      >
        <button
          type="button"
          class="btn btn-secondary"
          :class="{
            active: !options.all_conditions_match,
          }"
          @click="setMatchingTechnique(false)"
        >
          At least one matches
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          :class="{
            active: options.all_conditions_match,
          }"
          @click="setMatchingTechnique(true)"
        >
          All conditions match
        </button>
      </div>
    </template></responsive-or-slide-up
  >
</template>
<script lang="ts" setup>
import { FieldTypeIds } from '@component-library/fields';
import ResponsiveOrSlideUp from './ResponsiveOrSlideUp.vue';
import FieldConditionList from './FieldConditionList.vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import makeId from '@component-library/local-id.mjs';
import Field from '@component-library/classes/Field';
import { Condition, GatherFieldOptions } from '@component-library/gather';
import { computed, onMounted, ref } from 'vue';

const props = defineProps<{
  modelValue: GatherFieldOptions;
  field: Field;
  fields: Field[];
  mobileSize: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: GatherFieldOptions): void;
  (event: 'add', value: Condition): void;
}>();

const showConditionalOptions = ref(false);
const alwaysHiddenCheckboxId = ref(makeId());

onMounted(() => {
  if (props.modelValue.conditions && props.modelValue.conditions.length > 0)
    showConditionalOptions.value = true;
});

const options = computed({
  get() {
    return props.modelValue;
  },
  set(updated) {
    emit('update:modelValue', updated);
  },
});

const isAlwaysHiddenAvailable = computed(() => {
  return props.field.field_type_id === FieldTypeIds.EXPRESSION;
});

const isAlwaysHidden = computed({
  get() {
    return props.modelValue.is_always_hidden;
  },
  set(value) {
    emit('update:modelValue', { ...props.modelValue, is_always_hidden: value });
  },
});

function updateConditions(conditions: Condition[]) {
  const options = { ...props.modelValue, conditions };
  emit('update:modelValue', options);
}

function addCondition() {
  emit('add', {
    field_id: null,
    operator: '=',
    value: null,
  });
}

function removeCondition(index: number) {
  if (!props.modelValue.conditions) {
    throw new Error('Conditions are not defined');
  }
  const conditions = props.modelValue.conditions.slice(0, index);
  updateConditions(conditions);
}
function setMatchingTechnique(all_conditions_match: boolean) {
  emit('update:modelValue', { ...props.modelValue, all_conditions_match });
}
</script>
