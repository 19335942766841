<template>
  <modal :half="true" @close="close">
    <template #header> App Preview </template>
    <template #default>
      <FormSection
        v-for="section of sections"
        v-if="sections"
        :key="section.id"
        class="modal-body"
        :allFields="allFields"
        :section="section"
        :samples="[]"
        :inputValues="[]"
        :shouldEmit="false"
        :previewForm="true"
      />
    </template>
    <template #footer>
      <ButtonSpinner
        class="btn btn-primary w-100"
        :loading="false"
        @click="close"
      >
        Close
      </ButtonSpinner>
    </template>
  </modal>
</template>

<script setup lang="ts">
import Modal from '@component-library/components/Modal.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import FormSection from '@component-library/form/FormSection.vue';
import { computed, ref, toRaw } from 'vue';

const props = defineProps<{
  dataTab: {
    sections: any[];
  };
}>();

const emit = defineEmits(['close']);

const sections = ref(structuredClone([...toRaw(props.dataTab.sections)]) ?? []);

const allFields = computed(() => {
  return sections.value.reduce((accu, item) => {
    return [...accu, ...item.template_fields];
  }, []);
});
const close = () => {
  emit('close');
};
</script>
