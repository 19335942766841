<script lang="ts" setup>
import { ErrorBag } from '../business-model/form';
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    placeholder?: string;
    name?: string;
    modelValue?: string | number | null;
    value?: string | number | null;
    errorBag?: ErrorBag;
    autocomplete?: string;
    autofocus?: boolean;
    type?: string;
    required?: boolean;
    invalid?: boolean;
    disabled?: boolean;
  }>(),
  {
    errorBag: () => ({}),
    type: 'text',
    required: false,
    invalid: false,
  }
);

const emit = defineEmits(['update:modelValue', 'input']);

const id = ref(props.name + '-' + makeId());

onBeforeMount(updateId);

const inputError = computed(() => {
  if (!props.name) {
    return null;
  }
  if (!(props.name in props.errorBag)) {
    return null;
  }
  return Array.isArray(props.errorBag[props.name])
    ? props.errorBag[props.name][0]
    : props.errorBag[props.name];
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}
</script>

<template>
  <div>
    <div class="form-floating">
      <input
        :id="id"
        v-model="inputValue"
        :type="type"
        :class="['form-control', { 'is-invalid': invalid || inputError }]"
        :name="name"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :required="required"
        :disabled="disabled"
        placeholder="a"
      />
      <label v-if="label" :for="id">
        {{ label }} <sup v-if="required" class="text-danger">*</sup>
      </label>
    </div>
    <div v-if="inputError" class="invalid-feedback">
      {{ inputError }}
    </div>
  </div>
</template>
