import localforage from 'localforage';

type Headers = Record<string, string>;

export const get = async (
  url: string,
  params: Record<string, any> = {},
  headers: Headers = {},
  additionalOptions: Record<string, any> = {}
) => {
  const urlParts = url.split('?');
  const originalParams = new URLSearchParams(urlParts[1] || '');

  // append parameters to url as query string
  if (params && Object.keys(params).length > 0) {
    for (const key of Object.keys(params)) {
      originalParams.append(key, params[key]);
    }
  }

  const fetchHeaders = await _getHeaders(headers);

  const fullUrl = urlParts[0] + '?' + originalParams.toString();
  const response = await fetch(fullUrl, {
    headers: fetchHeaders,
    ...additionalOptions,
  });

  return response;
};

export const getJSON = async (
  url: string,
  {
    params = {},
    headers = {},
  }: {
    params?: Record<string, any>;
    headers?: Headers;
  } = {}
) => {
  const response = await get(url, params, headers);

  const data = await response.json();

  return { data };
};

export const post = async (
  url: string,
  body: FormData,
  headers: Record<any, any> = {}
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: await _getHeaders(headers),
    body,
  });

  return await response.json();
};

const _getHeaders = async (
  headers: Record<any, any>,
): Promise<Headers> => {
  const bearerToken = await localforage.getItem('auth_token');

  // Only include bearer when url is not a full url
  const defaultHeaders: Headers = bearerToken
    ? {
      Authorization: `Bearer ${bearerToken}`,
    }
    : {};

  return {
    ...defaultHeaders,
    ...headers,
  };
};
