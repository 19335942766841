import { ComputedRef, Ref, ref } from 'vue';
import { Sample } from '../../../enviro';
import { OfflineSample } from '../../../offline-data';
import {
  getUniqueValuesFromArray,
} from '../../../utils';

const useTogglableSampleList = (
  samples: Ref<Sample[]> | ComputedRef<Sample[] | OfflineSample[]>,
  selectedSampleIds: ComputedRef<(number)[]>,
  selectedAppIds: ComputedRef<number[]>,
  loadSamples: (id: number) => Promise<void>,
  setSelectedAppIds: (ids: number[]) => void,
  setSelectedSampleIds: (ids: number[]) => void
) => {
  const toggledAppDropdowns = ref<number[]>([...selectedAppIds.value]);

  const isAppDropdownOpen = (id: number) => {
    return toggledAppDropdowns.value.includes(id);
  };

  const toggleAppDropdown = async (id: number) => {
    if (isAppDropdownOpen(id)) {
      closeAppDropdown(id);
    } else {
      openAppDropdown(id);

      if (getSamplesByAppId(id).length === 0) {
        await loadSamples(id);
      }
    }
  };

  const toggleApp = async (id: number) => {
    if (selectedAppIds.value.findIndex((appId) => appId === id) === -1) {
      if (!isAppDropdownOpen(id)) {
        await toggleAppDropdown(id);
      }

      setSelectedAppIds([...selectedAppIds.value, id]);
    } else {
      setSelectedAppIds(selectedAppIds.value.filter((appId) => appId !== id));

      if (hasAppSampleSelected(id)) {
        const appSamples = getSamplesByAppId(id);
        const selectedSamplesByAppId = selectedSampleIds.value.filter((id) => {
          return appSamples.map((s) => s.id).includes(id);
        });

        setSelectedSampleIds(
          selectedSampleIds.value.filter(
            (id) => !selectedSamplesByAppId.includes(id)
          )
        );
      }
    }
  };

  const getSamplesByAppId = (id: number) => {
    return samples.value.filter((s) => s.template_tab_id === id);
  };

  const openAppDropdown = (id: number) => {
    toggledAppDropdowns.value = [...toggledAppDropdowns.value, id];
  };

  const closeAppDropdown = (id: number) => {
    toggledAppDropdowns.value = toggledAppDropdowns.value.filter(
      (appId) => appId !== id
    );
  };

  const hasAppSampleSelected = (id: number) => {
    return (
      samples.value.filter(
        (s) =>
          selectedSampleIds.value.includes(Number(s.id)) && s.template_tab_id === id
      ).length > 0
    );
  };

  const toggleSample = ({ id, template_tab_id }: Sample | OfflineSample) => {
    const nid = Number(id);
    if (selectedSampleIds.value.includes(nid)) {
      setSelectedSampleIds(
        selectedSampleIds.value.filter((sampleId) => sampleId !== nid)
      );
    } else {
      setSelectedSampleIds([...selectedSampleIds.value, nid]);

      if (
        template_tab_id &&
        selectedAppIds.value.findIndex((appId) => appId === template_tab_id) ===
        -1
      ) {
        setSelectedAppIds([...selectedAppIds.value, template_tab_id]);
      }
    }
  };

  /**
   * Toggles multiple samples.
   * - Removes any sample IDs that are currently selected.
   * - Adds sample IDs that are not currently selected.
   * - Handles associated template_tab_id for adding to selectedAppIds.
   *
   * @param samples - Array of samples to toggle.
   */
  const toggleSamples = (samples: (Sample | OfflineSample)[]) => {
    const sampleIdsToToggle = samples.map((s) => Number(s.id));
    const currentlySelectedIds = new Set(selectedSampleIds.value);
    const idsToRemove: number[] = [];
    const idsToAdd: number[] = [];

    sampleIdsToToggle.forEach((id) => {
      if (currentlySelectedIds.has(id)) {
        idsToRemove.push(id);
      } else {
        idsToAdd.push(id);
      }
    });

    const newSelectedSampleIds = selectedSampleIds.value
      .filter((id) => !idsToRemove.includes(id))
      .concat(idsToAdd);

    setSelectedSampleIds(newSelectedSampleIds);

    const appIds = getUniqueValuesFromArray([
      ...selectedAppIds.value,
      ...samples
        .filter((s) => s.template_tab_id !== null)
        .map((s) => s.template_tab_id),
    ]);

    setSelectedAppIds(appIds);
  };

  return {
    toggledAppDropdowns,
    toggleApp,
    toggleAppDropdown,
    isAppDropdownOpen,
    toggleSample,
    toggleSamples,
  };
};

export default useTogglableSampleList;
