<script setup lang="ts">
import {
  ConditionsDefault,
  DefaultSelectOption,
  AutoAssignType,
  checkIsConditionsDefault,
} from '@component-library/business-model/auto-assign';
import {
  checkHasNumberValue,
  checkIsSelectSupported,
  getSelectOptions,
  FieldTypeIds,
  SelectOption,
} from '@component-library/fields';
import { computed, ref } from 'vue';
import Field from '@component-library/classes/Field';
import { GatherField } from '@component-library/gather';

const props = defineProps<{
  field: Field;
  fields: Field[];
}>();
const emit = defineEmits(['updateOptions']);

const defaultSelect = ref<HTMLSelectElement[]>([]);

const conditionsDefaults = computed<ConditionsDefault[]>(() => {
  return (props.field.options?.defaults?.filter((d) =>
    checkIsConditionsDefault(d)
  ) ?? []) as ConditionsDefault[];
});

const isText = computed(() => {
  return props.field.field_type_id == FieldTypeIds.TEXT;
});

const isDropdown = computed(() => {
  return props.field.field_type_id == FieldTypeIds.DROPDOWN;
});

const isLithology = computed(() => {
  return props.field.field_type_id == FieldTypeIds.LITHOLOGY;
});

const isCheckbox = computed(() => {
  return props.field.field_type_id == FieldTypeIds.CHECKBOX;
});

function findFieldOperandById(id: string | number): Field | undefined {
  return props.fields.find((f) => f.id === parseInt(String(id), 10));
}

function handleAddClick() {
  const options = {
    ...props.field.options,
    defaults: [
      ...(props.field.options?.defaults ?? []),
      {
        type: AutoAssignType.CONDITIONS,
        field_id: props.fields[0].id,
        operator: '=',
        value: null,
        default: null,
      },
    ],
  };
  emit('updateOptions', options);
}

function handleDeleteClick(index) {
  const cd = conditionsDefaults.value[index];
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(cd), 1);
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}

function hasOptions(cd: ConditionsDefault) {
  const field = findFieldOperandById(cd.field_id);
  return !!field && checkIsSelectSupported(field);
}

function updateDefault(
  index: number,
  { prop, value }: { prop: string; value: string | number | string[] }
) {
  const cd = conditionsDefaults.value[index];
  const nextDefaults = [...(props.field.options?.defaults ?? [])];
  nextDefaults.splice(nextDefaults.indexOf(cd), 1, { ...cd, [prop]: value });
  emit('updateOptions', {
    ...props.field.options,
    defaults: nextDefaults,
  });
}

function getOperators(cd: ConditionsDefault) {
  const field = findFieldOperandById(cd.field_id);

  if (field && checkHasNumberValue(field)) {
    return ['=', '!=', '<', '<=', '>', '>='];
  }

  return ['=', '!='];
}

function getValues(cd: ConditionsDefault): SelectOption[] {
  const field = findFieldOperandById(cd.field_id);

  if (!field) {
    return [];
  }

  return getSelectOptions(field);
}

function getDefaultFromSelect(index: number): string | string[] {
  const select = defaultSelect.value[index];
  const selectedValues: string[] = [];
  for (let i = 0; i < select.options.length; i++) {
    const so = select.options.item(i);
    if (so?.selected) {
      selectedValues.push(so.value);
    }
  }
  return props.field.options?.has_multiple ? selectedValues : selectedValues[0];
}

function getDefaultSelectOptions(): DefaultSelectOption[] {
  if (isCheckbox.value) {
    return [
      { value: 'yes', displayValue: 'Yes' },
      { value: 'no', displayValue: 'No' },
    ];
  }

  return (
    props.field.options?.options?.map((o) => ({
      value: o,
      displayValue: o,
    })) ?? []
  );
}

function getValueFromEventTarget(evt: Event) {
  return (evt.target as HTMLInputElement).value;
}

function checkIsOptionSelected(index, option) {
  const { default: value } = conditionsDefaults.value[index];
  return Array.isArray(value) ? value.includes(option) : option === value;
}
</script>

<template>
  <div>
    <div class="d-flex flex-row align-items-center mb-3">
      <label class="form-label me-auto mb-0">
        Automatically assign a default value when a condition is met
      </label>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm ms-2"
        @click="handleAddClick"
      >
        <i class="fas fa-plus m-1" />
      </button>
    </div>

    <template v-for="(cd, index) in conditionsDefaults">
      <div class="mb-1">
        <div class="input-group">
          <!-- Select for a Dropdown field as an operand -->
          <select
            class="form-control"
            :value="cd.field_id"
            @change="
              (evt) =>
                updateDefault(index, {
                  prop: 'field_id',
                  value: parseInt(getValueFromEventTarget(evt), 10),
                })
            "
          >
            <option v-for="f in fields" :key="f.id" :value="f.id">
              {{ f.label }}
            </option>
          </select>

          <!-- Select for operators -->
          <select
            class="form-control"
            :value="cd.operator"
            @change="
              (evt) =>
                updateDefault(index, {
                  prop: 'operator',
                  value: getValueFromEventTarget(evt),
                })
            "
          >
            <option
              v-for="(o, oIndex) in getOperators(cd)"
              :key="oIndex"
              :value="o"
            >
              {{ o }}
            </option>
          </select>

          <!-- Select for the options of a Dropdown field operand -->
          <select
            v-if="hasOptions(cd)"
            class="form-control"
            :value="cd.value"
            @change="
              (evt) =>
                updateDefault(index, {
                  prop: 'value',
                  value: getValueFromEventTarget(evt),
                })
            "
          >
            <option
              v-for="fieldValue in getValues(cd)"
              :key="fieldValue.value"
              :value="fieldValue.value"
            >
              {{ fieldValue.displayValue }}
            </option>
          </select>

          <!-- Text input for the value of a Text field operand -->
          <input
            v-else
            type="text"
            class="form-control"
            :value="cd.value"
            @input="
              (evt) =>
                updateDefault(index, {
                  prop: 'value',
                  value: getValueFromEventTarget(evt),
                })
            "
          />

          <button
            type="button"
            class="btn btn-outline-danger"
            @click="handleDeleteClick(index)"
          >
            <i class="fas fa-trash-alt" />
          </button>
        </div>

        <!-- Text input for the default value of a Text field -->
        <div v-if="cd.value && isText" class="mb-2 mt-2">
          <div class="form-group">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                :value="cd.default"
                placeholder="Default text"
                @change="
                  (evt) =>
                    updateDefault(index, {
                      prop: 'default',
                      value: getValueFromEventTarget(evt),
                    })
                "
              />
            </div>
          </div>
        </div>

        <!-- Select for the default value of a Dropdown/Lithology/Checkbox field  -->
        <select
          v-if="cd.value && (isDropdown || isLithology || isCheckbox)"
          ref="defaultSelect"
          class="form-control form-control-sm mt-1"
          :multiple="props.field.options?.has_multiple"
          @change="
            (evt) =>
              updateDefault(index, {
                prop: 'default',
                value: getDefaultFromSelect(index),
              })
          "
        >
          <option
            :value="null"
            disabled
            :selected="checkIsOptionSelected(index, null)"
            hidden
          >
            Select default value
          </option>
          <option
            v-for="(dso, dsoIndex) in getDefaultSelectOptions()"
            :key="`dso-${dsoIndex}`"
            :value="dso.value"
            :selected="checkIsOptionSelected(index, dso.value)"
          >
            {{ dso.displayValue }}
          </option>
        </select>
      </div>
    </template>
  </div>
</template>
