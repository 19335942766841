<template>
  <div class="d-flex align-items-center">
    <ul class="nav nav-pills nav-fill">
      <li
        v-for="coc in cocs"
        :key="`coc-${coc.id}`"
        class="nav-item"
        @click="selectCoc(coc)"
      >
        <div
          class="nav-link"
          :class="{
            'bg-dark text-white fw-bold': selectedCoc.id == coc.id,
          }"
        >
          <div class="d-flex align-items-center justify-content-center">
            {{ formatDate(coc.created_at) }}
            <i
              v-if="cocs.length > 1"
              class="fas fa-times ms-2"
              @click.stop="deleteCoc(coc.id)"
            />
          </div>
        </div>
      </li>
    </ul>
    <button class="btn btn-light btn-sm ms-2 me-2" @click="createCoc">
      <i class="fas fa-plus" />
    </button>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    cocs: Array,
    selectedCoc: Object,
    selectCoc: Function,
    createCoc: Function,
    deleteCoc: Function,
  },
  methods: {
    formatDate(date) {
      return moment.utc(date).local().format('DD-MM-YYYY hh:mm A');
    },
  },
};
</script>
