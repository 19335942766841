import _isEqual from 'lodash/isEqual';
import _isPlainObject from 'lodash/isPlainObject';
import moment, { MomentInput, unitOfTime } from 'moment';
import { ref } from 'vue';
import type { InputValue } from './gather';
import makeId from './local-id.mjs';
import { FORM_CONTEXT_TYPE_DATA_MANAGER } from './business-model/common';

export type OmitId<T> = Omit<T, 'id'>;

/**
 * @deprecated Placeholder until we update Axios and import direct from library.
 */
export interface AxiosProgressEvent {
  loaded: number;
  total: number;
}

export interface Paginated<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export function forcePlainObject(value) {
  return _isPlainObject(value) ? value : {};
}

export function getFileExtension(fileName) {
  return fileName.split('.').at(-1).toLowerCase();
}

export function checkIsIterable<T>(input): input is Iterable<T> {
  if (input === null || input === undefined) {
    return false;
  }

  return typeof input[Symbol.iterator] === 'function';
}

type SectionCountsByTab = Record<number, Record<number, number>>;

export function createFormContext(
  type,
  updateField: (fieldId: number, prop: string, value: any) => Promise<void>,
  getProject: () => any,
  getLinkedItemId: (appTitle: string) => number | undefined = () => undefined,
  setIsBusy: (isBusy: boolean) => void = () => { }
) {
  // A queue of changed input values used to update expressions.
  const changedInputValueQueue = ref<InputValue[]>([]);
  // Used to track the number of expressions which are impacted by the changed input value queue.
  const impactedExpressionsCounter = ref<number>(0);

  // https://stackoverflow.com/questions/65718651/how-do-i-make-vue-2-provide-inject-api-reactive
  const formContext: any = {
    type,
    updateField,
    sectionCountsByTab: {} as SectionCountsByTab,
    updateSectionCount(tabId: number, sectionId: number, count: number) {
      const sectionCounts = this.sectionCountsByTab[tabId];
      if (!sectionCounts) {
        this.sectionCounts = this.sectionCountsByTab[tabId] = {};
      }
      this.sectionCounts[sectionId] = count;
    },
    getSectionCount(tabId: number, sectionId: number) {
      return (this.sectionCountsByTab[tabId] ?? {})[sectionId] ?? 1;
    },
    getLinkedItemId,
    get changedInputValueQueue() {
      return changedInputValueQueue.value;
    },
    increaseImpactedExpressionsCounter() {
      impactedExpressionsCounter.value++;
    },
    decreaseImpactedExpressionsCounter() {
      impactedExpressionsCounter.value--;
      if (impactedExpressionsCounter.value === 0) {
        changedInputValueQueue.value = [];
      }
    },
    setIsBusy,
  };

  formContext.theme =
    type === FORM_CONTEXT_TYPE_DATA_MANAGER
      ? {
        primaryColor: '#3366a2',
      }
      : {
        primaryColor: '#4e0695',
      };

  Object.defineProperty(formContext, 'project', {
    enumerable: true,
    get: getProject,
  });
  return formContext;
}

export function addVideoPlaybackLimit(videoPlayer: HTMLVideoElement) {
  // Use code from https://stackoverflow.com/questions/11903545/how-to-disable-seeking-with-html5-video-tag

  let supposedCurrentTime = 0;

  const handleTimeupdate = function () {
    if (!videoPlayer.seeking) {
      supposedCurrentTime = videoPlayer.currentTime;
    }
  };
  const handleSeeking = function () {
    // guard agains infinite recursion:
    // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
    const delta = videoPlayer.currentTime - supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      videoPlayer.currentTime = supposedCurrentTime;
    }
  };
  const handleEnded = function () {
    // reset state in order to allow for rewind
    supposedCurrentTime = 0;
  };
  const handleRateChange = function () {
    videoPlayer.playbackRate = 1;
  };

  videoPlayer.addEventListener('timeupdate', handleTimeupdate);
  // prevent user from seeking
  videoPlayer.addEventListener('seeking', handleSeeking);
  // delete the following event handler if rewind is not required
  videoPlayer.addEventListener('ended', handleEnded);
  videoPlayer.addEventListener('ratechange', handleRateChange);

  // Return the enable function.
  return () => {
    videoPlayer.removeEventListener('timeupdate', handleTimeupdate);
    videoPlayer.removeEventListener('seeking', handleSeeking);
    videoPlayer.removeEventListener('ended', handleEnded);
    videoPlayer.removeEventListener('ratechange', handleRateChange);
  };
}

export function openNewTab(url) {
  window.open(url, '_blank');
}

type DeviceType = 'mobile' | 'desktop';

function detectDeviceType(): DeviceType {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? 'mobile'
    : 'desktop';
}

export const getDeviceName = () => {
  const userAgent = navigator.userAgent;

  if (/iPhone/.test(userAgent)) {
    return 'iPhone';
  } else if (/Android/.test(userAgent)) {
    return 'Android Device';
  } else {
    return 'Chrome - Web Browser';
  }
};

export function checkIsMobile() {
  return detectDeviceType() === 'mobile';
}

export function replaceQueryParam(
  url: string,
  paramName: string,
  paramValue: string
) {
  const regex = new RegExp(`([?&])${paramName}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  return url.match(regex)
    ? url.replace(regex, `$1${paramName}=${paramValue}$2`)
    : `${url}${separator}${paramName}=${paramValue}`;
}

export function validateEmail(email: any) {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}|[a-zA-Z\-0-9]+)$/
      ) !== null
  );
}

// Attribution to ChatGPT
export function breakTextIntoLines(text: string, maxLineLength: number) {
  const words = text.split(/\s+/);
  const lines: string[] = [];
  let currentLine = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (currentLine.length + word.length + 1 <= maxLineLength) {
      // Add word to current line
      currentLine += (currentLine === '' ? '' : ' ') + word;
    } else {
      // Add current line to lines array and start new line with current word
      lines.push(currentLine);
      currentLine = word;
    }
  }

  // Add last line to lines array
  lines.push(currentLine);

  return lines;
}

export const getProjectLastUpdated = (updatedAt: MomentInput) => {
  const differenceInMonths = _getDifferenceStringByType(updatedAt, 'month');
  if (differenceInMonths) {
    return differenceInMonths;
  }

  const differenceInDays = _getDifferenceStringByType(updatedAt, 'day');
  if (differenceInDays) {
    return differenceInDays;
  }

  const differenceInHours = _getDifferenceStringByType(updatedAt, 'hour');
  if (differenceInHours) {
    return differenceInHours;
  }

  const differenceInMinutes = _getDifferenceStringByType(updatedAt, 'minute');

  if (!differenceInMinutes) {
    return 'Just now';
  }

  return differenceInMinutes;
};

function _getDifferenceStringByType(
  updatedAt: MomentInput,
  type: unitOfTime.Diff
) {
  const difference = moment().diff(moment.utc(updatedAt), type);

  if (difference == 0) {
    return;
  }

  return difference + ` ${type}${difference > 1 ? `s` : ``} ago`;
}

export function dataUrlToBlob(dataUrl: string) {
  if (!window || window.window !== window) {
    throw new Error('This module is only available in browser');
  }

  if (!Blob) {
    throw new Error('Blob was not supported');
  }

  const dataURLPattern = /^data:((.*?)(;charset=.*?)?)(;base64)?,/;

  // parse the dataURL components as per RFC 2397
  const matches = dataUrl.match(dataURLPattern);
  if (!matches) {
    throw new Error('invalid dataURI');
  }

  // default to text/plain;charset=utf-8
  const mediaType = matches[2]
    ? matches[1]
    : 'text/plain' + (matches[3] || ';charset=utf-8');

  const isBase64 = !!matches[4];
  const dataString = dataUrl.slice(matches[0].length);
  const byteString = isBase64
    ? // convert base64 to raw binary data held in a string
    atob(dataString)
    : // convert base64/URLEncoded data component to raw binary
    decodeURIComponent(dataString);

  const array: number[] = [];
  for (var i = 0; i < byteString.length; i++) {
    array.push(byteString.charCodeAt(i));
  }

  return new Blob([new Uint8Array(array)], { type: mediaType });
}

export const getUniqueValuesFromArray = (values) => {
  return [...new Set([...values])];
};

export const getUniqueValuesFromArrayByKey = (values: any[], key: string) => {
  const seen = new Set();
  return values.filter((item) => {
    const k = item[key];
    return seen.has(k) ? false : seen.add(k);
  });
};

export function truncateWithEllipsis(
  input: string,
  length: number,
  ellipsis = '...'
) {
  if (input.length > length) {
    return input.substring(0, length - ellipsis.length) + ellipsis;
  }
  return input;
}

export function ucFirst(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function ucFirstEachWord(value: string) {
  return value.split(' ').map(ucFirst).join(' ');
}

/**
 * @deprecated Use `makeId` helper.
 */
export const generateUniqueId = (): string => {
  return makeId();
};

export function chunkArray(array: any[], chunkSize: number = 500) {
  const chunks: any[] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export const omit = (obj: any, keys: string[]) => {
  const result = {};
  for (const key in obj) {
    if (!keys.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const deepEqual = (a: any, b: any) => {
  if (a === b) return true;

  if (a && typeof a == 'object' && b && typeof b == 'object') {
    if (Object.keys(a).length != Object.keys(b).length) return false;

    for (let key in a) {
      if (!(key in b)) return false;
      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  } else {
    return false;
  }
};

export function getChangedProperties<T extends object>(
  original: T,
  modified: T
): Array<keyof T> {
  const changedProps: Array<keyof T> = [];

  for (const key in original) {
    if (!_isEqual(original[key], modified[key])) {
      changedProps.push(key);
    }
  }

  return changedProps;
}

export function formatLocalDateTime(
  date: string | number | Date,
  format = 'LLL'
) {
  return moment(date).format(format);
}

export function formatLocalDate(date: string | number | Date, format = 'L') {
  return moment(date).format(format);
}

export function stripProjectIdFromPath(path: string) {
  return path.replace(/^\/p\/[^/]+/, '');
}