<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label for="laboratory" class="form-label"> Laboratory </label>
          <select
            id="laboratory"
            v-model="coc.laboratory_id"
            class="form-control"
          >
            <option
              v-for="(lab, lkey) in laboratories"
              :key="lkey"
              :value="lab.laboratory_id"
            >
              {{ lab.lab_title }}
            </option>
          </select>

          <small class="text-muted">{{ laboratoryEmail }}</small>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label for="quote_no" class="form-label"> Quote no. </label>
          <input
            id="quote_no"
            v-model="coc.quote_no"
            type="number"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-3">
        <label for="primary_contact" class="form-label">
          Primary contact
        </label>
        <select id="laboratory" v-model="coc.owner_id" class="form-control">
          <option
            v-for="(user, uKey) in users"
            :key="uKey"
            :value="user.user_id"
          >
            {{ user.email }}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="form-group mb-3">
          <label for="charge_to" class="form-label"> Charge to </label>
          <input
            id="charge_to"
            v-model="coc.charge_to"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-10">
        <div class="form-group mb-3">
          <label for="address" class="form-label"> Address </label>
          <input
            id="address"
            v-model="coc.address"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group mb-3">
          <label for="postcode" class="form-label"> Postcode </label>
          <input
            id="postcode"
            v-model="coc.postcode"
            type="number"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label for="phone" class="form-label"> Phone </label>
          <input
            id="phone"
            v-model="coc.phone"
            type="number"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label for="mobile" class="form-label"> Mobile </label>
          <input
            id="mobile"
            v-model="coc.mobile"
            type="number"
            class="form-control"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-3">
        <label for="order_no" class="form-label"> Order No </label>
        <input
          id="order_no"
          v-model="coc.order_no"
          type="number"
          class="form-control"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <div class="form-group mb-3">
          <label for="additional_info" class="form-label"> Priority </label>
          <div>
            <div
              v-for="priority in priorities"
              :key="priority.index"
              class="form-check form-check-inline"
            >
              <input
                :id="'priority-' + priority.index"
                v-model="coc.priority"
                class="form-check-input"
                type="radio"
                :value="priority.index"
              />

              <label
                class="form-check-label"
                :for="'priority-' + priority.index"
              >
                {{ priority.title }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="form-group mb-3">
          <label for="additional_info" class="form-label">
            Additional information
          </label>
          <textarea
            id="additional_info"
            v-model="coc.additional_information"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CocForm',
  props: {
    coc: Object,
    laboratories: Array,
    users: Array,
  },
  data: () => ({
    priorities: [
      { title: 'Low', index: 1 },
      { title: 'Normal', index: 2 },
      { title: 'High', index: 3 },
      { title: 'Urgent', index: 4 },
    ],
  }),
  computed: {
    laboratoryEmail() {
      const laboratory = this.laboratories.find(
        (l) => l.laboratory_id == this.coc.laboratory_id
      );

      return laboratory?.lab_email;
    },
  },
};
</script>
