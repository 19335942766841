<script lang="ts" setup>
import { getDefaultValuePair } from '@component-library/business-logic/expression';
import { FieldTypeIds } from '@component-library/fields';
import { onMounted } from 'vue';

const date = defineModel({ type: String, required: true });

onMounted(() => {
  if (!date.value) {
    const { value } = getDefaultValuePair({
      field_type_id: FieldTypeIds.DATE,
    });
    date.value = value;
  }
});
</script>

<template>
  <input v-model="date" type="date" class="form-control" name="date" />
</template>
