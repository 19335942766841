<script lang="ts" setup>
import { getDefaultValuePair } from '@component-library/business-logic/expression';
import { FieldTypeIds } from '@component-library/fields';
import { onMounted } from 'vue';

const time = defineModel({ type: String, required: true });

onMounted(() => {
  if (!time.value) {
    const { value2 } = getDefaultValuePair({
      field_type_id: FieldTypeIds.DATE,
    });
    time.value = value2!;
  }
});
</script>

<template>
  <input v-model="time" type="time" class="form-control" name="time" />
</template>
