<script setup lang="ts">
import type { App } from '@component-library/gather';
import AlertBox from '@component-library/components/AlertBox.vue';
import InfoButton from '@component-library/components/InfoButton.vue';
import { computed, watch } from 'vue';
import makeId from '@component-library/local-id.mjs';

type PoiAppId = number | null;

const id = makeId();

const props = defineProps<{
  allowCollectionOnPoi: boolean;
  poiAppId?: PoiAppId;
  poiApps: App[];
}>();
const emit = defineEmits(['change']);

const hasPoiApps = computed(() => props.poiApps.length > 0);

watch(
  () => props.allowCollectionOnPoi,
  (newValue) => {
    if (
      newValue &&
      (!props.poiAppId ||
        !props.poiApps.find((poiApp) => poiApp.id === props.poiAppId))
    ) {
      changePoiAppId(props.poiApps[0].id);
    } else if (!newValue && props.poiAppId) {
      changePoiAppId(null);
    }
  },
  {
    immediate: true,
  }
);

function change(key, value) {
  emit('change', {
    allow_collection_on_poi: props.allowCollectionOnPoi,
    poi_app_id: props.poiAppId,
    [key]: value,
  });
}

function changeAllowCollectionOnPoi(value: boolean) {
  change('allow_collection_on_poi', value);
}

function changePoiAppId(value: PoiAppId) {
  change('poi_app_id', value);
}

function handleAllowCollectionOnPoiChange(event: Event) {
  changeAllowCollectionOnPoi((event.target! as HTMLInputElement).checked);
}

function handlePoiAppIdChange(event: Event) {
  changePoiAppId(parseInt((event.target! as HTMLInputElement).value, 10));
}
</script>

<template>
  <div>
    <div class="form-check">
      <input
        :id="id"
        class="form-check-input"
        type="checkbox"
        :checked="allowCollectionOnPoi"
        :disabled="!hasPoiApps"
        @change="handleAllowCollectionOnPoiChange"
      />
      <label class="form-check-label" :for="id">
        Allow collection on point of interest
      </label>
      <InfoButton
        v-if="hasPoiApps"
        class="ms-2"
        info="Check this option to enable using an Any or Non-spatial app from the following dropdown to collect data on points of interest."
      />
    </div>
    <AlertBox v-if="!hasPoiApps" type="info">
      You need to create an <b>Any</b> or <b>Non-spatial</b> app for collecting
      data of points of interest.
    </AlertBox>
    <select
      v-else-if="allowCollectionOnPoi"
      class="form-control"
      :value="poiAppId"
      @change="handlePoiAppIdChange"
    >
      <option v-for="poiApp in poiApps" :key="poiApp.id" :value="poiApp.id">
        {{ poiApp.title }}
      </option>
    </select>
  </div>
</template>
