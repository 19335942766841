<script setup>
import { canDeleteSample } from '@/js/helpers/general.js';
import { useStore } from '@/js/store';
import useSampleStore from '@/js/stores/sample';
import { StackableModalType } from '@/js/types/modal-stack';
import EventBus from '@component-library/EventBus';

const props = defineProps({
  entries: Array,
  isOnline: Boolean,
});

const emit = defineEmits('getEntries', 'showVersionControlModal');

const store = useStore();
const sampleStore = useSampleStore();

const onScroll = (e) => {
  const ele = e.target;
  if (ele.scrollHeight - ele.scrollTop === ele.clientHeight) {
    emit('getEntries');
  }
};

const editEntry = (entry) => {
  sampleStore.addSample(entry);
  store.dispatch('pushToModalStack', {
    type: StackableModalType.SampleModal,
    payload: entry.id,
  });
};

const openEntryVersionControl = (entry) => {
  EventBus.$emit('setSampleDataToModify', entry);
  emit('showVersionControlModal');
};

const deleteEntry = (entry) => {
  EventBus.$emit('setSampleDataToModify', entry);
  emit('showDeleteSampleModal');
};
</script>

<template>
  <div
    class="table-responsive custom-scrollbar"
    style="max-height: 600px"
    @scroll="onScroll"
  >
    <table class="table table-hover">
      <tbody>
        <tr v-for="entry in props.entries" :key="entry.id" class="clickable">
          <td>{{ entry.custom_title || entry.lab_title }}</td>
          <td class="text-end">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary ms-1"
              @click="editEntry(entry)"
            >
              <i class="fas fa-pencil"></i>
            </button>
            <button
              v-if="canDeleteSample(entry.created_at)"
              type="button"
              class="btn btn-sm btn-outline-danger ms-1"
              @click="deleteEntry(entry)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
            <button
              v-if="isOnline && !entry.offline_user_id"
              type="button"
              class="btn btn-sm btn-outline-secondary ms-1"
              @click="openEntryVersionControl(entry)"
            >
              <i class="fas fa-history"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
