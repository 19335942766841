<script lang="ts" setup>
import { useOfflineStorageManagerStore } from '@component-library/store/offline-storage-manager';
import { useStore } from '@/js/store';
import { checkIsPoiApp } from '@component-library/business-logic/app';
import { App } from '@component-library/gather';
import { OfflineSample } from '@component-library/offline-data';
import { Project } from '@component-library/project';
import { computed, ref, watch } from 'vue';
import api from '../map/api';
import ActionMenu from './components/ActionMenu.vue';
import EntryFilter from './components/EntryFilter.vue';
import EntryTable from './components/EntryTable.vue';
import Spinner from '@component-library/components/Spinner.vue';
import { useRouter } from 'vue-router';

const offlineStorageManager = useOfflineStorageManagerStore();
const store = useStore();
const router = useRouter();
const props = defineProps<{
  templateTabs: App[];
}>();

const emit = defineEmits<{
  (event: 'showProjectInfoModal'): void;
  (event: 'showDeleteSampleModal'): void;
  (event: 'showVersionControlModal'): void;
  (event: 'startNonSpatial'): void;
  (event: 'changeViewType', isNonSpatial: boolean): void;
}>();

const entries = ref<OfflineSample[]>([]),
  loading = ref(false),
  isLoadFinished = ref(false),
  filters = ref({
    query: null,
    tab_id: null,
  }),
  currentPage = ref(1);
const filteredEntries = computed<OfflineSample[]>(() => {
  return entries.value.filter((s) => {
    const { template_tab_id: appId } = s;
    if (!appId) {
      return true;
    }

    return !checkIsPoiApp(props.templateTabs, appId);
  });
});

const project = store.state.project as Project | null;
if (!project?.project_id) {
  throw new Error('Project not found');
}
const offlineProject = offlineStorageManager.getProjectById(project.project_id);
const isOnline = store.state.isOnline;

const nonSpatialTabs = computed(() => {
  return props.templateTabs.filter(
    (t) =>
      ['any', 'non-spatial'].includes(t.drawing_type) &&
      !checkIsPoiApp(props.templateTabs, t.id)
  );
});

function resetEntries() {
  entries.value = [];
  isLoadFinished.value = false;
  currentPage.value = 1;
}

function getFreshEntries() {
  resetEntries();
  getEntries();
}

defineExpose({ getFreshEntries });

const getEntries = async (updatedFilters = null) => {
  if (updatedFilters) {
    resetEntries();
    filters.value = updatedFilters;
  }

  if (isLoadFinished.value || loading.value) {
    return;
  }

  const templateTabIds = filters.value.tab_id
    ? [filters.value.tab_id]
    : nonSpatialTabs.value.map((t) => t.id);

  if (!isOnline && offlineProject) {
    entries.value = offlineProject.samples.filter(
      (s) => s.template_tab_id && templateTabIds.includes(s.template_tab_id)
    );
    isLoadFinished.value = true;
    return;
  }

  loading.value = true;

  try {
    const { data } = await api.getPaginatedSamples({
      page: currentPage.value,
      query: filters.value.query,
      templateTabIds,
      isNonSpatial: true,
    });

    const paginator = data.samples;
    if (paginator.data.length == 0) {
      isLoadFinished.value = true;
    } else {
      currentPage.value = paginator.current_page + 1;
      entries.value = [...entries.value, ...paginator.data];
    }

    loading.value = false;
  } catch (e) {
    throw e;
  }
};

watch(
  nonSpatialTabs,
  () => {
    getEntries();
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div
    class="col-12 col-md-10 offset-0 offset-md-1 pb-4 px-3 px-md-0 d-flex flex-column h-100"
  >
    <EntryFilter
      :loading="loading"
      :nonSpatialTabs="nonSpatialTabs"
      @getEntries="getEntries"
    />
    <ActionMenu
      :nonSpatialTabs="nonSpatialTabs"
      :hasSpatialTabs="
        props.templateTabs.some((t) => t.drawing_type !== 'non-spatial')
      "
      @startNonSpatial="emit('startNonSpatial')"
      @changeViewType="(isNonSpatial) => emit('changeViewType', isNonSpatial)"
      @showProjectInfoModal="emit('showProjectInfoModal')"
    />
    <div v-if="loading && entries.length == 0" class="text-center p-4">
      <Spinner large />
    </div>
    <div v-else-if="!loading && entries.length === 0" class="text-center p-4">
      <div
        v-if="templateTabs.length === 0"
        class="d-flex align-items-center justify-content-center flex-column text-center all-data-loading w-100"
      >
        <h1 class="fal fa-exclamation-triangle"></h1>
        <h3 class="fw-bolder">No apps configured for this project.</h3>
        <a
          class="btn btn-primary btn-lg"
          @click="router.push('/template/' + project?.project_id + '/apps')"
        >
          Go to App Editor
        </a>
      </div>
      <div
        v-else
        class="d-flex align-items-center justify-content-center flex-column text-center all-data-loading w-100"
      >
        <h1 class="fal fa-exclamation-triangle"></h1>
        <h3 class="fw-bolder">No items to show</h3>
        <h6 class="text-muted mb-0">Try adding an item via the plus.</h6>
      </div>
    </div>
    <EntryTable
      v-else
      :entries="filteredEntries"
      :isOnline="isOnline"
      @getEntries="getEntries"
      @showDeleteSampleModal="emit('showDeleteSampleModal')"
      @showVersionControlModal="emit('showVersionControlModal')"
    />
  </div>
</template>
