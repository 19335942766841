import moment from 'moment';
import type { User } from './user';
import axios from 'axios';
import useIsGather from './composables/useIsGather';

export enum ReviewStatus {
  WIP = 0,
  AWAITING_REVIEW = 1,
  REVIEW_PASSED = 2,
  REVIEW_FAILED = 3,
  AWAITING_FORMATTING = 4,
  FORMATTING_FAILED = 5,
  READY = 6,
  SENT = 7,
}

export const ReviewStatusLabels: Record<ReviewStatus, string> = {
  [ReviewStatus.WIP]: 'Work in progress',
  [ReviewStatus.AWAITING_REVIEW]: 'Awaiting reviewer',
  [ReviewStatus.REVIEW_PASSED]: 'Reviewed',
  [ReviewStatus.REVIEW_FAILED]: 'Revision needed',
  [ReviewStatus.AWAITING_FORMATTING]: 'Awaiting formatting',
  [ReviewStatus.FORMATTING_FAILED]: 'Formatting failed',
  [ReviewStatus.READY]: 'Ready',
  [ReviewStatus.SENT]: 'Sent to client',
};

export function formatReviewStatus(reviewStatus: ReviewStatus): string {
  return ReviewStatusLabels[reviewStatus] || 'Unknown';
}

export function getReviewStatusIconClasses(
  reviewStatus: ReviewStatus
): string | null {
  return (
    {
      [ReviewStatus.AWAITING_REVIEW]:
        'fas fa-exclamation-triangle text-warning',
      [ReviewStatus.REVIEW_FAILED]: 'fas fa-times text-danger',
      [ReviewStatus.REVIEW_PASSED]: 'fas fa-check text-success',
    }[reviewStatus] ?? null
  );
}

export interface File {
  id: string;
  project_id: number;
  uploader_user_id: number | null;
  comments_file_id: null | string;
  virus_status: number;
  review_status: ReviewStatus;
  review_comments: string | null;
  formatter_comments: string | null;
  version: number;
  is_final: boolean;
  display_name: string;
  size_mb: number;
  s3_file: string;
  path: string;
  temporary_url: null | string;

  reviewers?: User[];
  formatters?: User[];
  authors?: User[];

  previous_versions?: FileVersion[];

  updated_at: string;
  created_at: string;

  // Front end computed values below
  virus_icon?: string | null;
  virus_status_text?: string | null;
  review_icon?: string | null;
  review_status_text?: string | null;
}

export interface FileVersion {
  id: number;
  file_id: number;
  version: number;
  display_name: string;
  size_mb: number;
  s3_file: string;

  temporary_url?: string;

  file?: File;
  created_at: string;
}

export interface Directory {
  display_name: string;
  path: string;
  project_id?: number;
}

export enum DocumentType {
  ANY = -1,
  EXCEL = 1,
  WORD = 0,
}

export enum EditorStatus {
  NONE = 0,
  EDITING = 1,
  READY_FOR_SAVING = 2,
  SAVING_ERROR_OCCURRED = 3,
  CLOSED_WITHOUT_CHANGES = 4,
  SAVED_CONTINUE_EDITING = 6,
  ERROR_FORCE_SAVING = 7,
  CHANGED = 100,
}

// This may not be completely accurate.
export interface Document {
  id: number;
  clone_from_id: null | number;
  creator_id: number;
  creator: User | null;
  project_id: number;
  type: DocumentType;
  status: EditorStatus;
  status_updated_at: string;
  has_been_exported: boolean;
  name: string;
  file_name: string;
  tab_settings?: DocumentTabSettings;
  pre_generation_event_id?: number | null;
  post_node_script: string;
  snippets?: {
    [key: number]: string;
  };
  errors?: {
    [key: number]: {
      message?: string;
      command?: string;
    };
  };
  temporary_url?: string;
  template_file?: File;
  discipline: string | null;

  updated_at: string;
  created_at: string;
}

export type DocumentTabSettings = {
  [key: number]: boolean;
};

/**
 * @deprecated Legacy Custom Templates. Use Document system instead.
 */
export type CustomTemplate = any;

export interface UploadStatus {
  progress: number;
  pending: boolean;
  complete: boolean;
  error: string | null;
}

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

export function parseAndCompressFile(file, toBlob = true) {
  return new Promise((resolve) => {
    const maxWidth = 3072;
    const maxHeight = 3072;
    const mimeType = 'image/jpeg';

    const blobURL = URL.createObjectURL(file);
    const img = new Image();

    img.src = blobURL;
    img.onerror = (e) => {
      URL.revokeObjectURL(img.src);
      throw e;
    };

    img.onload = () => {
      const [newWidth, newHeight] = calculateSize(img, maxWidth, maxHeight);

      const canvas = document.createElement('canvas');

      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('Could not get 2d context');
      }
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      URL.revokeObjectURL(img.src);

      if (toBlob) {
        canvas.toBlob(
          (blob) => {
            resolve({ name: file.name, blob });
          },
          mimeType,
          0.8
        );
      } else {
        resolve(canvas.toDataURL('image/jpeg', 0.7));
      }
    };
  });
}

export type InputValueValueFile = {
  name: string;
  src: string;
  blob?: Blob;
};

export function downloadInputValueFile(file: InputValueValueFile) {
  let url = file.src;
  if (!url && !file.blob) {
    throw new Error('Cannot download file. No file source or blob provided');
  }
  if (file.blob) {
    url = URL.createObjectURL(file.blob);
  }
  const a = document.createElement('a');
  if (!useIsGather() && url.replace(/^\/+/, '').startsWith('api/')) {
    url = url.replace('api/', '');
  }

  axios.get(url, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });
    a.href = URL.createObjectURL(blob);
    a.download =
      (file.name || file.src || 'image.jpg').split('/').pop() || 'image.jpg';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    if (file.blob) {
      URL.revokeObjectURL(url);
    }
  });
}

/**
 * Ensure the file name is valid and has the correct extension
 * @param file
 * @param name
 * @returns
 */
export function parseInputValueFileName(
  file: InputValueValueFile,
  name: string,
  fallbackExtension: string = '.jpg'
) {
  if (!name) {
    name = '';
  }

  name = String(name).trim();
  if (name === '') {
    name =
      moment().format('Y-MM-DD') + '-' + Math.random().toString().substring(6);
  }

  // Enforce the correct extension based on the src url (typically a S3 temporary url)
  let srcExt = fallbackExtension;
  if (file.src) {
    const fileExt = file.src.split('.').pop() || '';
    if (['png', 'jpg', 'jpeg', 'webp'].includes(fileExt)) {
      srcExt = '.' + fileExt;
    }
  }
  // Remove any known duplicate extensions
  if (!name.endsWith(srcExt)) {
    const parts = name.split('.');
    if (parts.length > 1) {
      const ext = parts.pop();
      if (ext && ['png', 'jpg', 'jpeg', 'webp'].includes(ext)) {
        name = parts.join('.');
      }
    }
    name = name.trim() + srcExt;
  }
  return name;
}
