<script>
export default {
  name: 'CheckboxOperandInput',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
});

const checked = defineModel({ type: Object, required: true });

const _value = computed({
  get() {
    return checked.value.value;
  },
  set(value) {
    checked.value = {
      value,
      value2: checked.value.value2,
    };
  },
});
const isYes = computed(() => {
  return _value.value?.toLowerCase() === 'yes';
});
const isNo = computed(() => {
  return _value.value?.toLowerCase() === 'no';
});
</script>

<template>
  <div class="form-group">
    <div class="btn-group btn-group-toggle">
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isYes,
        }"
        @click="() => (_value = 'yes')"
      >
        Yes
      </button>
      <button
        type="button"
        class="btn btn-outline-dark"
        :class="{
          active: isNo,
        }"
        @click="() => (_value = 'no')"
      >
        No
      </button>
    </div>
  </div>
</template>
